/* eslint-disable jira/react/no-style-attribute */

import React, { Component } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import type { FieldRenderedValueProps } from '../../../model/fields/field-rendered-value/index.tsx';

type State = {
	tooltipText: string;
};

// TODO: FREE-2849 : Revisit the list and add more as required
const BLOCK_ELEMENTS = 'div, p, h2';

// eslint-disable-next-line jira/react/no-class-components
export default class FieldRenderedValue extends Component<FieldRenderedValueProps, State> {
	constructor(props: FieldRenderedValueProps) {
		super(props);
		this.rawText = '';
		this.hasEllipsis = false;
		this.state = {
			tooltipText: '',
		};
	}

	onSetRef = (div?: HTMLElement | null) => {
		this.ref = div;
	};

	onMouseOver = () => {
		if (this.props.doNotRenderTooltip) return;

		if (this.ref) {
			/*
			 * If there is a 'block' child node, the width of the child is to be taken for the correct calculations.
			 * Since we are rendering HTML blob, we need to find out if there are any 'block' child nodes
			 */
			const targetElement = this.ref.querySelector(BLOCK_ELEMENTS) || this.ref;
			let tooltipText = '';

			// Check if the element has overflown. If yes, set the innerText in state for tooltip to display
			// @ts-expect-error - TS2339 - Property 'offsetWidth' does not exist on type 'Element'.
			if (targetElement && targetElement.scrollWidth > targetElement.offsetWidth) {
				// @ts-expect-error - TS2339 - Property 'innerText' does not exist on type 'Element'.
				tooltipText = targetElement.innerText;
			}
			if (this.state.tooltipText !== tooltipText) {
				this.setState(() => ({
					tooltipText,
				}));
			}
		}
	};

	getContent() {
		const { fieldRenderedValue, fieldCssClass, canBeMultiLine, isLastColumn } = this.props;
		const innerHtml = { __html: fieldRenderedValue };

		if (canBeMultiLine) {
			return (
				<div
					// @ts-expect-error - TS2322 - Type '{ padding: string; overflowWrap: string; }' is not assignable to type 'Properties<string | number, string & {}>'.
					// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
					style={isLastColumn ? LastColumnMultilineStyle : NonLastColumnMultilineStyle}
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
					className={fieldCssClass}
					// eslint-disable-next-line react/no-danger
					dangerouslySetInnerHTML={innerHtml}
				/>
			);
		}

		return isLastColumn ? (
			<StyledWrapperLastColumn
				ref={this.onSetRef}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={fieldCssClass}
				dangerouslySetInnerHTML={innerHtml}
				onMouseOver={this.onMouseOver}
				onFocus={this.onMouseOver}
				role="textbox"
				tabIndex={0}
			/>
		) : (
			<BaseStyledWrapper
				ref={this.onSetRef}
				// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
				className={fieldCssClass}
				dangerouslySetInnerHTML={innerHtml}
				onMouseOver={this.onMouseOver}
				onFocus={this.onMouseOver}
				role="textbox"
				tabIndex={0}
			/>
		);
	}

	ref: HTMLElement | undefined | null;

	rawText: string | undefined;

	hasEllipsis: boolean;

	render() {
		const { tooltipText } = this.state;
		return (
			<FieldRenderedValueContainer>
				{tooltipText ? (
					<Tooltip delay={0} content={tooltipText}>
						{this.getContent()}
					</Tooltip>
				) : (
					this.getContent()
				)}
			</FieldRenderedValueContainer>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FieldRenderedValueContainer = styled.div({
	minWidth: '0px',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BaseStyledWrapper = styled.div({
	paddingTop: token('space.100'),
	paddingRight: token('space.300'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	whiteSpace: 'nowrap',
	width: '100%',
	boxSizing: 'border-box',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'& *': {
		overflow: 'hidden',
		textOverflow: 'ellipsis',
		whiteSpace: 'nowrap',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledWrapperLastColumn = styled(BaseStyledWrapper)({
	paddingTop: token('space.100'),
	paddingRight: token('space.100'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
});

const LastColumnMultilineStyle = {
	padding: `${token('space.100')}`,
	overflowWrap: 'break-word',
};
const NonLastColumnMultilineStyle = {
	padding: `${token('space.100')} ${token('space.300')} ${token('space.100')} ${token('space.100')}`,
	overflowWrap: 'break-word',
};
