import React, { Component, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import type { UIAnalyticsEvent } from '@atlaskit/analytics-next';
import { Box } from '@atlaskit/primitives';
import { colors as akColors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import { fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import {
	SelectableChildGutterPaddingContainer,
	UnselectableChildGutterPaddingContainer,
} from '../common/styled/index.tsx';

const linkStyle = () => ({
	boxShadow: 'none',
	outline: 'none',
});

type Props = JsonComponentProps<'summary'> & {
	useJSMQueueDurationEvent?: () => void;
};

type State = {
	isFocused: boolean;
};

// eslint-disable-next-line jira/react/no-class-components
export default class SummaryField extends Component<Props, State> {
	state = {
		isFocused: false,
	};

	onFocus = () => {
		this.setState({ isFocused: true });
	};

	onBlur = () => {
		this.setState({ isFocused: false });
	};

	onClick = (event: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const { onIssueViewTransition } = this.props.tableInjectedProps;
		const { useJSMQueueDurationEvent } = this.props;
		onIssueViewTransition?.(event);
		fireUIAnalytics(analyticsEvent, 'tableIssueLink');
		useJSMQueueDurationEvent && useJSMQueueDurationEvent();
	};

	onAuxClick = () => {
		const { useJSMQueueDurationEvent } = this.props;
		useJSMQueueDurationEvent && useJSMQueueDurationEvent();
	};

	renderDisabled() {
		const {
			dataSelectorProps: { value },
			tableInjectedProps: { isLastColumn },
		} = this.props;

		return (
			<UnselectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<Box paddingBlock="space.100" paddingInline="space.0">
					{value}
				</Box>
			</UnselectableChildGutterPaddingContainer>
		);
	}

	render() {
		const {
			dataSelectorProps: { value },
			tableInjectedProps: { isLastColumn, issueKey, isTableDisabled },
		} = this.props;

		if (isTableDisabled) {
			return this.renderDisabled();
		}

		return (
			<SelectableChildGutterPaddingContainer isLastColumn={isLastColumn}>
				<Container isFocused={this.state.isFocused}>
					<Link
						// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
						style={linkStyle()}
						onFocus={this.onFocus}
						onBlur={this.onBlur}
						href={`/browse/${issueKey}`}
						onClick={this.onClick}
						onAuxClick={this.onAuxClick}
						// eslint-disable-next-line @atlaskit/ui-styling-standard/no-classname-prop -- Ignored via go/DSP-18766
						className="issue-link"
						{...{ 'data-issue-key': issueKey }}
					>
						{value}
					</Link>
				</Container>
			</SelectableChildGutterPaddingContainer>
		);
	}
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Container = styled.div<{ isFocused?: boolean }>({
	paddingTop: token('space.100'),
	paddingRight: token('space.075'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.075'),
	width: 'auto',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	borderColor: ({ isFocused }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		isFocused ? `${token('color.border.focused', akColors.B200)}` : 'transparent',
	borderRadius: '3px',
	borderWidth: '2px',
	borderStyle: 'solid',
	boxShadow: 'none',
	outline: 'none',
});
