/** @jsx jsx */
import { useMemo, useState, useRef, useEffect, useCallback, type MouseEvent } from 'react';
import { jsx } from '@compiled/react';
import differenceInDays from 'date-fns/differenceInDays';
import { graphql, useFragment } from 'react-relay';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import {
	Pressable,
	Box,
	xcss,
	type BorderRadius,
	type BackgroundColor,
} from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { N20A } from '@atlaskit/theme/colors';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { fg } from '@atlassian/jira-feature-gating';
import { FIELD_TYPE_MAP } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/constants.tsx';
import { getUpdateAnalyticsFlowHelper } from '@atlassian/jira-issue-analytics/src/services/update-issue-field/index.tsx';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import {
	DUE_DATE_TYPE,
	START_DATE_TYPE,
} from '@atlassian/jira-platform-field-config/src/index.tsx';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type {
	issueRenderer_calendar_IssueEventRenderer$key,
	issueRenderer_calendar_IssueEventRenderer$data,
} from '@atlassian/jira-relay/src/__generated__/issueRenderer_calendar_IssueEventRenderer.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import {
	useCalendarCapabilities,
	NONE,
	FLYOUT,
} from '../../../../common/controllers/capabilities-provider/index.tsx';
import { useCalendarIssueViewMode } from '../../../../common/controllers/use-calendar-issue-view-mode/index.tsx';
import { toLocalDate } from '../../../../common/utils/dates/index.tsx';
import { findDraggedDate } from '../../../../common/utils/drag-and-drop/index.tsx';
import {
	useCalendarActions,
	useDraggingEvent,
	useShowIssueKey,
	useViewRange,
	useColorBy,
} from '../../../../controllers/calendar-store/index.tsx';
import { moreLinkPopoverBinder } from '../../../../controllers/more-link-popover-binder/index.tsx';
import { OPTIMISTIC_ISSUE_ID_PREFIX } from '../../../../controllers/use-create-calendar-issue-mutation/index.tsx';
import { useIsVisibleInMoreLinkPopover } from '../../../../controllers/use-is-visible-in-more-link-popover/index.tsx';
import {
	isDroppableCalendarDayCell,
	makeDraggableCalendarIssue,
} from '../../../../services/draggable-card-type/index.tsx';
import { EventSummary } from './event-summary/index.tsx';
import IssueFlyout from './flyout/index.tsx';

type IssueEventState = 'overdue' | 'in-progress' | 'resolved' | 'default';

interface IssueEventRendererProps {
	issueRef: issueRenderer_calendar_IssueEventRenderer$key;
	connectionRecordId: string | undefined;
	isAllDayEvent?: boolean;
}

export function IssueEventRenderer({
	issueRef,
	connectionRecordId,
	isAllDayEvent = true,
}: IssueEventRendererProps) {
	const issue: issueRenderer_calendar_IssueEventRenderer$data | undefined =
		useFragment<issueRenderer_calendar_IssueEventRenderer$key>(
			graphql`
				fragment issueRenderer_calendar_IssueEventRenderer on JiraScenarioIssueLike
				@argumentDefinitions(
					viewId: { type: "ID" }
					schedulePermissionsEnabled: { type: "Boolean!" }
				) {
					__id
					id
					... on JiraIssue {
						key
						issueTypeField {
							issueType {
								name
								avatar {
									small
								}
							}
						}

						canSchedule: hasProjectPermission(permission: SCHEDULE_ISSUES)
							@include(if: $schedulePermissionsEnabled)

						requestTypeField: fieldByIdOrAlias(idOrAlias: "requesttype", ignoreMissingField: true)
							@optIn(to: "JiraIssueFieldByIdOrAlias") {
							id
							... on JiraServiceManagementRequestTypeField {
								__typename
								requestType {
									name
									avatar {
										small
									}
								}
							}
						}

						summaryField {
							text
						}

						startDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
							__id
							id
							... on JiraDatePickerField {
								date
							}
							... on JiraDateTimePickerField {
								dateTime
							}
						}
						endDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
							__id
							id
							... on JiraDatePickerField {
								date
							}
							... on JiraDateTimePickerField {
								dateTime
							}
						}

						statusField {
							status {
								statusCategory {
									key
								}
							}
						}
						planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
							summaryField {
								text
							}
							issueTypeField {
								issueType {
									name
									avatar {
										small
									}
								}
							}
							startDateViewField {
								__id
								id
								... on JiraDatePickerField {
									__typename
									date
								}
							}
							endDateViewField {
								__id
								id
								... on JiraDatePickerField {
									__typename
									date
								}
							}
							statusField {
								status {
									statusCategory {
										key
									}
								}
							}
						}
					}
					... on JiraScenarioIssue {
						planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
							scenarioKey
							issueTypeField {
								issueType {
									name
									avatar {
										small
									}
								}
							}

							summaryField {
								text
							}
							startDateViewField {
								__id
								id
								... on JiraDatePickerField {
									date
								}
							}
							endDateViewField {
								__id
								id
								... on JiraDatePickerField {
									date
								}
							}
							statusField {
								status {
									statusCategory {
										key
									}
								}
							}
						}
					}
					...eventSummary_calendar @arguments(viewId: $viewId)
					...flyout_calendar_IssueFlyout @arguments(viewId: $viewId)
				}
			`,
			issueRef,
		);

	const issueEventCardRef = useRef<HTMLDivElement>(null);
	const {
		issue: { modalType, canSchedule },
		accessibleIconColors,
	} = useCalendarCapabilities();

	const { openIssueView } = useCalendarIssueViewMode();
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const viewRange = fg('jsd_shield_jsm_calendar_weekly_view') ? useViewRange() : 'month';
	const showIssueKey = useShowIssueKey() && issue?.key;

	const colorBy = useColorBy();

	const summary = issue?.planScenarioValues?.summaryField?.text || issue?.summaryField?.text;

	const issueType =
		issue?.planScenarioValues?.issueTypeField?.issueType ?? issue?.issueTypeField?.issueType;

	const issueTypeIcon = issueType?.avatar?.small ?? '';
	const requestTypeIcon = issue?.requestTypeField?.requestType?.avatar?.small;

	const requestTypeName = issue?.requestTypeField?.requestType?.name;
	const issueTypeName = issueType?.name;

	// if event has request type info, then show request type icon
	const eventIcon = requestTypeIcon ?? issueTypeIcon;

	const iconDescription = requestTypeName ?? issueTypeName;

	const { setDraggingEvent } = useCalendarActions();
	const draggingEvent = useDraggingEvent();
	const { isVisible, onDropEvent } = useIsVisibleInMoreLinkPopover(issueEventCardRef);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const isDraggable = canSchedule;
	const hasChangedIndicator =
		issue?.planScenarioValues !== null && issue?.planScenarioValues !== undefined;

	const startDateField = issue?.planScenarioValues?.startDateViewField ?? issue?.startDateViewField;

	const endDateField = issue?.planScenarioValues?.endDateViewField ?? issue?.endDateViewField;

	const startDateTime =
		startDateField && 'dateTime' in startDateField ? startDateField.dateTime : startDateField?.date;
	const endDateTime =
		endDateField && 'dateTime' in endDateField ? endDateField.dateTime : endDateField?.date;

	const statusCategory =
		issue?.planScenarioValues?.statusField?.status?.statusCategory?.key ??
		issue?.statusField?.status?.statusCategory?.key;

	useEffect(() => {
		const el = issueEventCardRef.current;
		if (!el || !isDraggable) {
			return;
		}

		return draggable({
			element: el,
			getInitialData: (args) => {
				const { input } = args;
				const draggedDate =
					findDraggedDate(el, input) || moreLinkPopoverBinder.getCurrentDayCellDate();
				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return makeDraggableCalendarIssue({
					issueAri: issue?.id,
					issueRecordId: issue?.__id,
					startDateFieldRecordId: startDateField?.__id,
					endDateFieldRecordId: endDateField?.__id,
					startDate: startDateTime,
					endDate: endDateTime,
					connectionRecordId,
					draggedDate,
					issueKey: issue?.planScenarioValues?.scenarioKey ?? issue?.key ?? '',
					source: 'calendar',
					canSchedule: issue?.canSchedule,
				}) as unknown as Record<string, unknown>;
			},
			onDragStart(_args) {
				if (startDateTime && fg('one_event_rules_them_all_fg')) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsStart(START_DATE_TYPE, {
						analytics: createAnalyticsEvent({}),
						attributes: {
							fieldType: FIELD_TYPE_MAP[START_DATE_TYPE],
							isDragEditing: true,
						},
					});
				}

				if (endDateTime && fg('one_event_rules_them_all_fg')) {
					getUpdateAnalyticsFlowHelper().fireAnalyticsStart(DUE_DATE_TYPE, {
						analytics: createAnalyticsEvent({}),
						attributes: {
							fieldType: FIELD_TYPE_MAP[DUE_DATE_TYPE],
							isDragEditing: true,
						},
					});
				}
				setDraggingEvent({ type: 'issue', id: issue.id });
			},
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onDrag(_args) {},
			onDrop(args) {
				setDraggingEvent(null);
				const dropTargetData = args.location.current.dropTargets[0]?.data;
				if (isDroppableCalendarDayCell(dropTargetData)) {
					onDropEvent(dropTargetData.isEventDraggedAway);
				}
			},
		});
	}, [
		createAnalyticsEvent,
		isDraggable,
		connectionRecordId,
		issue?.__id,
		startDateField,
		endDateField,
		issue?.id,
		issue?.key,
		issue?.planScenarioValues?.scenarioKey,
		onDropEvent,
		setDraggingEvent,
		startDateTime,
		endDateTime,
		issue?.canSchedule,
	]);

	const issueEventState: IssueEventState = useMemo(() => {
		if (statusCategory === 'done') {
			return 'resolved';
		}

		const endDate = endDateField?.date != null ? toLocalDate(endDateField?.date) : null;

		if (endDate && differenceInDays(Date.now(), endDate.getTime()) > 0) {
			return 'overdue';
		}

		if (statusCategory === 'indeterminate') {
			return 'in-progress';
		}

		return 'default';
	}, [endDateField, statusCategory]);

	const [isOpen, setIsOpen] = useState<boolean>(false);
	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

	const onEventCardClick = useCallback(() => {
		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'issueEventCard',
			}),
			'calendar',
		);

		if (modalType === FLYOUT) {
			setIsOpen((open) => !open);
			return;
		}

		if (!issue.key) {
			return;
		}

		if (modalType === NONE) {
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			window.open(`/browse/${issue.key}`, '_blank');
			return;
		}

		openIssueView(issue.key);
	}, [issue?.key, openIssueView, createAnalyticsEvent, modalType]);

	if (!isVisible) return null;

	if (!issue) {
		return null;
	}

	// Optimistically created issue from create issue mutation
	if (issue.id.startsWith(OPTIMISTIC_ISSUE_ID_PREFIX)) {
		return (
			<Box xcss={eventCardInlineStyles}>
				{eventIcon && <img alt={iconDescription} src={eventIcon} width={16} height={16} />}
				<Spinner size="small" interactionName="creating" label={`Creating ${iconDescription}`} />
				<Box xcss={eventNameInlineStyles}>{summary}</Box>
			</Box>
		);
	}

	// TODO: Hopefully there are already components for these
	const isDragging = draggingEvent?.type === 'issue' && draggingEvent?.id === issue.id;

	if (modalType === FLYOUT) {
		return (
			<JiraPopup
				isOpen={isOpen}
				onClose={onClose}
				placement="bottom-start"
				content={() => <IssueFlyout onClose={onClose} issueRef={issue} />}
				messageType="transactional"
				messageId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.jira-popup"
				trigger={({ ref: popupRef, ...popupTriggerProps }) => (
					<Tooltip position="bottom" content={`${showIssueKey ? `${issue.key} ` : ''}${summary}`}>
						{({ ref: tooltipRef, ...tooltipProps }) => (
							<Pressable
								{...popupTriggerProps}
								{...tooltipProps}
								ref={mergeRefs(popupRef, tooltipRef, issueEventCardRef)}
								xcss={[
									eventCardInlineStyles,
									viewRange === 'week' && eventCardForWeekStyles,
									hasChangedIndicator && extraLeftPaddingStyles,
									isDragging && eventCardInlineDraggingStyles,
									...(colorBy === 'status'
										? [
												issueEventState === 'in-progress' && inProgressStyles,
												issueEventState === 'resolved' && resolvedStyles,
												issueEventState === 'overdue' && overdueStyles,
											]
										: [
												isVisualRefreshEnabled() && fg('visual-refresh_drop_5')
													? defaultStyles
													: defaultStylesOld,
											]),
									!issue.key && eventCardUnclickableStyles,
								]}
								testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.issue-event-card"
								onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
									// Prevents fullcalendar from handling mouse events and interfering with pragmatic dnd
									e.stopPropagation();
								}}
								onClick={onEventCardClick}
							>
								<EventSummary
									viewRange={viewRange}
									eventIcon={eventIcon}
									iconDescription={iconDescription}
									showIssueKey={showIssueKey}
									issueEventState={issueEventState}
									issue={issue}
									showAccessibleIconColors={accessibleIconColors}
									startDateTime={startDateTime}
									endDateTime={endDateTime}
									summary={summary}
									hasChangedIndicator={hasChangedIndicator}
									isAllDayEvent={isAllDayEvent}
								/>
							</Pressable>
						)}
					</Tooltip>
				)}
			/>
		);
	}

	return (
		<Tooltip position="bottom" content={`${showIssueKey ? `${issue.key} ` : ''}${summary}`}>
			{({ ref: tooltipRef, ...tooltipProps }) => (
				<Pressable
					{...tooltipProps}
					ref={mergeRefs(tooltipRef, issueEventCardRef)}
					xcss={[
						eventCardInlineStyles,
						viewRange === 'week' && eventCardForWeekStyles,
						hasChangedIndicator && extraLeftPaddingStyles,
						isDragging && eventCardInlineDraggingStyles,
						...(colorBy === 'status'
							? [
									issueEventState === 'in-progress' && inProgressStyles,
									issueEventState === 'resolved' && resolvedStyles,
									issueEventState === 'overdue' && overdueStyles,
								]
							: [
									isVisualRefreshEnabled() && fg('visual-refresh_drop_5')
										? defaultStyles
										: defaultStylesOld,
								]),
						!issue.key && eventCardUnclickableStyles,
					]}
					testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.issue-event-card"
					onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
						// Prevents fullcalendar from handling mouse events and interfering with pragmatic dnd
						e.stopPropagation();
					}}
					onClick={onEventCardClick}
				>
					<EventSummary
						viewRange={viewRange}
						eventIcon={eventIcon}
						iconDescription={iconDescription}
						showIssueKey={showIssueKey}
						issueEventState={issueEventState}
						issue={issue}
						showAccessibleIconColors={accessibleIconColors}
						startDateTime={startDateTime}
						endDateTime={endDateTime}
						summary={summary}
						hasChangedIndicator={hasChangedIndicator}
						isAllDayEvent={isAllDayEvent}
					/>
				</Pressable>
			)}
		</Tooltip>
	);
}

const eventCardForWeekStyles = xcss({
	height: '100%',
	alignItems: 'flex-start',
	overflow: 'hidden',
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: '1rem',
	paddingTop: 'space.025',
	minHeight: '1.125rem',
	borderRadius: 'border.radius.050',
});

const eventCardInlineStyles = xcss({
	position: 'relative',
	display: 'flex',
	gap: 'space.050',
	padding: 'space.050',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	alignItems: 'center',
	boxSizing: 'border-box',
	width: '100%',
	height: '24px',
	// NOTE: Fallback token is needed as Cypress tests won't recognise token value which will violate A11y (Storybook renders fine)
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-imported-style-values
	backgroundColor: token('color.background.accent.gray.subtlest', N20A) as BackgroundColor,
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.gray.subtlest.pressed',
	},
	color: 'color.text',
	borderRadius:
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		`var(--fc-event-border-left-radius, 0)
        var(--fc-event-border-right-radius, 0)
        var(--fc-event-border-right-radius, 0)
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        var(--fc-event-border-left-radius, 0)` as BorderRadius,
});

const extraLeftPaddingStyles = xcss({
	paddingLeft: 'space.100',
	overflow: 'hidden',
});

const inProgressStyles = xcss({
	backgroundColor: 'color.background.accent.blue.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.blue.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.blue.subtlest.pressed',
	},
});

const resolvedStyles = xcss({
	backgroundColor: 'color.background.accent.green.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.green.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.green.subtlest.pressed',
	},
});

const overdueStyles = xcss({
	backgroundColor: 'color.background.accent.red.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.red.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.red.subtlest.pressed',
	},
});

const defaultStylesOld = xcss({
	backgroundColor: 'elevation.surface.raised',
	':hover': {
		backgroundColor: 'elevation.surface.raised.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.raised.pressed',
	},
	border: '1px solid',
	borderColor: 'color.border',
});

const defaultStyles = xcss({
	backgroundColor: 'elevation.surface',
	':hover': {
		backgroundColor: 'elevation.surface.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.pressed',
	},
	border: '1px solid',
	borderColor: 'color.border',
	borderRadius: '1px',
});

const eventCardUnclickableStyles = xcss({
	cursor: 'default',
});

const eventCardInlineDraggingStyles = xcss({
	opacity: 0.5,
});

const eventNameInlineStyles = xcss({
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
});
