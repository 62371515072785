/** @jsx jsx */
import React, { useMemo, useCallback } from 'react';

import { jsx } from '@compiled/react';
import differenceInDays from 'date-fns/differenceInDays';
import { graphql, useFragment } from 'react-relay';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import Link from '@atlaskit/link';
import Lozenge, { type LozengeProps } from '@atlaskit/lozenge';
import { Pressable, Box, xcss, Text, Stack, Inline } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	useAnalyticsEvents,
	fireUIAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type { flyout_calendar_IssueFlyout$key } from '@atlassian/jira-relay/src/__generated__/flyout_calendar_IssueFlyout.graphql';
import { DEFAULT_FORMAT_DATE_OPTIONS } from '../../../../../common/constants.tsx';
import { ChangeIndicator } from '../../../../../common/ui/change-indicator/index.tsx';
import { toLocalDate } from '../../../../../common/utils/dates/index.tsx';

import { messages } from './messages.tsx';

const LozengeAppearance: { [key: string]: LozengeProps['appearance'] } = {
	inprogress: 'inprogress',
	resolved: 'success',
	default: 'default',
};

type IssueEventState = 'inprogress' | 'resolved' | 'default';

export type Props = {
	issueRef: flyout_calendar_IssueFlyout$key;
	onClose: () => void;
	onClickIssueLink?: () => void;
};

export const IssueFlyout = ({ issueRef, onClose, onClickIssueLink }: Props) => {
	const data = useFragment(
		graphql`
			fragment flyout_calendar_IssueFlyout on JiraScenarioIssueLike
			@argumentDefinitions(viewId: { type: "ID" }) {
				... on JiraIssue {
					key
					issueTypeField {
						issueType {
							name
							avatar {
								small
							}
						}
					}
					summaryField {
						text
					}
					descriptionField {
						richText {
							adfValue {
								convertedPlainText {
									plainText
								}
							}
						}
					}
					startDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
						... on JiraDatePickerField {
							date
						}
					}
					endDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
						... on JiraDatePickerField {
							date
						}
					}

					statusField {
						status {
							name
							statusCategory {
								key
							}
						}
					}
					assigneeField {
						user {
							name
							picture
						}
					}
					projectField {
						project {
							name
							avatar {
								small
							}
						}
					}
					planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
						summaryField {
							text
						}
						descriptionField {
							richText {
								adfValue {
									convertedPlainText {
										plainText
									}
								}
							}
						}
						issueTypeField {
							issueType {
								name
								avatar {
									small
								}
							}
						}
						startDateViewField {
							... on JiraDatePickerField {
								date
							}
						}
						endDateViewField {
							... on JiraDatePickerField {
								date
							}
						}
						statusField {
							status {
								name
								statusCategory {
									key
								}
							}
						}
						assigneeField {
							user {
								name
								picture
							}
						}
						projectField {
							project {
								name
								avatar {
									small
								}
							}
						}
					}
				}
				... on JiraScenarioIssue {
					planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
						issueTypeField {
							issueType {
								name
								avatar {
									small
								}
							}
						}
						summaryField {
							text
						}
						descriptionField {
							richText {
								adfValue {
									convertedPlainText {
										plainText
									}
								}
							}
						}
						startDateViewField {
							... on JiraDatePickerField {
								date
							}
						}
						endDateViewField {
							... on JiraDatePickerField {
								date
							}
						}
						statusField {
							status {
								name
								statusCategory {
									key
								}
							}
						}
						assigneeField {
							user {
								name
								picture
							}
						}
						projectField {
							project {
								name
								avatar {
									small
								}
							}
						}
					}
				}
			}
		`,
		issueRef,
	);

	const { formatMessage, formatDate } = useIntl();
	const { createAnalyticsEvent } = useAnalyticsEvents();
	const startDateViewField = data.planScenarioValues?.startDateViewField ?? data.startDateViewField;
	const endDateViewField = data.planScenarioValues?.endDateViewField ?? data.endDateViewField;
	const summaryField = data.planScenarioValues?.summaryField ?? data.summaryField;
	const descriptionField = data.planScenarioValues?.descriptionField ?? data.descriptionField;
	const issueTypeField = data.planScenarioValues?.issueTypeField ?? data.issueTypeField;
	const assigneeField = data.planScenarioValues?.assigneeField ?? data.assigneeField;
	const projectField = data.planScenarioValues?.projectField ?? data.projectField;
	const statusField = data.planScenarioValues?.statusField ?? data.statusField;
	const statusCategory = statusField?.status?.statusCategory?.key;

	const isOverdue = useMemo(() => {
		const endDate = endDateViewField?.date != null ? toLocalDate(endDateViewField?.date) : null;
		return Boolean(endDate && differenceInDays(Date.now(), endDate.getTime()) > 0);
	}, [endDateViewField]);

	const issueEventState: IssueEventState = useMemo(() => {
		if (statusCategory === 'done') {
			return 'resolved';
		}

		if (statusCategory === 'indeterminate') {
			return 'inprogress';
		}

		return 'default';
	}, [statusCategory]);

	const analyticsAttributes = useMemo(
		() => ({
			isOverdue,
		}),
		[isOverdue],
	);

	const onMouseDownIssueLink = useCallback(
		(e: React.MouseEvent<HTMLElement, MouseEvent>) => {
			// stop event propagation to prevent closing the flyout on click
			e.stopPropagation();
			if (fg('plan_timeline_core_action_analytics')) {
				const analyticsEvent = createAnalyticsEvent({
					action: 'clicked',
					actionSubject: 'issueLink',
				});
				fireUIAnalytics(analyticsEvent, 'calendarIssueFlyoutIssueLink');
			}
			onClickIssueLink?.();
		},
		[createAnalyticsEvent, onClickIssueLink],
	);

	return (
		<ContextualAnalyticsData
			sourceType="InlineDialog"
			sourceName="issueFlyout"
			attributes={analyticsAttributes}
		>
			<Stack
				xcss={containerStackStyles}
				space="space.200"
				testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout"
				role="group"
			>
				<Inline alignBlock="center" xcss={titleContainerInlineStyles}>
					<Inline alignBlock="center" space="space.050" xcss={keyInlineStyles}>
						{data.key && (
							<>
								{issueTypeField?.issueType?.avatar?.small && (
									<img
										alt={issueTypeField?.issueType?.name ?? ''}
										src={issueTypeField?.issueType?.avatar?.small}
										width={16}
										height={16}
									/>
								)}
								<Link
									onMouseDown={onMouseDownIssueLink}
									appearance="subtle"
									href={`/browse/${data.key}`}
									target="_blank"
								>
									{data.key}
								</Link>
							</>
						)}
					</Inline>
					<Pressable
						xcss={closeButtonStyle}
						onClick={onClose}
						padding="space.0"
						testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout.close-button"
					>
						<CrossIcon
							label={formatMessage(
								fg('jira-issue-terminology-refresh-m3')
									? messages.closeButtonLabelIssueTermRefresh
									: messages.closeButtonLabel,
							)}
							LEGACY_size="small"
						/>
					</Pressable>
				</Inline>
				{statusField?.status?.name && (
					<Box as="span">
						<Lozenge
							testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout.status"
							appearance={LozengeAppearance[issueEventState]}
						>
							{statusField?.status?.name}
						</Lozenge>
					</Box>
				)}
				<Box as="p" padding="space.0" xcss={summaryStyles}>
					{summaryField?.text}
					{data.planScenarioValues?.summaryField && <ChangeIndicator appearance="triangle" />}
				</Box>
				{descriptionField?.richText?.adfValue?.convertedPlainText?.plainText && (
					<Box as="p" padding="space.0" xcss={containerStyle}>
						<Text as="span" maxLines={5}>
							{descriptionField?.richText?.adfValue?.convertedPlainText?.plainText}
						</Text>
						{data.planScenarioValues?.descriptionField && <ChangeIndicator appearance="triangle" />}
					</Box>
				)}

				<Inline space="space.100">
					<Stack
						testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout.start-date"
						alignBlock="start"
						space="space.050"
						xcss={fieldValueInlineStyles}
					>
						<Box as="span" xcss={fieldLabelInlineStyles}>
							{formatMessage(messages.startDateLabel)}
						</Box>
						<Box as="span" xcss={containerStyle}>
							{startDateViewField?.date
								? formatDate(toLocalDate(startDateViewField?.date), DEFAULT_FORMAT_DATE_OPTIONS)
								: '-'}
							{data.planScenarioValues?.startDateViewField && (
								<ChangeIndicator appearance="triangle" />
							)}
						</Box>
					</Stack>
					<Stack
						testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout.end-date"
						alignBlock="start"
						space="space.050"
						xcss={[fieldValueInlineStyles, isOverdue && overdueTextStyles]}
					>
						<Box as="span" xcss={fieldLabelInlineStyles}>
							{formatMessage(messages.endDateLabel)}
						</Box>
						<Box as="span" xcss={containerStyle}>
							{endDateViewField?.date
								? formatDate(toLocalDate(endDateViewField?.date), DEFAULT_FORMAT_DATE_OPTIONS)
								: '-'}
							{data.planScenarioValues?.endDateViewField && (
								<ChangeIndicator appearance="triangle" />
							)}
						</Box>
					</Stack>
				</Inline>

				{projectField?.project && (
					<Stack
						testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout.project"
						space="space.100"
					>
						<Inline xcss={fieldLabelInlineStyles}>{formatMessage(messages.projectLabel)}</Inline>

						<Inline space="space.100" alignBlock="center">
							{projectField?.project?.avatar?.small && (
								<Stack space="space.0" xcss={projectAvatarStyle}>
									<img
										alt={`${projectField?.project?.name} project avatar`}
										src={projectField?.project?.avatar?.small}
									/>
								</Stack>
							)}
							<Stack space="space.0">
								<Box as="span" xcss={fieldValueInlineStyles}>
									{projectField?.project?.name}
								</Box>
							</Stack>
						</Inline>
					</Stack>
				)}
				{assigneeField?.user && (
					<Stack
						testId="calendar.ui.calendar-renderer.event-renderer.issue-renderer.flyout.assignee"
						space="space.100"
					>
						<Inline xcss={fieldLabelInlineStyles}>{formatMessage(messages.assigneeLabel)}</Inline>
						<Inline space="space.100" alignBlock="center" xcss={containerStyle}>
							{assigneeField?.user?.picture && (
								<Stack space="space.0" xcss={projectAvatarStyle}>
									<img alt={`${assigneeField?.user?.name}`} src={assigneeField?.user?.picture} />
								</Stack>
							)}
							<Stack space="space.0">
								<Box as="span" xcss={fieldValueInlineStyles} paddingInlineEnd="space.150">
									{assigneeField?.user?.name}
									{data.planScenarioValues?.assigneeField && (
										<ChangeIndicator appearance="triangle" />
									)}
								</Box>
							</Stack>
						</Inline>
					</Stack>
				)}
			</Stack>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

export default IssueFlyout;

const containerStackStyles = xcss({
	padding: 'space.200',
	width: '280px',
});

const titleContainerInlineStyles = xcss({
	height: token('space.300'),
});

const keyInlineStyles = xcss({
	flex: 1,
	color: 'color.text.subtlest',
});

const summaryStyles = xcss({
	font: token('font.heading.xsmall'),
	color: 'color.text',
	position: 'relative',
	margin: '0',
});

const closeButtonStyle = xcss({
	border: 'none',
	background: 'none',
	cursor: 'pointer',
});

const fieldValueInlineStyles = xcss({
	flex: 1,
	font: token('font.body'),
	color: 'color.text',
	position: 'relative',
});

const fieldLabelInlineStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
});

const overdueTextStyles = xcss({
	color: 'color.text.accent.red',
});

const projectAvatarStyle = xcss({
	height: token('space.300'),
	width: token('space.300'),
	borderRadius: 'border.radius.100',
});

const containerStyle = xcss({
	position: 'relative',
});
