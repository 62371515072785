// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import { B300, N0, N100, N30, N40, N50A, N60A } from '@atlaskit/theme/colors';
// eslint-disable-next-line @atlaskit/design-system/no-deprecated-imports
import { gridSize } from '@atlaskit/theme/constants';
import { token } from '@atlaskit/tokens';

/**
 * Size in pixels of a 15 minutes slot on the calendar
 **/
const borderColor = token('color.border', N40);
const slotTextColor = token('color.text.subtlest', N100);

// eslint-disable-next-line @atlaskit/design-system/prefer-primitives, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const RelativeWrapper = styled.div({
	position: 'relative',
});

// eslint-disable-next-line @atlaskit/design-system/prefer-primitives, @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const FullCalendarStyledWrapper = styled.div<{
	hideScrollBar: boolean | undefined;
	hideAllDaySlot: boolean | undefined;
	formattedTimezone: string;
}>`
	height: 100%;

	& {
		.fc-media-screen .fc-timegrid-cols > table {
			height: 100%;
		}

		.fc {
			thead {
				border-bottom: 0;
			}

			.fc-scrollgrid-section > * {
				border-top-width: 0;
				border-left-width: 0;
			}

			.fc-daygrid-more-link {
				padding: 0;
				background: transparent;
				margin-top: 0;
				line-height: unset;
			}

			.fc-daygrid-day-bottom {
				margin: ${token('space.025', '2px')} ${token('space.025', '2px')} 0;
				padding-top: ${token('space.025', '2px')};
			}

			/* Style time slots */
			.fc-timegrid-slot {
				height: ${token('space.200', '16px')};
			}
			.fc-timegrid-slot:empty::before {
				content: none;
			}
			.fc-timegrid-slot-label-frame {
				position: absolute;
				height: ${token('space.200', '16px')};
				right: ${token('space.100', '8px')};
				top: ${token('space.negative.100', '-8px')};

				&::after {
					content: '';
					display: block;
					position: absolute;
					right: ${token('space.negative.100', '-8px')};
					top: 50%;
					width: ${token('space.100', '8px')};
					height: 1px;
					background-color: ${borderColor};
				}
			}
			.fc-timegrid-axis,
			.fc-timegrid-slot-label {
				position: relative;
				font: ${token('font.body.small')};
				color: ${slotTextColor};
			}
			.fc-col-header col,
			.fc-timegrid-slots col,
			.fc-daygrid-body col,
			.fc-timegrid-cols col {
				width: ${token('space.1000', '80px')} !important;
			}
			.fc-timegrid-axis-cushion {
				max-width: none;
			}

			/* Move the first time slot label down slightly */
			.fc-timegrid-slots tr:first-of-type .fc-timegrid-slot-label-frame {
				/* Hide the label if the all-day row is hidden */
				display: ${({ hideAllDaySlot }) => (hideAllDaySlot ? 'none' : 'visible')};

				top: 0;

				&::after {
					top: 0;
				}
			}

			/* Style grid borders */
			td {
				border: 1px solid ${borderColor};
			}
			.fc-timegrid {
				table {
					border-collapse: separate;
				}
				td {
					border-right-width: 0;
				}
				tr:not(:last-of-type) td,
				.fc-scrollgrid-section.fc-scrollgrid-section-body:last-of-type > td {
					${({ hideAllDaySlot }) =>
						hideAllDaySlot ? 'border-bottom-color: transparent;' : 'border-bottom-width: 0;'}
				}
				.fc-timegrid-slot-minor {
					border-top: 0;
				}
			}
			.fc-daygrid {
				.fc-scrollgrid-section > td {
					border-right-width: 0;
				}
				.fc-scrollgrid-section.fc-scrollgrid-section-body > td {
					border-radius: ${gridSize() * 0.375}px;
					border-top-width: 1px;
					border-left-width: 1px;
				}
			}
			tbody,
			th,
			td.fc-timegrid-axis,
			td.fc-timegrid-slot-label,
			.fc-scrollgrid {
				border: none;
			}
			.fc-scrollgrid-section.fc-scrollgrid-section-body > td {
				border-right-width: 1px;
			}

			/* Hide divider under all day events */
			.fc-timegrid-axis-cushion {
				flex-shrink: 1;
				text-align: right;
			}
			.fc-timegrid {
				tbody .fc-scrollgrid-section:nth-of-type(2) {
					height: 0;
				}
				.fc-timegrid-divider {
					display: none;
				}
			}

			/* Add drop shadow to day and week grid views */
			.fc-timegrid {
				.fc-scrollgrid-section.fc-scrollgrid-section-body {
					&:last-of-type > td {
						overflow: hidden;
						position: relative;

						&::before,
						&::after {
							content: '';
							display: block;
							position: absolute;
							height: ${token('space.100', '8px')};
							right: 0;
						}

						&::before {
							top: ${token('space.negative.100', '-8px')};
							box-shadow: ${token(
								'elevation.shadow.overflow',
								`1px 1px 8px -2px ${N50A}, 1px 0 1px ${N60A}`,
							)};
							z-index: 1;
						}

						&::after {
							bottom: ${token('space.negative.100', '-8px')};
							box-shadow: ${token(
								'elevation.shadow.overflow',
								`0 -1px 8px -2px ${N50A}, 0 0 1px ${N60A}`,
							)};
						}
					}
				}
			}

			/* Add dropshadow to events with open popups to make them feel more connected to the popups */
			.fc-event-with-open-popup {
				box-shadow: ${token('elevation.shadow.overlay', `0 4px 8px 0 ${N50A}, 0 0 1px 0 ${N60A}`)};
			}

			/* Add styling for placeholder events */
			.fc-timegrid-event.fc-event-mirror:not(.fc-event-resizing):not(.fc-event-dragging),
			.fc-daygrid-event.fc-event-placeholder,
			.fc-timegrid-event.fc-event-placeholder {
				background-color: ${token('elevation.surface.overlay', N0)};
				border: 2px solid ${token('color.border.information', B300)};
				box-shadow: ${token('elevation.shadow.overlay', `0 4px 8px -2px ${N50A}, 0 0 1px ${N60A}`)};
			}
			.fc-timegrid-event.fc-event-mirror:not(.fc-event-resizing):not(.fc-event-dragging) {
				margin-right: ${token('space.negative.100', '-8px')};
			}
			.fc-event-custom-border {
				border-width: 2px;
			}

			/* Hide scrollbar for header and all day events */
			.fc-timegrid {
				.fc-scrollgrid-section.fc-scrollgrid-section-header .fc-scroller,
				.fc-scrollgrid-section.fc-scrollgrid-section-body .fc-scroller {
					overflow-y: ${({ hideScrollBar }) => (hideScrollBar ? 'hidden' : 'visible')} !important;
				}

				.fc-scrollgrid-section.fc-scrollgrid-section-body > td {
					position: relative;

					&::before {
						content: '';
						display: block;
						position: absolute;
						left: ${token('space.1000', '80px')};
						right: 0;
						top: 0;
						height: 1px;
						background-color: ${borderColor};
					}
				}
			}

			/* Remove highlighting of current date */
			.fc-daygrid-day.fc-day-today,
			.fc-timegrid-col.fc-day-today {
				background-color: transparent;
			}

			/* Add right margin to events and remove padding */
			.fc-timegrid-col-events {
				margin-left: 0;
				margin-right: ${token('space.100', '8px')};
			}
			.fc-timegrid-event .fc-event-main {
				padding: unset;
			}

			/* Remove underlines from dates */
			a.fc-col-header-cell-cushion,
			a.fc-daygrid-day-number {
				&:hover {
					text-decoration: none;
				}
			}

			/* Style days of month */
			.fc-daygrid-day-top {
				flex-direction: row;
			}
			.fc-col-header-cell-cushion {
				padding-bottom: ${token('space.100', '8px')};
			}
			.fc-daygrid-day-number {
				padding: ${token('space.050', '4px')} ${token('space.100', '8px')} 0
					${token('space.100', '8px')};
			}
			.fc-day-other .fc-daygrid-day-top {
				opacity: 1 !important;
			}

			/* Style timezone */
			.fc-timegrid .fc-scrollgrid-section.fc-scrollgrid-section-header {
				.fc-timegrid-axis {
					z-index: 2;
				}

				.fc-timegrid-axis-frame::after {
					content: '${(props) => props.formattedTimezone}';
					background-color: ${token('elevation.surface', N0)};
					position: absolute;
					padding-right: ${token('space.100', '8px')};
					bottom: ${token('space.negative.100', '-8px')};
					right: 0;
				}

				/* Set overflow to allow timezone to be visible */
				.fc-scroller-harness {
					overflow: visible;

					.fc-scroller,
					.fc-timegrid-axis-frame {
						overflow: visible !important;
					}
				}
			}

			/* List view overridings */
			.fc-list {
				&,
				td {
					border: 0;
					vertical-align: middle;
				}

				.fc-list-day-cushion,
				.fc-list-table td {
					padding: ${token('space.100', '8px')} ${token('space.200', '16px')};
				}

				.fc-list-event td {
					padding-left: 0;

					&:not(:last-child) {
						padding-right: ${token('space.200', '16px')};
						color: ${token('color.text.subtlest', '#626f86')};
					}
				}

				.fc-list-day {
					&:not(:first-of-type) .fc-list-day-cushion {
						padding-top: ${token('space.300', '24px')};
						margin-top: ${token('space.300', '24px')};
						border-top: 2px solid ${token('color.border', N30)};
					}

					.fc-list-day-cushion {
						text-align: left;
						background-color: transparent;
						font-weight: ${token('font.weight.semibold')};
						font-size: 16px;
						padding-left: 0;
						padding-bottom: ${token('space.200', '16px')};
					}
				}
			}
		}
	}
`;
