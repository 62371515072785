import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { gridSize } from '@atlassian/jira-common-styles/src/main.tsx';

// Please consider refactoring code to use either only padding in styled components or only GUTTER, not both. Otherwise, it leads to bugs and unexpected behavior, e.g. GHOST-4901
export const COLUMN_GUTTER = {
	left: gridSize,
	right: gridSize * 3,
	rightLastColumn: gridSize,
} as const;

const breakWordStyle = {
	minWidth: '0px',
	overflowWrap: 'break-word',
} as const;

type GutterProps = {
	isLastColumn: boolean;
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const UnselectableChildGutterPaddingContainer = styled.div<GutterProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isLastColumn }) =>
		`0px ${isLastColumn ? token('space.100') : token('space.300')} 0px ${token('space.100')}`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...breakWordStyle,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SelectableChildGutterPaddingContainer = styled.div<GutterProps>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ isLastColumn }) => `0px ${isLastColumn ? '0px' : '0px'} 0px 0px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	...breakWordStyle,
});
