import React, { useCallback, useRef } from 'react';
import ShareIcon from '@atlaskit/icon/core/migration/share';
import { JiraProjectAri } from '@atlassian/ari/jira';
import { JiraSiteAri } from '@atlassian/ari/jira/site';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { useProjectContext } from '@atlassian/jira-providers-project-context/src/index.tsx';
import { integrationTypes } from '@atlassian/jira-share-button/src/constants.tsx';
import ShareButton, {
	type RenderCustomTriggerButton,
} from '@atlassian/jira-share-button/src/index.tsx';
import { useShareIntegrations } from '@atlassian/jira-share-integrations/src/index.tsx';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { messages } from './messages.tsx';

const SHARE_TYPE = 'calendar';

export const CalendarShareButton = () => {
	const { formatMessage } = useIntl();

	const cloudId = useCloudId();
	const objectAri = JiraSiteAri.create({ siteId: cloudId }).toString();

	const { data: projectData } = useProjectContext();
	const projectAri =
		!!projectData?.projectId && fg('jira_calendar_share_fix')
			? JiraProjectAri.create({
					siteId: cloudId,
					projectId: projectData.projectId.toString(),
				}).toString()
			: '';

	const shareButtonRef = useRef<HTMLElement>(null);
	const { shareIntegrations } = useShareIntegrations(SHARE_TYPE);

	const renderCustomTriggerButton: RenderCustomTriggerButton = useCallback(
		({ onClick, isSelected }, { ref, ...triggerProps }) => (
			<div ref={ref}>
				<ThemedButton
					{...triggerProps}
					ref={shareButtonRef}
					onClick={onClick}
					isSelected={isSelected}
					iconBefore={<ShareIcon spacing="spacious" label="" />}
				>
					{formatMessage(messages.share)}
				</ThemedButton>
			</div>
		),
		[formatMessage],
	);

	const handleDialogClose = () => {
		shareButtonRef?.current?.focus();
	};

	return (
		<ShareButton
			integrationType={integrationTypes.CALENDAR}
			triggerButtonStyle="icon-with-text"
			productId="jira"
			subProductId="jira-core"
			// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
			shareLink={__SERVER__ ? '' : window.location.href}
			shareTitle={formatMessage(messages.shareTitle)}
			shareContentType={SHARE_TYPE}
			renderCustomTriggerButton={renderCustomTriggerButton}
			objectAri={fg('jira_calendar_share_fix') ? projectAri : objectAri}
			onDialogClose={handleDialogClose}
			copyTooltipText={formatMessage(messages.shareTooltipText)}
			shareIntegrations={shareIntegrations}
			integrationMode="tabs"
			shareFormHelperMessage={formatMessage(messages.shareHelperMessage)}
		/>
	);
};
