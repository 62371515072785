import React, { type ComponentType } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { AsyncQueuesAutomationMenu } from '@atlassian/jira-servicedesk-queues-automation-menu/src/async.tsx';
import { fg } from '@atlassian/jira-feature-gating';

type Props = {
	isManuallySorted: boolean;
	isFavoriteEnabled: boolean;
	canManageQueue: boolean;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ResetSortingButton: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	ActionsMenu: ComponentType<any>;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	FavoriteButton: ComponentType<any>;
	showIssueFailureError: boolean;
	onDeleteComplete: (arg1: string) => void;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	OpenInIssueNavButton: ComponentType<any>;
};

export default function HeaderActions({
	canManageQueue,
	isManuallySorted,
	isFavoriteEnabled,
	onDeleteComplete,
	showIssueFailureError,
	ActionsMenu,
	FavoriteButton,
	ResetSortingButton,
	OpenInIssueNavButton,
}: Props) {
	const shouldShowResetSortingButton =
		!showIssueFailureError && !canManageQueue && isManuallySorted;
	const hasMultipleActions = isFavoriteEnabled && (canManageQueue || shouldShowResetSortingButton);
	const shouldHideNotSupportedActions =
		__SERVER__ && fg('remove_load_states_for_smooth_view_queues_ssr');

	return (
		<Wrapper>
			{!shouldHideNotSupportedActions && <AsyncQueuesAutomationMenu />}
			<>
				<OpenInIssueNavButton />
				<Spacer />
			</>
			{!shouldHideNotSupportedActions && isFavoriteEnabled && <FavoriteButton />}
			{!shouldHideNotSupportedActions && hasMultipleActions && <Spacer />}
			{!shouldHideNotSupportedActions && canManageQueue && (
				<ActionsMenu onDeleteComplete={onDeleteComplete} />
			)}
			{!shouldHideNotSupportedActions && shouldShowResetSortingButton && <ResetSortingButton />}
		</Wrapper>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Spacer = styled.div({
	width: token('space.100'),
});
