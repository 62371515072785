import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	releaseLabel: {
		id: 'release-flyout.release-label',
		defaultMessage: 'RELEASE',
		description: 'Label for the release layout header',
	},
	closeButtonLabel: {
		id: 'release-flyout.close-button-label',
		defaultMessage: 'Close the release layout',
		description: 'Label for the close button in the release layout',
	},
	releasedStatusLabel: {
		id: 'release-flyout.released-status-label',
		defaultMessage: 'RELEASED',
		description: 'Label for the released status',
	},
	unreleasedStatusLabel: {
		id: 'release-flyout.unreleased-status-label',
		defaultMessage: 'UNRELEASED',
		description: 'Label for the unreleased status',
	},
	overdueStatusLabel: {
		id: 'release-flyout.overdue-status-label',
		defaultMessage: 'OVERDUE',
		description: 'Label for the overdue status',
	},
	overdueDaysLabel: {
		id: 'release-flyout.overdue-days-label',
		defaultMessage:
			'{numberDaysOverdue} {numberDaysOverdue, plural, =1 {day} other {days}} overdue',
		description: 'Label for the number of days overdue',
	},
	startDateLabel: {
		id: 'release-flyout.start-date-label',
		defaultMessage: 'Start date',
		description: 'Label for the start date',
	},
	releaseDateLabel: {
		id: 'release-flyout.release-date-label',
		defaultMessage: 'Release date',
		description: 'Label for the release date',
	},
	projectLabel: {
		id: 'release-flyout.project-label',
		defaultMessage: 'Project',
		description: 'Label for the project',
	},
	driverLabel: {
		id: 'release-flyout.driver-label',
		defaultMessage: 'Driver',
		description: 'Label for the driver',
	},
	progressLabel: {
		id: 'release-flyout.progress-label',
		defaultMessage: 'Progress',
		description: 'Label for the progress',
	},
	doneLabel: {
		id: 'release-flyout.done-label',
		defaultMessage: 'done',
		description: 'Label for the done',
	},
	doneProgressLabel: {
		id: 'release-flyout.done-progress-label',
		defaultMessage: '{percent}% done',
		description: 'Label for the done percentage',
	},
	toDoToolTipsText: {
		id: 'release-flyout.to-do-tool-tips-text',
		defaultMessage:
			'To Do: {count} of {totalCount} {totalCount, plural, =0 {issue} one {issue} other {issues}}',
		description:
			'A tooltip for a progress bar that shows the number of issues with the "To do" status.',
	},
	inProgressToolTipsText: {
		id: 'release-flyout.in-progress-tool-tips-text',
		defaultMessage:
			'In Progress: {count} of {totalCount} {totalCount, plural, =0 {issue} one {issue} other {issues}}',
		description:
			'A tooltip for a progress bar that shows the number of issues with the "In progress" status.',
	},
	doneToolTipsText: {
		id: 'release-flyout.done-tool-tips-text',
		defaultMessage:
			'Done: {count} of {totalCount} {totalCount, plural, =0 {issue} one {issue} other {issues}}',
		description:
			'A tooltip for a progress bar that shows the number of issues with the "Done" status.',
	},
	toDoToolTipsTextIssueTermRefresh: {
		id: 'release-flyout.to-do-tool-tips-text-issue-term-refresh',
		defaultMessage:
			'To Do: {count} of {totalCount} {totalCount, plural, =0 {work item} one {work item} other {work items}}',
		description:
			'A tooltip for a progress bar that shows the number of issues with the "To do" status.',
	},
	inProgressToolTipsTextIssueTermRefresh: {
		id: 'release-flyout.in-progress-tool-tips-text-issue-term-refresh',
		defaultMessage:
			'In Progress: {count} of {totalCount} {totalCount, plural, =0 {work item} one {work item} other {work items}}',
		description:
			'A tooltip for a progress bar that shows the number of issues with the "In progress" status.',
	},
	doneToolTipsTextIssueTermRefresh: {
		id: 'release-flyout.done-tool-tips-text-issue-term-refresh',
		defaultMessage:
			'Done: {count} of {totalCount} {totalCount, plural, =0 {work item} one {work item} other {work items}}',
		description:
			'A tooltip for a progress bar that shows the number of issues with the "Done" status.',
	},
});
