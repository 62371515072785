import { defineMessages } from '@atlassian/jira-intl';

export default defineMessages({
	agentDescription: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.agent-description',
		defaultMessage:
			'Get better visibility of the status of your issues. Track the progress of all issues, or use filters to show specific issues. Ask an admin to enable your board.',
		description: 'The board promotion spotlight content description for agent users',
	},
	adminDescription: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.admin-description',
		defaultMessage:
			'Get better visibility of the status of your issues. Track the progress of all issues, or use filters to show specific issues. Enable your board in Features.',
		description: 'The board promotion spotlight content description for admin users',
	},
	heading: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.heading',
		defaultMessage: 'Visualize your work on a board',
		description: 'The board promotion spotlight heading',
	},
	enable: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.enable',
		defaultMessage: 'Go to Features',
		description: 'Enable button on admin view of the spotlight',
	},
	ok: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.ok',
		defaultMessage: 'OK',
		description: 'OK button on agent view of the spotlight',
	},
	dismiss: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.dismiss',
		defaultMessage: 'Dismiss',
		description: 'Dismiss button on admin view of the spotlight',
	},
	learnMore: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.learn-more',
		defaultMessage: 'Learn more',
		description: 'Learn more button on agent view of the spotlight',
	},
	agentDescriptionIssueTermRefresh: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.agent-description-issue-term-refresh',
		defaultMessage:
			'Get better visibility of the status of your work items. Track the progress of all work items, or use filters to show specific work items. Ask an admin to enable your board.',
		description: 'The board promotion spotlight content description for agent users',
	},
	adminDescriptionIssueTermRefresh: {
		id: 'servicedesk.queues.agent-view.layout.board-promo-spotlight.admin-description-issue-term-refresh',
		defaultMessage:
			'Get better visibility of the status of your work items. Track the progress of all work items, or use filters to show specific work items. Enable your board in Features.',
		description: 'The board promotion spotlight content description for admin users',
	},
});
