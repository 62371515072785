/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import EmojiFrequentIcon from '@atlaskit/icon/core/migration/clock--emoji-frequent';
import { Box, xcss, Flex, Inline, type Space } from '@atlaskit/primitives';
import { R500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import type { eventSummary_calendar$key } from '@atlassian/jira-relay/src/__generated__/eventSummary_calendar.graphql';
import type { issueRenderer_calendar_IssueEventRenderer$data } from '@atlassian/jira-relay/src/__generated__/issueRenderer_calendar_IssueEventRenderer.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { ChangeIndicator } from '../../../../../common/ui/change-indicator/index.tsx';
import { useFormatToLocalTimeString } from '../../../../../common/utils/dates/index.tsx';
import { EventAssignee } from '../event-assignee/index.tsx';
import { IconRenderer } from '../event-icon/index.tsx';
import { messages } from './messages.tsx';
import { getMaxAllowedSummaryInLines } from './utils.tsx';

type MultilineSummaryProps = {
	maxAllowedLines: number;
	startDateTime: string | undefined | null;
	endDateTime: string | undefined | null;
	summary: string | undefined | null;
};

const MultilineSummary = ({
	maxAllowedLines,
	startDateTime,
	endDateTime,
	summary,
}: MultilineSummaryProps) => {
	const startTimeLocalString = useFormatToLocalTimeString(startDateTime);
	const endTimeLocalString = useFormatToLocalTimeString(endDateTime);
	const { formatMessage } = useIntl();
	const cssStyle = fg('jsm_calendar_weekly_view_style_enhencement')
		? longSummaryStyles
		: longSummaryStylesOld;

	return (
		<Box
			as="span"
			// remove next line when cleaning up jsm_calendar_weekly_view_style_enhencement
			// eslint-disable-next-line @atlaskit/design-system/consistent-css-prop-usage
			xcss={cssStyle}
			// eslint-disable-next-line jira/react/no-style-attribute
			style={{ WebkitLineClamp: maxAllowedLines }}
		>
			{summary}
			{startTimeLocalString && endTimeLocalString && fg('jsd_shield_weekly_view_fast_follow') && (
				<Box as="span" xcss={timeStyles}>
					{formatMessage(messages.timeFormat, {
						startTime: startTimeLocalString,
						endTime: endTimeLocalString,
					})}
				</Box>
			)}
		</Box>
	);
};

export type EventSummaryProps = {
	viewRange: string;
	startDateTime: string | undefined | null;
	endDateTime: string | undefined | null;
	eventIcon: string;
	iconDescription: string | undefined;
	showIssueKey: string | boolean | undefined;
	issueEventState: string;
	issue: issueRenderer_calendar_IssueEventRenderer$data;
	showAccessibleIconColors: boolean;
	hasChangedIndicator: boolean;
	summary: string | undefined | null;
	isAllDayEvent: boolean;
};

const Wrapper = ({
	isWeekView,
	children,
	direction,
	gap,
}: {
	isWeekView: boolean;
	children: ReactNode;
	direction?: 'column' | 'row';
	gap?: Space;
}) => {
	if (isWeekView) {
		return (
			<Flex direction={direction} xcss={fullWidthStyles} gap={gap}>
				{children}
			</Flex>
		);
	}

	return <>{children}</>;
};

const EventSummary = ({
	eventIcon,
	iconDescription,
	viewRange,
	showIssueKey,
	issueEventState,
	issue,
	showAccessibleIconColors,
	hasChangedIndicator,
	summary,
	startDateTime,
	endDateTime,
	isAllDayEvent,
}: EventSummaryProps) => {
	const { formatMessage } = useIntl();

	const data = useFragment<eventSummary_calendar$key>(
		graphql`
			fragment eventSummary_calendar on JiraScenarioIssueLike
			@argumentDefinitions(viewId: { type: "ID" }) {
				...eventAssignee_calendar @arguments(viewId: $viewId)
			}
		`,
		issue,
	);

	const isVisualRefresh = isVisualRefreshEnabled() && fg('visual-refresh_drop_5');

	if (!fg('jsd_shield_jsm_calendar_weekly_view')) {
		return (
			<>
				{eventIcon && <img alt={iconDescription} src={eventIcon} width={16} height={16} />}
				<Box as="span" xcss={[isVisualRefresh ? eventNameInlineStyles : eventNameInlineStylesOld]}>
					{showIssueKey &&
						(issueEventState === 'resolved' ? (
							<span css={resolvedEventIssuesKeyStyles}>{issue.key}</span>
						) : (
							<span css={[isVisualRefresh ? eventIssuesKeyStyles : eventIssuesKeyStylesOld]}>
								{issue.key}
							</span>
						))}
					{summary}
				</Box>
				{!showAccessibleIconColors && issueEventState === 'overdue' && (
					<EmojiFrequentIcon
						label={formatMessage(messages.overdueLabel)}
						color={token('color.icon.danger', R500)}
						LEGACY_size="small"
					/>
				)}
				{showAccessibleIconColors && <IconRenderer issueState={issueEventState} />}
				<EventAssignee issueKey={data} />
				{hasChangedIndicator && <ChangeIndicator />}
			</>
		);
	}

	const isWeekView = viewRange === 'week';
	const maxAllowedLines = getMaxAllowedSummaryInLines(startDateTime, endDateTime, isAllDayEvent);

	return (
		<Wrapper isWeekView={isWeekView} direction="column">
			<Wrapper isWeekView={isWeekView} gap="space.050">
				{eventIcon && <img alt={iconDescription} src={eventIcon} width={16} height={16} />}
				<Box
					as="span"
					xcss={[
						isVisualRefresh ? eventNameInlineStyles : eventNameInlineStylesOld,
						isWeekView && eventNameInlineStylesWeeklyView,
					]}
				>
					{showIssueKey &&
						(issueEventState === 'resolved' ? (
							<span css={resolvedEventIssuesKeyStyles}>{issue.key}</span>
						) : (
							<span css={[isVisualRefresh ? eventIssuesKeyStyles : eventIssuesKeyStylesOld]}>
								{issue.key}
							</span>
						))}
					{(!isWeekView || maxAllowedLines === 1) && <>{summary}</>}
				</Box>
				<Inline as="span" space="space.200" xcss={iconStyles}>
					{!showAccessibleIconColors && issueEventState === 'overdue' && (
						<EmojiFrequentIcon
							label={formatMessage(messages.overdueLabel)}
							color={token('color.icon.danger', R500)}
							LEGACY_size="small"
						/>
					)}
					{showAccessibleIconColors && <IconRenderer issueState={issueEventState} />}
				</Inline>
				<EventAssignee issueKey={data} />
				{hasChangedIndicator && <ChangeIndicator />}
			</Wrapper>
			{isWeekView && maxAllowedLines > 1 && (
				<MultilineSummary
					startDateTime={startDateTime}
					endDateTime={endDateTime}
					summary={summary}
					// -1 to account for the line that shows the issue key
					maxAllowedLines={maxAllowedLines - 1}
				/>
			)}
		</Wrapper>
	);
};

export { EventSummary, MultilineSummary };

const eventNameInlineStylesOld = xcss({
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
});

const eventNameInlineStyles = xcss({
	fontWeight: token('font.weight.regular'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
});

const eventNameInlineStylesWeeklyView = xcss({
	display: '-webkit-box',
	WebkitLineClamp: 1,
	WebkitBoxOrient: 'vertical',
});

const eventIssuesKeyStylesOld = css({
	fontWeight: token('font.weight.regular'),
	textDecoration: 'none',
	paddingRight: token('space.050'),
});

const eventIssuesKeyStyles = css({
	fontWeight: token('font.weight.regular'),
	textDecoration: 'none',
	paddingRight: token('space.050'),
	color: token('color.text.subtle'),
});

const resolvedEventIssuesKeyStyles = css({
	fontWeight: token('font.weight.regular'),
	textDecoration: 'line-through',
	paddingRight: token('space.050'),
});

const longSummaryStylesOld = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'break-all',
	display: '-webkit-box',
	appearance: 'none',
	WebkitBoxOrient: 'vertical',
	padding: 'space.025',
	fontWeight: token('font.weight.medium'),
	textAlign: 'justify',
});

const longSummaryStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	wordBreak: 'break-word',
	display: '-webkit-box',
	appearance: 'none',
	WebkitBoxOrient: 'vertical',
	padding: 'space.025',
	fontWeight: token('font.weight.medium'),
	textAlign: 'justify',
});

const timeStyles = xcss({
	display: 'flex',
	justifyContent: 'flex-start',
	color: 'color.text.subtlest',
	fontWeight: token('font.weight.regular'),
	marginTop: 'space.050',
});

const fullWidthStyles = xcss({
	width: '100%',
});

const iconStyles = xcss({
	flex: 1,
	textAlign: 'left',
});
