import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { type PreloadedQuery, graphql, usePreloadedQuery, useQueryLoader } from 'react-relay';
import Button from '@atlaskit/button';
import FilterIcon from '@atlaskit/icon/core/migration/filter';
import Popup, { type TriggerProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { Inline, Stack } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import type { srcfilterPopupButton_softwareFiltersPopup_Query } from '@atlassian/jira-relay/src/__generated__/srcfilterPopupButton_softwareFiltersPopup_Query.graphql';
import { useCloudId } from '@atlassian/jira-tenant-context-controller/src/components/cloud-id/index.tsx';
import { messages } from './messages.tsx';
import { type FilterPopupProps, FilterPopup } from './ui/index.tsx';

const QUERY = graphql`
	query srcfilterPopupButton_softwareFiltersPopup_Query($cloudId: ID!, $jqlBuilderQuery: String) {
		...ui_softwareFiltersPopup_FilterPopup
			@arguments(cloudId: $cloudId, jqlBuilderQuery: $jqlBuilderQuery)
	}
`;

interface FilterPopupContentProps {
	onChange: FilterPopupProps['onChange'];
	query: PreloadedQuery<srcfilterPopupButton_softwareFiltersPopup_Query>;
	initialValue?: FilterPopupProps['initialValue'];
}

function FilterPopupContent({ onChange, query, initialValue }: FilterPopupContentProps) {
	const queryRef = usePreloadedQuery(QUERY, query);
	if (!queryRef) {
		return null;
	}

	return <FilterPopup onChange={onChange} queryRef={queryRef} initialValue={initialValue} />;
}

/**
 * A button that opens a popup with the filter when clicked.
 * Handles fetching the filter data.
 */
export function FilterPopupButton({
	onChange,
	jqlBuilderQuery,
	initialValue,
	onOpen,
	onClose,
}: {
	/**
	 * Called with a JQL string whenever filtering options change.
	 */
	onChange: FilterPopupProps['onChange'];
	/**
	 * Initial JQL model to seed the popup with
	 */
	initialValue?: FilterPopupProps['initialValue'];
	/**
	 * The JQL query to use as the "context" to constrain which filter options are shown.
	 *
	 * For example, if this is "project = A", only "A"'s statuses will be shown.
	 */
	jqlBuilderQuery?: string;
	/**
	 * Called when the filters popup is opened
	 */
	onOpen?: () => void;
	/**
	 * Called when the filters popup is closed
	 */
	onClose?: () => void;
}) {
	const cloudId = useCloudId();
	const [query, loadQuery] = useQueryLoader<srcfilterPopupButton_softwareFiltersPopup_Query>(QUERY);
	const [isOpen, setIsOpen] = useState(false);
	const { formatMessage } = useIntl();

	const filterCount = Object.values(initialValue?.whereClauses ?? {}).flatMap((f) => f).length;
	const filtersMessage =
		filterCount > 0
			? formatMessage(messages.filtersCount, { filters: filterCount })
			: formatMessage(messages.filters);

	return (
		<Popup
			isOpen={isOpen}
			placement="bottom-end"
			shouldRenderToParent
			content={() => {
				const fallback = (
					<Stack alignBlock="center" alignInline="center">
						<Spinner />
					</Stack>
				);
				return (
					<FilterPopupContainer>
						<Placeholder name="FilterPopup" fallback={fallback}>
							{query ? (
								<FilterPopupContent onChange={onChange} query={query} initialValue={initialValue} />
							) : (
								fallback
							)}
						</Placeholder>
					</FilterPopupContainer>
				);
			}}
			onClose={() => {
				setIsOpen(false);
				onClose?.();
			}}
			trigger={(props: TriggerProps) => (
				<Button
					{...props}
					testId="software-filters-popup.button"
					onClick={() => {
						if (isOpen) {
							onOpen?.();
						} else {
							onClose?.();
						}
						loadQuery({ cloudId, jqlBuilderQuery });
						setIsOpen((s) => !s);
					}}
					appearance={fg('jira-calendar-business-theme') ? 'subtle' : 'default'}
				>
					<Inline space="space.050" alignBlock="center" alignInline="center">
						<FilterIcon spacing="spacious" label="" />
						{filtersMessage}
					</Inline>
				</Button>
			)}
		/>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const FilterPopupContainer = styled.div({
	width: 300,
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
});
