import type { ActionsObservable } from 'redux-observable';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { fg } from '@atlassian/jira-feature-gating';
import { LOAD_ISSUES_ACTION_SOURCE_SORT } from '../../../model/index.tsx';
import { loadIssuesAction } from '../../../state/actions/issue/index.tsx';
import {
	RESET_SORT_ORDER,
	sortOrderOptimisticResetAction,
} from '../../../state/actions/table/index.tsx';
import type { Action } from '../../../state/actions/types.tsx';
import 'rxjs/add/observable/of';
import 'rxjs/add/operator/switchMap';

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (action$: ActionsObservable<Action>) =>
	fg('clean_local_storage_sorting')
		? action$.ofType(RESET_SORT_ORDER).switchMap(() =>
				Observable.of(
					sortOrderOptimisticResetAction(),
					// @ts-expect-error - TS2769 - No overload matches this call.
					loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_SORT),
				),
			)
		: action$
				.ofType(RESET_SORT_ORDER)
				.map(() => loadIssuesAction(0, LOAD_ISSUES_ACTION_SOURCE_SORT));
