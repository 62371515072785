import React from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment, type EntryPointProps } from 'react-relay';
import Heading, { type HeadingProps } from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import SprintIcon from '@atlaskit/icon/core/migration/sprint';
import Lozenge, { type ThemeAppearance } from '@atlaskit/lozenge';
import { Box, Stack, Inline, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import type { EntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import type {
	JiraSprintState,
	ui_SprintFlyout$key,
} from '@atlassian/jira-relay/src/__generated__/ui_SprintFlyout.graphql';
import { DEFAULT_FORMAT_DATE_OPTIONS } from '../common/constants.tsx';
import { messages } from './messages.tsx';
import { SprintActionsMenu } from './sprint-actions-menu/index.tsx';

export type SprintFlyoutProps = {
	sprintRef: ui_SprintFlyout$key;
	editSprintModalTriggerRef: EntryPointButtonTrigger;
	isSprintActionMenuDisabled?: boolean;
	onClose: () => void;
	userTimeZone: string;
	headingAs?: HeadingProps['as'];
	userTimeZoneForSprintEnabled: boolean;
};

function toLozengeState(state: JiraSprintState | null | undefined): ThemeAppearance {
	switch (state) {
		case 'ACTIVE':
			return 'inprogress';
		case 'CLOSED':
			return 'success';
		default:
			return 'default';
	}
}

function toSprintStateMessage(state: JiraSprintState | null | undefined) {
	switch (state) {
		case 'ACTIVE':
			return messages.activeSprintState;
		case 'CLOSED':
			return messages.closedSprintState;
		default:
			return messages.futureSprintState;
	}
}

function withTimezone(userTimeZone: string, userTimeZoneForSprintEnabled: boolean) {
	return {
		...DEFAULT_FORMAT_DATE_OPTIONS,
		timeZone: userTimeZoneForSprintEnabled ? userTimeZone : 'UTC',
	};
}

export function SprintFlyout({
	sprintRef,
	editSprintModalTriggerRef,
	isSprintActionMenuDisabled = false,
	onClose,
	userTimeZone,
	headingAs = 'h3',
	userTimeZoneForSprintEnabled,
}: SprintFlyoutProps) {
	/* eslint-disable @atlassian/relay/graphql-naming */
	const sprint = useFragment<ui_SprintFlyout$key>(
		graphql`
			fragment ui_SprintFlyout on JiraSprint {
				name
				state
				goal
				startDate
				endDate
				completionDate
			}
		`,
		sprintRef,
	);
	/* eslint-enable @atlassian/relay/graphql-naming */
	const { formatMessage, formatDate } = useIntl();

	const formatDateOptions = withTimezone(userTimeZone, userTimeZoneForSprintEnabled);

	return (
		<Stack space="space.150" xcss={containerStyles} testId="sprint-flyout.ui.stack">
			<Inline alignBlock="center">
				<Inline alignBlock="center" space="space.050" xcss={titleInlineStyles}>
					<SprintIcon label="" LEGACY_size="small" />

					<Title>{formatMessage(messages.sprintLabel)}</Title>
				</Inline>
				{!isSprintActionMenuDisabled && (
					<SprintActionsMenu
						editSprintModalTriggerRef={editSprintModalTriggerRef}
						onClosePopup={onClose}
					/>
				)}
				<CloseButton onClick={onClose} data-testid="sprint-flyout.ui.close-button">
					<CrossIcon label={formatMessage(messages.closeButtonLabel)} LEGACY_size="small" />
				</CloseButton>
			</Inline>
			<Inline>
				<Lozenge appearance={toLozengeState(sprint.state)}>
					{formatMessage(toSprintStateMessage(sprint.state))}
				</Lozenge>
			</Inline>

			<Heading as={headingAs} size="small">
				{sprint.name}
			</Heading>

			{sprint.goal && (
				<Box as="p" xcss={goalStyles} testId="sprint-flyout.ui.box">
					{sprint.goal}
				</Box>
			)}
			{(sprint.startDate ?? sprint.endDate ?? sprint.completionDate) && (
				<Inline testId="sprint-flyout.ui.inline" space="space.250">
					{sprint.startDate && (
						<Stack alignBlock="start" space="space.100" xcss={dateContainerStyles}>
							<Box as="span" xcss={dateFieldTitleStyles}>
								{formatMessage(messages.startDateLabel)}
							</Box>
							<Box as="span" xcss={dateFieldValueStyles}>
								{formatDate(sprint.startDate, formatDateOptions)}
							</Box>
						</Stack>
					)}

					{/* Use sprint completion date for completed sprints; otherwise use planned end date */}
					{sprint.completionDate ? (
						<Stack alignBlock="start" space="space.100" xcss={dateContainerStyles}>
							<Box as="span" xcss={dateFieldTitleStyles}>
								{formatMessage(messages.completionDateLabel)}
							</Box>
							<Box as="span" xcss={dateFieldValueStyles}>
								{formatDate(sprint.completionDate, formatDateOptions)}
							</Box>
						</Stack>
					) : (
						sprint.endDate && (
							<Stack alignBlock="start" space="space.100" xcss={dateContainerStyles}>
								<Box as="span" xcss={dateFieldTitleStyles}>
									{formatMessage(messages.endDateLabel)}
								</Box>
								<Box as="span" xcss={dateFieldValueStyles}>
									{formatDate(sprint.endDate, formatDateOptions)}
								</Box>
							</Stack>
						)
					)}
				</Inline>
			)}
		</Stack>
	);
}

const SprintFlyoutEntryPoint = ({ props }: EntryPointProps<{}, {}, SprintFlyoutProps, {}>) => (
	<SprintFlyout {...props} />
);

export default SprintFlyoutEntryPoint;

const containerStyles = xcss({
	padding: 'space.200',
	width: '260px',
});

const titleInlineStyles = xcss({
	flex: 1,
	color: 'color.text.subtlest',
});

const goalStyles = xcss({
	margin: 'space.0',
	font: token('font.body'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	/* stylelint-disable value-no-vendor-prefix */
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
});

const dateFieldTitleStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
});

const dateFieldValueStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
});

const dateContainerStyles = xcss({
	flex: 1,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CloseButton = styled.button({
	border: 'none',
	background: 'none',
	cursor: 'pointer',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h2({
	margin: 0,
	color: 'inherit',
	font: token('font.heading.xxsmall'),
});
