import type { DocNode as ADF } from '@atlaskit/adf-schema';
import {
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	type CommentVisibility,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
import type { AccountId } from '@atlassian/jira-shared-types/src/general.tsx';
import type { CommentsPageInfo } from '@atlassian/jira-issue-shared-types/src/common/types/comment-transformer-types.tsx';

export type CommentId = string;
export const toCommentId = (value: string): CommentId => value;

export const SAVE_COMMENT_LOADING = 'SAVE_COMMENT_LOADING' as const;
export const LOADING = 'LOADING' as const;
export const COMPLETE = 'COMPLETE' as const;

export type LoadingStage = typeof LOADING | typeof COMPLETE | typeof SAVE_COMMENT_LOADING;

export const COMMENT_VISIBILITY_PUBLIC: CommentVisibility = {
	type: COMMENT_VISIBILITY_TYPE_PUBLIC,
	value: '',
};

export type OptimisticComment = {
	id: CommentId;
	createdDate: string;
	updatedDate?: string | null;
	authorId: AccountId | null;
	updateAuthorId?: AccountId | null;
	bodyHtml?: string;
	bodyAdf?: ADF;
	visibility: CommentVisibility;
	edited?: boolean;
	hasSaveFailed?: boolean;
	isDeleted?: boolean;
	parentId?: CommentId;
	replies?: string[];
	isInternal: boolean;
	/*
	 *  TODO : https://hello.atlassian.net/browse/GCS-1009 Make jsdAuthorCanSeeRequest mandatory when FF is cleaned
	 */
	jsdAuthorCanSeeRequest?: boolean; // JSM specific field for identifying whether user is request participant or not
	eventOccurredAt?: number | null;
	jsdIncidentActivityViewHidden?: boolean | null;
	fullIssueUrl?: string;
	triggerIncidentSaveCommentFlag?: boolean;
	editSessionContainsAttachmentsUsage?: boolean;
	childCommentIds?: Set<string>;
	childCommentsPageInfo?: CommentsPageInfo;
};

export type PersistedComment = OptimisticComment & {
	body: ADF;
};

export type Comment = OptimisticComment | PersistedComment;

export type EditorComment = {
	isEditing: boolean;
	editingValue?: ADF;
	isInternal: boolean;
	visibility?: CommentVisibility;
	eventOccurredAt?: number | null;
	// jsdIncidentActivityViewHidden just hides the comment from the "All" tab and the PIR also.
	jsdIncidentActivityViewHidden?: boolean | null;
	editSessionContainsAttachmentsUsage?: boolean;
};

export type CommentEditors = Record<CommentId, EditorComment>;

export type OptimisticCommentsById = Record<CommentId, OptimisticComment>;

export type PersistedCommentsById = Record<CommentId, PersistedComment>;

export type OptimisticOrPersistedCommentsById = Record<
	CommentId,
	OptimisticComment | PersistedComment
>;

export type CommentsById = Record<CommentId, Comment>;

export const ActionButton = {
	PREVIOUS: 'PREVIOUS',
	NEXT: 'NEXT',
} as const;

type ChildButtonLoading = Record<CommentId, keyof typeof ActionButton>;

export type CommentsUiState = {
	pendingSavedValues: OptimisticCommentsById;
	pendingDeletedIds: CommentId[];
	deleteModalCommentId: CommentId | null;
	isLoadingMoreComments: boolean;
	commentEditors: CommentEditors;
	addCommentEditorExpand?: boolean;
	addCommentEditorForceFocus?: number;
	isCommentAttachmentInProgress: boolean;
	hasScrolledPermalinkComment: boolean;
	loadingStage: LoadingStage;
	replyingToCommentId: CommentId | null;
	replyingToAuthorId: AccountId | null;
	commentSessionId?: string | null;
	childButtonLoadingState?: ChildButtonLoading;
};

type CommentProperty = {
	key: string;
	value: {
		internal: boolean;
	};
};

export type CommentProperties = {
	properties?: CommentProperty[];
};

export type SaveCommentPropsType = {
	id: CommentId;
	bodyAdf: ADF;
	isNewComment: boolean | undefined;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	analyticsEvent: any;
	visibility: CommentVisibility;
	jsdIncidentActivityViewHidden?: boolean | null;
	eventOccurredAt?: number | null;
	fullIssueUrl?: string;
	triggerIncidentSaveCommentFlag?: boolean;
	parentId?: CommentId;
	commentSessionId?: string | null;
	editSessionContainsAttachmentsUsage?: boolean;
};

// eslint-disable-next-line @atlassian/eng-health/no-barrel-files/disallow-reexports
export {
	COMMENT_VISIBILITY_TYPE_GROUP,
	COMMENT_VISIBILITY_TYPE_ROLE,
	COMMENT_VISIBILITY_TYPE_PUBLIC,
	type CommentVisibility,
	type CommentVisibilityType,
} from '@atlassian/jira-issue-gira-transformer-types/src/common/types/comments.tsx';
