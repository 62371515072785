import { updateIn, dissoc, assoc } from 'icepick';
import find from 'lodash/find';
import toPairs from 'lodash/toPairs';
// @ts-expect-error - TS2305 - Module '"monet"' has no exported member 'MaybeType'.
import { Maybe, type MaybeType } from 'monet';
import type { CreateUIAnalyticsEvent } from '@atlaskit/analytics-next';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import {
	type SortOrder,
	ASC,
	DESC,
} from '@atlassian/jira-issue-table/src/model/fields/sort-order/index.tsx';
import { fireOperationalAnalytics } from '@atlassian/jira-product-analytics-bridge';
import log from '@atlassian/jira-servicedesk-queues-common/src/log/index.tsx';
import {
	type QueueId,
	type Column,
	fromQueueId,
	fromQueueIdString,
} from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { createLocalStorageProvider } from '@atlassian/jira-browser-storage-providers/src/controllers/local-storage/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import type { SortedQueues, SortedQueue, QueuesWithSorting } from '../../model/index.tsx';
import type { ManualSorting } from '../../state/reducers/persisted/queue/types.tsx';
import { SORT_PREFIX } from './constants.tsx';

// remove with ff cleanup `clean_local_storage_sorting`
const getKey = (queueId: string) => `${SORT_PREFIX}${queueId}`;
export const localStorageProvider = createLocalStorageProvider('servicedesk_queues');
// remove with FF cleanup `clean_local_storage_sorting`
export const storeSortOrderInLocalStorage = (
	queueId: QueueId,
	fieldId: string,
	sortOrder: SortOrder,
) => {
	try {
		if (fg('jsm_queue_localstorage_compliance')) {
			localStorageProvider.set(getKey(fromQueueId(queueId).toString()), {
				fieldId,
				sortOrder,
			});
			return;
		}
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.localStorage.setItem(
			getKey(fromQueueId(queueId).toString()),
			JSON.stringify({
				fieldId,
				sortOrder,
			}),
		);
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		const errMessage = 'Local storage failure for sorting';
		fireErrorAnalytics({
			meta: {
				id: 'servicesSort',
				packageName: 'jiraServicedeskQueuesAgentView',
				teamName: 'jsd-shield',
			},
			attributes: {
				type: 'localStorageFull',
			},
			error: new Error(errMessage),
		});
		log.safeErrorWithoutCustomerData('servicedesk.queues.agent-view.services', errMessage);
	}
};

// remove with FF cleanup `clean_local_storage_sorting`
export const removeSortOrderInLocalStorage = (queueId: QueueId) => {
	try {
		if (fg('jsm_queue_localstorage_compliance')) {
			localStorageProvider.remove(getKey(fromQueueId(queueId).toString()));
			return;
		}
		// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
		window.localStorage.removeItem(getKey(fromQueueId(queueId).toString()));
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		const errMessage = 'Local storage deletion failure for sorting';
		fireErrorAnalytics({
			meta: {
				id: 'servicesSortLocalstorageDelete',
				packageName: 'jiraServiceDeskQueuesAgentView',
				teamName: 'jsd-shield',
			},
			attributes: {
				type: 'localStorageDelete',
			},
			error: new Error(errMessage),
		});
		log.safeErrorWithoutCustomerData('servicedesk.queues.agent-view.services', errMessage);
	}
};

export const getSortingFromLocalStorage = (
	queueId: string,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): MaybeType<ManualSorting> => {
	try {
		let stored: string | null = null;
		if (fg('jsm_queue_localstorage_compliance')) {
			stored = localStorageProvider.get(getKey(queueId))
				? JSON.stringify(localStorageProvider.get(getKey(queueId)))
				: null;
		} else {
			stored = typeof window !== 'undefined' ? window.localStorage.getItem(getKey(queueId)) : null;
		}
		if (createAnalyticsEvent) {
			fireOperationalAnalytics(
				createAnalyticsEvent({}),
				'viewQueuesSortingFromLocalStorage updated',
				{
					hasSorting: stored !== null,
				},
			);
		}
		if (stored !== null) {
			const storedSortOrder = JSON.parse(stored);
			if (
				storedSortOrder.fieldId &&
				storedSortOrder.fieldId.length > 0 &&
				(storedSortOrder.sortOrder === ASC || storedSortOrder.sortOrder === DESC)
			) {
				return Maybe.of({
					sortedBy: storedSortOrder.fieldId,
					sortOrder: storedSortOrder.sortOrder,
				});
			}
		}
		// eslint-disable-next-line @typescript-eslint/no-explicit-any
	} catch (_: any) {
		const errMessage = 'Local storage for sorting getItem failure';
		fireErrorAnalytics({
			meta: {
				id: 'servicesSortLocalstorageGet',
				packageName: 'jiraServiceDeskQueuesAgentView',
				teamName: 'jsd-shield',
			},
			attributes: {
				type: 'localStorageGet',
			},
			error: new Error(errMessage),
		});
		log.safeErrorWithoutCustomerData('servicedesk.queues.agent-view.services', errMessage);
	}

	return Maybe.None();
};

const applyManualSortingToColumns = (columns: Column[], manualSorting: ManualSorting): Column[] =>
	columns.map((column) => {
		if (column.id === manualSorting.sortedBy) {
			return assoc(column, 'currentSorting', manualSorting.sortOrder);
		}
		return dissoc(column, 'currentSorting');
	});

export const addSortingToQueue = (
	queue: SortedQueue,
	maybeManualSorting: MaybeType<ManualSorting>,
): SortedQueue =>
	maybeManualSorting.cata(
		() => queue,
		// @ts-expect-error - TS7006 - Parameter 'manualSorting' implicitly has an 'any' type.
		(manualSorting) =>
			updateIn(queue, ['columns'], (columns) =>
				// @ts-expect-error - TS2345 - Argument of type 'unknown' is not assignable to parameter of type 'Column[]'.
				applyManualSortingToColumns(columns, manualSorting),
			),
	);

const addSortingToQueuesInternal = (currentQueueId: QueueId, queues: SortedQueues): SortedQueues =>
	// @ts-expect-error - TS2322 - Type '[string, SortedQueue]' is not assignable to type 'SortedQueues'. | TS2345 - Argument of type '(acc: Record<string, any>, [queueId, queue]: [number, Queue]) => Record<string, any>' is not assignable to parameter of type '(previousValue: Record<string, any>, currentValue: [string, SortedQueue], currentIndex: number, array: [string, SortedQueue][]) => Record<string, any>'. | TS2304 - Cannot find name 'Queue'.
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	toPairs(queues).reduce<Record<string, any>>((acc, [queueId, queue]: [QueueId, Queue]) => {
		const maybeManualSorting: MaybeType<ManualSorting> = getSortingFromLocalStorage(
			String(queueId),
		);
		const newQueue = addSortingToQueue(queue, maybeManualSorting);
		acc[queueId] = newQueue;
		return acc;
	}, {});

// Adds manual sorting information taken from local storage to queues' columns
export const addSortingToQueues = (
	currentQueueId: QueueId,
	queues: SortedQueues,
): QueuesWithSorting => {
	const updatedQueues = addSortingToQueuesInternal(currentQueueId, queues);
	const currentQueueIdString = fromQueueId(currentQueueId).toString();
	if (!updatedQueues[currentQueueIdString]) {
		return { queues: updatedQueues };
	}

	const manualSortColumn: Column | undefined = updatedQueues[currentQueueIdString].columns.find(
		(column) => column.currentSorting,
	);
	if (!manualSortColumn || !manualSortColumn.currentSorting) {
		return { queues: updatedQueues };
	}

	return {
		queues: updatedQueues,
		manualSorting: {
			sortedBy: manualSortColumn.id,
			sortOrder: manualSortColumn.currentSorting,
		},
	};
};

// SHIELD-3415 Methods used by queue quality only
const createQueueFromManualSorting = (
	queue: SortedQueue,
	manualSorting: ManualSorting,
): SortedQueue => {
	const { columns } = queue;

	if (find(columns, { id: manualSorting.sortedBy })) {
		return {
			...queue,
			columns: applyManualSortingToColumns(columns, manualSorting),
			manualSorting,
		};
	}

	return queue;
};

// SHIELD-3608: Payload should be of type Queue. Currently used as SortedQueue
// to support legacy
export const createSortedQueue = (
	queue: SortedQueue,
	createAnalyticsEvent?: CreateUIAnalyticsEvent,
): SortedQueue =>
	getSortingFromLocalStorage(fromQueueIdString(queue.id), createAnalyticsEvent).cata(
		() => queue,
		// @ts-expect-error - TS7006 - Parameter 'manualSorting' implicitly has an 'any' type.
		(manualSorting) => {
			const sortedQueue = createQueueFromManualSorting(queue, manualSorting);
			if (!sortedQueue.manualSorting) {
				removeSortOrderInLocalStorage(sortedQueue.id);
			}

			return sortedQueue;
		},
	);
