import React, { useState, useEffect, type ReactNode } from 'react';
import { styled, keyframes } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

export const LoadingMoreSpinner = ({
	isLoading,
	loadingMessage,
}: {
	isLoading: boolean;
	loadingMessage: ReactNode | string;
}) => {
	// isVisible will wait for the animation to complete before turning false
	const [isVisible, setIsVisible] = useState(false);

	useEffect(() => {
		if (isLoading) {
			setIsVisible(true);
		}
	}, [isLoading]);

	const onAnimationEnd = () => {
		if (!isLoading) {
			setIsVisible(false);
		}
	};

	if (!isVisible) {
		return null;
	}

	return (
		<SpinnerContainer data-testid="business-load-more-spinner">
			<SpinnerWrapper isVisible={isLoading} onAnimationEnd={onAnimationEnd}>
				<Spinner appearance="invert" size="small" />
				<Box xcss={spinnerTextStyles}>{loadingMessage}</Box>
			</SpinnerWrapper>
		</SpinnerContainer>
	);
};

const appear = keyframes({
	'0%': {
		opacity: 0,
	},
	'100%': {
		opacity: '100%',
	},
});

const disappear = keyframes({
	'0%': {
		opacity: '100%',
	},
	'100%': {
		opacity: 0,
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	position: 'absolute',
	bottom: token('space.800'),
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	width: '100%',
	zIndex: 100,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div<{ isVisible: boolean }>({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'space-around',
	paddingTop: token('space.100'),
	paddingRight: token('space.150'),
	paddingBottom: token('space.100'),
	paddingLeft: token('space.100'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.background.neutral.bold'),

	color: token('color.text.inverse'),
	borderRadius: token('border.radius', '3px'),
	font: token('font.body.small'),
	fontWeight: token('font.weight.medium'),
	boxShadow: token(
		'elevation.shadow.overlay',
		'rgb(9 30 66 / 15%) 0px 8px 12px, rgb(9 30 66 / 31%) 0px 0px 1px',
	),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isVisible }) =>
		isVisible && {
			animation: `${appear} 0.5s ease-in-out`,
		},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	...({ isVisible }) =>
		!isVisible && {
			animation: `${disappear} 0.5s ease-in-out`,
		},
});

const spinnerTextStyles = xcss({
	marginLeft: 'space.100',
});
