import React, { useCallback, useRef } from 'react';
import { styled } from '@compiled/react';
import { useFragment, graphql } from 'react-relay';
import { Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { calendarUnscheduledPanelCard_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarUnscheduledPanelCard_calendar.graphql';
import { useCalendarIssueViewMode } from '../../../../common/controllers/use-calendar-issue-view-mode/index.tsx';
import {
	useCalendarActions,
	useShowIssueKey,
} from '../../../../controllers/calendar-store/index.tsx';
import { CalendarCardAssignee } from './calendar-card-assignee/index.tsx';
import { CalendarCardKey } from './calendar-card-key/index.tsx';
import { CalendarCardPriority } from './calendar-card-priority/index.tsx';
import { CalendarCardStartDate } from './calendar-card-start-date/index.tsx';
import { CalendarCardStatus } from './calendar-card-status/index.tsx';
import { CalendarCardSummary } from './calendar-card-summary/index.tsx';
import { useDraggablePanelCard } from './use-draggable-panel-card/index.tsx';

export function CalendarUnscheduledPanelCard({
	issueRef,
	connectionRecordId,
}: {
	connectionRecordId?: string;
	issueRef: calendarUnscheduledPanelCard_calendar$key;
}) {
	const data = useFragment(
		graphql`
			fragment calendarUnscheduledPanelCard_calendar on JiraIssue
			@argumentDefinitions(
				viewId: { type: "ID" }
				schedulePermissionsEnabled: { type: "Boolean!" }
			) {
				id
				__id
				key
				...calendarCardKey_calendar
				...calendarCardSummary_calendar
				...calendarCardAssignee_calendar
				...calendarCardPriority_calendar
				...calendarCardStatus_calendar
				...calendarCardStartDate_calendar @arguments(viewId: $viewId)

				canSchedule: hasProjectPermission(permission: SCHEDULE_ISSUES)
					@include(if: $schedulePermissionsEnabled)

				issueTypeField {
					issueType {
						name
						avatar {
							small
						}
					}
				}

				requestTypeField: fieldByIdOrAlias(idOrAlias: "requesttype", ignoreMissingField: true)
					@optIn(to: "JiraIssueFieldByIdOrAlias") {
					id
					... on JiraServiceManagementRequestTypeField {
						__typename
						requestType {
							name
							avatar {
								small
							}
						}
					}
				}

				startDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
					__id
					id
					... on JiraDatePickerField {
						__typename
						date
					}
					... on JiraDateTimePickerField {
						__typename
						dateTime
					}
				}

				endDateViewField(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
					__id
					id
					... on JiraDatePickerField {
						__typename
						date
					}
					... on JiraDateTimePickerField {
						__typename
						dateTime
					}
				}
			}
		`,
		issueRef,
	);

	const containerRef = useRef<HTMLDivElement>();
	const { isDragging } = useDraggablePanelCard({
		containerRef,
		issueAri: data.id,
		issueRecordId: data.__id,
		startDateFieldRecordId: data.startDateViewField?.__id,
		endDateFieldRecordId: data.endDateViewField?.__id,
		connectionRecordId,
		endDate: data.endDateViewField?.date ?? data.endDateViewField?.dateTime,
		startDate: data.startDateViewField?.date ?? data.startDateViewField?.dateTime,
		issueKey: data.key,
		canSchedule: data.canSchedule,
	});
	const { setUnscheduledPanelIssueViewVisibility } = useCalendarActions();
	const { openIssueView } = useCalendarIssueViewMode();
	const showIssueKey = useShowIssueKey();

	const onClick = useCallback(() => {
		setUnscheduledPanelIssueViewVisibility(true);
		openIssueView(data.key);
	}, [data.key, openIssueView, setUnscheduledPanelIssueViewVisibility]);

	const issueType = data.issueTypeField?.issueType;
	const issueTypeIcon = issueType?.avatar?.small ?? '';
	const requestTypeIcon = data?.requestTypeField?.requestType?.avatar?.small;

	const requestTypeName = data?.requestTypeField?.requestType?.name;
	const issueTypeName = issueType?.name;

	// if event has request type info, then show request type icon
	const eventIcon = requestTypeIcon ?? issueTypeIcon;

	const iconDescription = requestTypeName ?? issueTypeName;

	return (
		<CalendarCardContainer
			isDragging={isDragging}
			onClick={onClick}
			data-testid="calendar.ui.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-card.calendar-card-container"
		>
			<Stack
				xcss={fg('jira-calendar-business-theme') ? cardStyles : cardStylesWithoutHoverStyling}
				space="space.100"
				ref={containerRef}
				testId="calendar.ui.calendar-unscheduled-panel.calendar-unscheduled-panel-card-list.calendar-unscheduled-panel-card.draggable"
			>
				<Inline>
					<CalendarCardSummary issueRef={data} />
					<CalendarCardAssignee issueRef={data} />
				</Inline>
				<CalendarCardStartDate issueRef={data} />
				<Inline space="space.100" alignBlock="center" grow="hug" xcss={bottomRowStyles}>
					{eventIcon && <img alt={iconDescription} src={eventIcon} height={16} width={16} />}
					{showIssueKey && <CalendarCardKey issueRef={data} />}
					<Inline space="space.100">
						<CalendarCardStatus issueRef={data} />
						<CalendarCardPriority issueRef={data} />
					</Inline>
				</Inline>
			</Stack>
		</CalendarCardContainer>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CalendarCardContainer = styled.button<{ isDragging: boolean }>({
	background: 'unset',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	opacity: ({ isDragging }) => (isDragging ? 0.5 : 'unset'),
	userSelect: 'none',
	textAlign: 'left',
	border: 'unset',
});

const cardStyles = xcss({
	boxShadow: 'elevation.shadow.raised',
	borderRadius: 'border.radius.050',
	padding: 'space.150',
	background: token('elevation.surface.raised'),
	overflow: 'hidden',
	':hover': {
		background: token('elevation.surface.raised.hovered'),
	},
});

const cardStylesWithoutHoverStyling = xcss({
	boxShadow: 'elevation.shadow.raised',
	borderRadius: 'border.radius.050',
	padding: 'space.150',
	background: token('elevation.surface.raised'),
	overflow: 'hidden',
});

const bottomRowStyles = xcss({ overflow: 'hidden' });
