import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';

type Props = {
	border?: boolean;
	children: ReactNode;
};

export default function Blanket(props: Props) {
	const { border, children } = props;
	return (
		<BlanketContainer>
			<BackgroundContainer>
				<Background border={border} />
			</BackgroundContainer>
			<ComponentContainer>{children}</ComponentContainer>
			<SpinnerContainer role="status" aria-label="Loading">
				<Spinner />
			</SpinnerContainer>
		</BlanketContainer>
	);
}

Blanket.defaultProps = {
	border: true,
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BlanketContainer = styled.div({
	position: 'relative',
	display: 'inline-flex',
	flexDirection: 'row',
	height: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerContainer = styled.div({
	position: 'absolute',
	top: 0,
	left: 0,
	height: '100%',
	width: '100%',
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	boxSizing: 'border-box',
	paddingRight: token('space.100'),
	paddingLeft: token('space.100'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const BackgroundContainer = styled.div({
	position: 'absolute',
	height: '100%',
	width: '100%',
	boxSizing: 'border-box',
	paddingTop: token('space.025'),
	paddingRight: token('space.025'),
	paddingBottom: token('space.025'),
	paddingLeft: token('space.025'),
	overflow: 'auto',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Background = styled.div<{ border?: boolean }>({
	position: 'relative',
	height: '100%',
	width: '100%',
	boxSizing: 'border-box',
	backgroundColor: `${token('color.background.accent.gray.subtlest')}`,
	borderRadius: '3px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	border: `${(props: { border?: boolean }) =>
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		props.border ? `1px solid ${token('color.border.disabled', colors.N30)}` : 'none'}`,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ComponentContainer = styled.div({
	opacity: 0.2,
	flex: '1 1 auto',
});
