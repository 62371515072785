/** @jsx jsx */
import React, { useCallback, type MouseEvent, type ReactNode } from 'react';
import { styled, jsx, css } from '@compiled/react';
import addMonths from 'date-fns/addMonths';
import addWeeks from 'date-fns/addWeeks';
import format from 'date-fns/format';
import isSameMonth from 'date-fns/isSameMonth';
import isSameWeek from 'date-fns/isSameWeek';
import startOfMonth from 'date-fns/startOfMonth';
import { token } from '@atlaskit/tokens';
import Button from '@atlaskit/button';
import ChevronLeftLargeIcon from '@atlaskit/icon/utility/migration/chevron-left--chevron-left-large';
import ChevronRightLargeIcon from '@atlaskit/icon/utility/migration/chevron-right--chevron-right-large';
import ChevronLeftIcon from '@atlaskit/icon/utility/chevron-left';
import ChevronRightIcon from '@atlaskit/icon/utility/chevron-right';
import { Box, Inline, Text, xcss } from '@atlaskit/primitives';
import Tooltip from '@atlaskit/tooltip';
import { Tokens } from '@atlassian/jira-custom-theme-constants/src/constants.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { fireUIAnalytics, type UIAnalyticsEvent } from '@atlassian/jira-product-analytics-bridge';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import {
	useDateRange,
	useCalendarActions,
	useViewRange,
} from '../../../controllers/calendar-store/index.tsx';
import { messages } from './messages.tsx';

const doNothing = (x: Date) => x;

/**
 * Control the currently visible date on the calendar app.
 */
export function DateControls({
	currentDate,
	setCurrentDate,
	getToday = () => new Date(),
}: {
	currentDate: Date;
	setCurrentDate: (d: Date) => void;
	getToday?: () => Date;
}) {
	const [, setDateQueryParam] = useQueryParam('date');
	// eslint-disable-next-line react-hooks/rules-of-hooks
	const viewRange = fg('jsd_shield_jsm_calendar_weekly_view') ? useViewRange() : 'month';
	const addRangeUnit =
		viewRange === 'week' && fg('jsd_shield_jsm_calendar_weekly_view') ? addWeeks : addMonths;
	const startOfRangeUnit =
		viewRange === 'week' && fg('jsd_shield_jsm_calendar_weekly_view') ? doNothing : startOfMonth;
	const replaceRangeUnit =
		viewRange === 'week' && fg('jsd_shield_jsm_calendar_weekly_view') ? isSameWeek : isSameMonth;

	const replaceDateQueryParam = (targetDate: Date) => {
		if (replaceRangeUnit(getToday(), targetDate)) {
			setDateQueryParam(undefined, 'replace');
		} else {
			setDateQueryParam(format(targetDate, 'yyyy-MM-dd'), 'replace');
		}
	};

	const onClickPrevious = (_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const previousSlot = addRangeUnit(startOfRangeUnit(currentDate), -1);
		replaceDateQueryParam(previousSlot);
		setCurrentDate(previousSlot);
		fireUIAnalytics(analyticsEvent, 'previousMonth');
	};

	const onClickToday = (_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const currentSlot = fg('jsd_shield_jsm_calendar_weekly_view')
			? getToday()
			: startOfMonth(getToday());
		replaceDateQueryParam(currentSlot);
		setCurrentDate(currentSlot);
		fireUIAnalytics(analyticsEvent, 'currentMonth');
	};

	const onClickNext = (_: MouseEvent<HTMLElement>, analyticsEvent: UIAnalyticsEvent) => {
		const nextSlot = addRangeUnit(startOfRangeUnit(currentDate), 1);
		replaceDateQueryParam(nextSlot);
		setCurrentDate(nextSlot);
		fireUIAnalytics(analyticsEvent, 'nextMonth');
	};

	const { formatDate, formatMessage } = useIntl();

	let monthSelector: ReactNode = <></>;

	if (isVisualRefreshEnabled()) {
		monthSelector = (
			<div css={monthSelectorHackStyles}>
				{isVisualRefreshEnabled() && fg('visual-refresh_drop_2') ? (
					<Box xcss={monthSelectorStyles}>
						<ThemedButton
							onClick={onClickPrevious}
							testId="calendar.ui.calendar-top-controls-bar.date-controls.previous"
							appearance="subtle"
							iconAfter={
								<ChevronLeftIcon spacing="spacious" label={formatMessage(messages.previous)} />
							}
						/>
						{fg('visual-refresh_drop_4') ? (
							<Box
								as="span"
								color="inherit"
								testId="calendar.ui.calendar-top-controls-bar.date-controls.current-date"
								xcss={monthTextStyles}
							>
								{formatDate(currentDate, {
									year: 'numeric',
									month: 'short',
								})}
							</Box>
						) : (
							<Text
								color="inherit"
								testId="calendar.ui.calendar-top-controls-bar.date-controls.current-date"
								weight="medium"
							>
								{formatDate(currentDate, {
									year: 'numeric',
									month: 'short',
								})}
							</Text>
						)}
						<ThemedButton
							onClick={onClickNext}
							testId="calendar.ui.calendar-top-controls-bar.date-controls.next"
							appearance="subtle"
							iconAfter={
								<ChevronRightIcon spacing="spacious" label={formatMessage(messages.next)} />
							}
						/>
					</Box>
				) : (
					<Box xcss={monthSelectorStylesOld}>
						<ThemedButton
							onClick={onClickPrevious}
							testId="calendar.ui.calendar-top-controls-bar.date-controls.previous"
							appearance="subtle"
						>
							<Inline alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
								<ChevronLeftLargeIcon spacing="spacious" label={formatMessage(messages.previous)} />
							</Inline>
						</ThemedButton>
						<Text
							color="inherit"
							testId="calendar.ui.calendar-top-controls-bar.date-controls.current-date"
						>
							{formatDate(currentDate, {
								year: 'numeric',
								month: 'long',
							})}
						</Text>
						<ThemedButton
							onClick={onClickNext}
							testId="calendar.ui.calendar-top-controls-bar.date-controls.next"
							appearance="subtle"
						>
							<Inline alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
								<ChevronRightLargeIcon spacing="spacious" label={formatMessage(messages.next)} />
							</Inline>
						</ThemedButton>
					</Box>
				)}
			</div>
		);
	}

	const todayButton = (
		<ThemedButton
			onClick={onClickToday}
			testId="calendar.ui.calendar-top-controls-bar.date-controls.today"
			title={
				!fg('visual-refresh_drop_5')
					? formatDate(new Date(), {
							year: 'numeric',
							month: 'short',
							day: 'numeric',
						})
					: undefined
			}
		>
			{formatMessage(messages.today)}
		</ThemedButton>
	);

	return fg('jira-calendar-business-theme') ? (
		<Inline space="space.100" alignBlock="center" alignInline="end">
			{!isVisualRefreshEnabled() && (
				<>
					<CurrentMonthWrapper data-testid="calendar.ui.calendar-top-controls-bar.date-controls.current-date">
						{formatDate(currentDate, {
							year: 'numeric',
							month: 'long',
						})}
					</CurrentMonthWrapper>

					<ThemedButton
						onClick={onClickPrevious}
						testId="calendar.ui.calendar-top-controls-bar.date-controls.previous"
					>
						<Inline alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
							<ChevronLeftLargeIcon spacing="spacious" label={formatMessage(messages.previous)} />
						</Inline>
					</ThemedButton>
					<ThemedButton
						onClick={onClickNext}
						testId="calendar.ui.calendar-top-controls-bar.date-controls.next"
					>
						<Inline alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
							<ChevronRightLargeIcon spacing="spacious" label={formatMessage(messages.next)} />
						</Inline>
					</ThemedButton>
				</>
			)}

			{isVisualRefreshEnabled() &&
				(fg('visual-refresh_drop_5') ? (
					<Tooltip
						content={
							fg('visual-refresh_drop_6')
								? format(new Date(), 'EEEE, MMMM dd, yyyy')
								: formatDate(new Date(), {
										year: 'numeric',
										month: 'short',
										day: 'numeric',
									})
						}
					>
						{todayButton}
					</Tooltip>
				) : (
					todayButton
				))}

			{isVisualRefreshEnabled() && monthSelector}

			{!isVisualRefreshEnabled() && (
				<ThemedButton
					onClick={onClickToday}
					testId="calendar.ui.calendar-top-controls-bar.date-controls.today"
				>
					{formatMessage(messages.today)}
				</ThemedButton>
			)}
		</Inline>
	) : (
		<Inline space="space.100" alignBlock="center" alignInline="end">
			<CurrentMonthWrapperWithoutJwmTheming data-testid="calendar.ui.calendar-top-controls-bar.date-controls.current-date">
				{formatDate(currentDate, {
					year: 'numeric',
					month: 'long',
				})}
			</CurrentMonthWrapperWithoutJwmTheming>

			<Button
				onClick={onClickPrevious}
				testId="calendar.ui.calendar-top-controls-bar.date-controls.previous"
			>
				<Inline alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
					<ChevronLeftLargeIcon spacing="spacious" label={formatMessage(messages.previous)} />
				</Inline>
			</Button>
			<Button
				onClick={onClickNext}
				testId="calendar.ui.calendar-top-controls-bar.date-controls.next"
			>
				<Inline alignBlock="center" alignInline="center" xcss={iconWrapperStyles}>
					<ChevronRightLargeIcon spacing="spacious" label={formatMessage(messages.next)} />
				</Inline>
			</Button>

			<Button
				onClick={onClickToday}
				testId="calendar.ui.calendar-top-controls-bar.date-controls.today"
			>
				{formatMessage(messages.today)}
			</Button>
		</Inline>
	);
}

export function ConnectedDateControls() {
	const { currentDate } = useDateRange();
	const { setDateRange } = useCalendarActions();
	const setCurrentDate = useCallback(
		(date: Date) => setDateRange({ currentDate: date }),
		[setDateRange],
	);

	return <DateControls currentDate={currentDate} setCurrentDate={setCurrentDate} />;
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CurrentMonthWrapper = styled.div({
	display: 'flex',
	whiteSpace: 'nowrap',
	alignItems: 'center',
	justifyContent: 'center',
	fontWeight: token('font.weight.bold'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CurrentMonthWrapperWithoutJwmTheming = styled.div({
	display: 'flex',
	whiteSpace: 'nowrap',
	alignItems: 'center',
	justifyContent: 'center',
	fontWeight: token('font.weight.bold'),
});

const iconWrapperStyles = xcss({ height: 'size.300' });

const monthSelectorStylesOld = xcss({
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	border: `1px solid ${Tokens.COLOR_BORDER}`,
	borderRadius: '3px',
	gap: 'space.100',
	height: '32px',
});

const monthSelectorStyles = xcss({
	boxSizing: 'border-box',
	display: 'flex',
	justifyContent: 'space-between',
	alignItems: 'center',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/ui-styling-standard/no-imported-style-values
	border: `1px solid ${Tokens.COLOR_BORDER}`,
	borderRadius: '3px',
	gap: 'space.050',
	height: '32px',
});

const monthTextStyles = xcss({
	fontWeight: 'font.weight.medium',
	minWidth: '70px',
	textAlign: 'center',
});

const monthSelectorHackStyles = css({
	display: 'contents',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: Tokens.COLOR_TEXT,
});
