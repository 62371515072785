import React, { useRef, useState, useCallback, type MouseEvent } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import ReleaseIcon from '@atlaskit/icon/core/migration/release--ship';
import { Pressable, Inline, xcss, type BorderRadius, Box } from '@atlaskit/primitives';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer$key } from '@atlassian/jira-relay/src/__generated__/crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer.graphql';
import { ChangeIndicator } from '../../../../common/ui/change-indicator/index.tsx';
import { COMPLETE, OFF_TRACK, ON_TRACK } from '../../../../common/ui/release-icon/constants.tsx';
import { ReleaseIcon as CustomReleaseIcon } from '../../../../common/ui/release-icon/index.tsx';
import { getReleaseStatus } from '../../../../common/ui/release-icon/utils.tsx';
import { useColorBy } from '../../../../controllers/calendar-store/index.tsx';
import { useIsVisibleInMoreLinkPopover } from '../../../../controllers/use-is-visible-in-more-link-popover/index.tsx';
import CrossProjectVersionFlyout from './flyout/index.tsx';

interface VersionEventRendererProps {
	versionRef: crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer$key;
}

export function CrossProjectVersionEventRenderer({ versionRef }: VersionEventRendererProps) {
	const version =
		useFragment<crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer$key>(
			graphql`
				fragment crossProjectVersionRenderer_calendar_CrossProjectVersionEventRenderer on JiraCrossProjectVersion {
					name
					releaseDate
					status
					projects {
						id
						name
						avatar {
							xsmall
						}
					}
					crossProjectVersionScenarioValues {
						scenarioType
						name
					}
					...flyout_calendar_CrossProjectVersionFlyout
				}
			`,
			versionRef,
		);

	const versionEventRef = useRef<HTMLDivElement>(null);

	const { isVisible } = useIsVisibleInMoreLinkPopover(versionEventRef);

	const colorBy = useColorBy();

	const versionName = version.crossProjectVersionScenarioValues?.name ?? version.name;
	const releaseDate = version.releaseDate;

	const status =
		version.status && releaseDate ? getReleaseStatus(version.status, releaseDate) : null;

	const hasChangedIndicator =
		version.crossProjectVersionScenarioValues?.scenarioType !== null &&
		version.crossProjectVersionScenarioValues?.scenarioType !== undefined;

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onEventCardClick = useCallback(() => {
		setIsOpen(!isOpen);

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'versionEventCard',
			}),
			'calendar',
		);
	}, [createAnalyticsEvent, isOpen]);

	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

	if (!isVisible) return null;

	return (
		<JiraPopup
			isOpen={isOpen}
			onClose={onClose}
			placement="bottom-start"
			content={() => <CrossProjectVersionFlyout onClose={onClose} versionRef={version} />}
			messageId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.jira-popup"
			messageType="transactional"
			trigger={({ ref: popupRef, ...popupTriggerProps }) => (
				<Tooltip position="bottom" content={version.name}>
					{({ ref: tooltipRef, ...tooltipProps }) => (
						<Pressable
							{...popupTriggerProps}
							{...tooltipProps}
							testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.version-event-card"
							ref={mergeRefs(popupRef, tooltipRef, versionEventRef)}
							xcss={[
								eventCardInlineStyles,
								hasChangedIndicator && extraLeftPaddingStyles,
								...(colorBy === 'status'
									? [
											status === OFF_TRACK && overdueStyles,
											status === COMPLETE && releasedStyles,
											status === ON_TRACK && unreleasedStyles,
										]
									: [defaultStyles]),
							]}
							{...popupTriggerProps}
							onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
								// Prevents fullcalendar from handling mouse events and interfering with pragmatic dnd
								e.stopPropagation();
							}}
							onClick={onEventCardClick}
						>
							<ReleaseIcon color={token('color.icon')} LEGACY_size="small" label="" />
							<Inline space="space.050" as="span" xcss={eventNameInlineStyles} alignBlock="center">
								{versionName}
							</Inline>
							{version.status && releaseDate && (
								<Box xcss={releaseIconWrapperStyles}>
									<CustomReleaseIcon status={version.status} releaseDate={releaseDate} />
								</Box>
							)}
							{(version.projects ?? []).map((project) =>
								project?.avatar?.xsmall ? (
									<ProjectAvatar
										key={project?.id}
										alt={`${project?.name} project avatar`}
										src={project?.avatar?.xsmall}
									/>
								) : null,
							)}
							{hasChangedIndicator && <ChangeIndicator />}
						</Pressable>
					)}
				</Tooltip>
			)}
		/>
	);
}

const eventCardInlineStyles = xcss({
	position: 'relative',
	display: 'flex',
	gap: 'space.050',
	padding: 'space.050',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	alignItems: 'center',
	boxSizing: 'border-box',
	width: '100%',
	height: '24px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	backgroundColor: 'color.background.accent.gray.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.gray.subtlest.pressed',
	},
	color: 'color.text',
	borderRadius:
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		`var(--fc-event-border-left-radius, 0)
        var(--fc-event-border-right-radius, 0)
        var(--fc-event-border-right-radius, 0)
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        var(--fc-event-border-left-radius, 0)` as BorderRadius,
});

const unreleasedStyles = xcss({
	backgroundColor: 'color.background.accent.blue.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.blue.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.blue.subtlest.pressed',
	},
});

const releasedStyles = xcss({
	backgroundColor: 'color.background.accent.green.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.green.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.green.subtlest.pressed',
	},
});

const overdueStyles = xcss({
	backgroundColor: 'color.background.accent.red.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.red.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.red.subtlest.pressed',
	},
});

const defaultStyles = xcss({
	backgroundColor: 'elevation.surface.raised',
	':hover': {
		backgroundColor: 'elevation.surface.raised.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.raised.pressed',
	},
	border: '1px solid',
	borderColor: 'color.border',
});

const extraLeftPaddingStyles = xcss({
	paddingLeft: 'space.100',
	overflow: 'hidden',
});

const eventNameInlineStyles = xcss({
	display: 'inline',
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
});

const releaseIconWrapperStyles = xcss({
	flex: 1,
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectAvatar = styled.img({
	height: token('space.200'),
	width: token('space.200'),
	borderRadius: token('border.radius'),
});
