/** @jsx jsx */
// eslint-disable-next-line jira/restricted/react
import React, { PureComponent, type ComponentType, type ReactNode } from 'react';
import { css, cssMap, jsx } from '@compiled/react';
import { Box, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import type { Column, ColumnId } from '../../../../../../model/columns/index.tsx';
import { getWidth } from '../../../../common/styled.tsx';

type ColumnComponentType = ComponentType<{
	id: ColumnId;
	width: number;
	isFirstColumn: boolean;
}>;

type Props = {
	id: ColumnId;
	width: number;
	column?: Column; // make this required when we tidy up the feature flag css_support_for_virtual_table
	isFirstColumn: boolean;
	shouldUseCssForRendering: boolean;
	DefaultColumn: ColumnComponentType;
};

// eslint-disable-next-line jira/react/no-class-components
export default class ColumnWrapper extends PureComponent<Props> {
	static defaultProps: Partial<Props> = {};

	render() {
		const { id, width, column, isFirstColumn, shouldUseCssForRendering, DefaultColumn } =
			this.props;

		if (shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
			return (
				<Wrapper
					column={column}
					width={width}
					isFirstColumn={isFirstColumn}
					shouldUseCssForRendering={shouldUseCssForRendering}
				>
					<DefaultColumn id={id} width={width} isFirstColumn={isFirstColumn} />
				</Wrapper>
			);
		}

		return (
			<Wrapper width={width} isFirstColumn={isFirstColumn}>
				<DefaultColumn id={id} width={width} isFirstColumn={isFirstColumn} />
			</Wrapper>
		);
	}
}

const wrapperMarginLeftStylesMap = cssMap({
	firstColumn: {
		marginLeft: token('space.300'),
	},
	otherColumn: {
		marginLeft: token('space.0'),
	},
});

const Wrapper = ({
	isFirstColumn,
	shouldUseCssForRendering,
	width,
	column,
	children,
}: {
	isFirstColumn: boolean;
	shouldUseCssForRendering?: boolean;
	width: number;
	column?: Column; // make this required when we tidy up the feature flag css_support_for_virtual_table
	children: ReactNode;
}) => {
	if (column && shouldUseCssForRendering && fg('css_support_for_virtual_table')) {
		return (
			<Box
				style={{
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					minWidth: getWidth({ width: column.minWidth, isFirstColumn }),
					// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
					maxWidth: getWidth({ width: column.maxWidth, isFirstColumn }),
				}}
				xcss={[
					fg('table_headers_widths_vs_column_widths') ? wrapperStyles : wrapperStylesOld,
					...(isFirstColumn ? [firstColumnStyles] : [otherColumnStyles]),
				]}
				// eslint-disable-next-line react/jsx-props-no-spreading
				{...(fg('jsm_virtual_table_a11y') && { role: 'columnheader' })}
			>
				{children}
			</Box>
		);
	}

	return (
		<div
			css={[
				cssWrapperStyles,
				wrapperMarginLeftStylesMap[isFirstColumn ? 'firstColumn' : 'otherColumn'],
			]}
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			style={{ width: getWidth({ width, isFirstColumn }) }}
			{...(fg('jsm_virtual_table_a11y') && { role: 'columnheader' })}
		>
			{children}
		</div>
	);
};

const cssWrapperStyles = css({
	flex: '0 0 auto',
	display: 'flex',
	position: 'relative',
});

const wrapperStylesOld = xcss({
	flexGrow: 1,
});

const wrapperStyles = xcss({
	width: '100%',
	flex: '1 1 auto',
});

const firstColumnStyles = xcss({
	marginLeft: 'space.300',
});

const otherColumnStyles = xcss({
	marginLeft: 'space.0',
});
