import React from 'react';
import { styled } from '@compiled/react';
import ArrowRightIcon from '@atlaskit/icon/core/arrow-right';
import CheckIcon from '@atlaskit/icon/core/check-mark';
import EmojiFrequentIcon from '@atlaskit/icon/core/clock';
import { G500, B500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { MessageDescriptorV2 } from '@atlassian/jira-intl/src/v2/types.tsx';
import { messages } from './messages.tsx';

const ISSUE_EVENT_STATE_INPROGRESS = 'in-progress';
const ISSUE_EVENT_STATE_RESOLVED = 'resolved';
const ISSUE_EVENT_STATE_OVERDUE = 'overdue';

type IconConfig = {
	[key: string]: {
		Component: React.ElementType;
		color: string;
		message: MessageDescriptorV2;
	};
};

const iconConfig: IconConfig = {
	[ISSUE_EVENT_STATE_INPROGRESS]: {
		Component: ArrowRightIcon,
		color: token('color.icon.accent.blue', B500),
		message: messages.inprogressLabel,
	},
	[ISSUE_EVENT_STATE_RESOLVED]: {
		Component: CheckIcon,
		color: token('color.icon.accent.green', G500),
		message: messages.doneLabel,
	},
	[ISSUE_EVENT_STATE_OVERDUE]: {
		Component: EmojiFrequentIcon,
		color: token('color.icon.danger'),
		message: messages.overdueLabel,
	},
};

export const IconRenderer = ({ issueState }: { issueState: string }) => {
	const { formatMessage } = useIntl();
	const { Component, color, message } = iconConfig[issueState] || {};

	return Component ? (
		<IconContainer>
			<Component label={formatMessage(message)} color={color} />
		</IconContainer>
	) : null;
};

// Force 12px icon size until utility icon for calendar is available: https://hello.atlassian.net/wiki/spaces/DST/pages/3748692796/New+ADS+iconography+-+Code+migration+guide#Details%3A-What%E2%80%99s-changed-with-Icon%3F
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const IconContainer = styled.span({
	font: token('font.body.UNSAFE_small'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	svg: {
		height: '12px',
		width: '12px',
	},
});
