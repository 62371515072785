import React, { type ComponentType, type FC } from 'react';
import Button from '@atlaskit/button';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import Placeholder from '@atlassian/jira-placeholder/src/index.tsx';
import { lazyForPaint } from '@atlassian/react-loosely-lazy';
import type { Props } from './index.tsx';

// eslint-disable-next-line jira/deprecations/no-rll-client-async-experiences
const MeatballMenu = lazyForPaint<ComponentType<Props>>(
	() => import(/* webpackChunkName: "async-meatball-menu" */ './index'),
	{ ssr: false },
);

const AsyncMeatballMenu: FC<Props> = (props: Props) => (
	<Placeholder
		name="async-meatball-menu"
		fallback={
			<Button
				appearance="subtle"
				iconBefore={<MoreIcon label="" LEGACY_size="medium" spacing="spacious" />}
				isDisabled
			/>
		}
	>
		<MeatballMenu {...props} />
	</Placeholder>
);
export default AsyncMeatballMenu;
