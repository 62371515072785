import { localStorage, VIEW_MODE_STORAGE_KEY } from './constants.tsx';
import { getChildIssuesLimit as getChildIssuesLimitSelector } from './selectors.tsx';
import type { StoreApi, IssueContext, ActivityViewModeType } from './types.tsx';
import { getViewModeFromLocalStorage } from './utils.tsx';

export const setIssueContext =
	(issueContext: IssueContext) =>
	({ setState, getState }: StoreApi) => {
		const { issueKey: currentIssueKey, issueId: currentIssueId } = getState();

		// ARKEN-792: reset issueId on issueKey change if issueId isn't provided
		if (
			currentIssueId &&
			currentIssueKey &&
			!issueContext.issueId &&
			issueContext.issueKey !== currentIssueKey
		) {
			setState({
				...issueContext,
				issueId: undefined,
				viewMode: issueContext.viewMode || getState().viewMode,
			});
		} else {
			setState({
				...issueContext,
				viewMode: issueContext.viewMode || getState().viewMode,
			});
		}
	};

export const mergeIssueContext =
	(partialIssueContext: Partial<IssueContext>) =>
	({ getState, setState }: StoreApi) => {
		const { issueKey: currentIssueKey, issueId: currentIssueId } = getState();

		// ARKEN-792: reset issueId on issueKey change if issueId isn't provided
		if (
			currentIssueId &&
			currentIssueKey &&
			!partialIssueContext.issueId &&
			partialIssueContext.issueKey &&
			partialIssueContext.issueKey !== currentIssueKey
		) {
			setState({ ...getState(), ...partialIssueContext, issueId: undefined });
		} else {
			setState({ ...getState(), ...partialIssueContext });
		}
	};

export const getChildIssuesLimit =
	() =>
	({ getState }: StoreApi) =>
		getChildIssuesLimitSelector(getState());

export const getMaxTokenLength =
	() =>
	({ getState }: StoreApi) =>
		getState().maxTokenLength;

export const getIsLoadedWithPage =
	() =>
	({ getState }: StoreApi) =>
		getState().isLoadedWithPage;

export const getBackButtonUrl =
	() =>
	({ getState }: StoreApi) =>
		getState().backButtonUrl;

export const getIsSpaEnabled =
	() =>
	({ getState }: StoreApi) =>
		getState().isSpaEnabled;

export const getIssueKey =
	() =>
	({ getState }: StoreApi) =>
		getState().issueKey;

export const getIsArchived =
	() =>
	({ getState }: StoreApi) =>
		getState().isArchived;

export const setDetailViewAsModal =
	(willSave = true) =>
	({ setState }: StoreApi) => {
		if (willSave) {
			localStorage.set(VIEW_MODE_STORAGE_KEY, 'MODAL');
		}
		setState({
			viewMode: 'MODAL',
		});
	};

export const setDetailViewAsSidebar =
	(willSave = true) =>
	({ setState }: StoreApi) => {
		if (willSave) {
			localStorage.set(VIEW_MODE_STORAGE_KEY, 'SIDEBAR');
		}
		setState({
			viewMode: 'SIDEBAR',
		});
	};

export const resetDetailView =
	() =>
	({ setState }: StoreApi) => {
		setState({
			viewMode: getViewModeFromLocalStorage(),
		});
	};

export const setActivityLayoutView =
	(layoutView: ActivityViewModeType = 'VERTICAL') =>
	({ setState, getState }: StoreApi) => {
		setState({
			...getState(),
			activityLayoutView: layoutView,
		});
	};

export const actions = {
	setIssueContext,
	mergeIssueContext,
	getChildIssuesLimit,
	getMaxTokenLength,
	getIsLoadedWithPage,
	getBackButtonUrl,
	getIsSpaEnabled,
	getIssueKey,
	getIsArchived,
	setDetailViewAsModal,
	setDetailViewAsSidebar,
	resetDetailView,
	setActivityLayoutView,
} as const;
