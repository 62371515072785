import React from 'react';
import { styled } from '@compiled/react';
import EmptyState from '@atlaskit/empty-state';
import { useThemeObserver, token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import { MAX_IMAGE_HEIGHT, MAX_IMAGE_WIDTH } from '../../../../../constants.tsx';
import { EMPTY_STATE_DATA } from './constants.tsx';

export const EmptyQueueAgentView = () => {
	const { formatMessage } = useIntl();
	const [practice] = usePathParam('practiceType');
	const { colorMode } = useThemeObserver();
	// practice should always be set in case of an unconfigured queue, if not this is a wrong route
	if (practice === undefined) {
		return null;
	}

	const { headerMessage, descriptionMessage, getImageUrl, learnMoreText, learnMoreUrl } =
		EMPTY_STATE_DATA[toItsmPractice(practice)];

	return (
		<EmptyState
			header={formatMessage(headerMessage)}
			description={<Description>{formatMessage(descriptionMessage)}</Description>}
			imageUrl={getImageUrl(colorMode)}
			primaryAction={
				learnMoreText &&
				learnMoreUrl && (
					<LearnMoreLink target="_blank" href={learnMoreUrl}>
						{formatMessage(learnMoreText)}
					</LearnMoreLink>
				)
			}
			maxImageWidth={MAX_IMAGE_WIDTH}
			maxImageHeight={MAX_IMAGE_HEIGHT}
			testId="servicedesk-queues-empty-queue.common.ui.empty-practice-queue.empty-unconfigured-practice-queue.empty-queue-agent-view.empty-queue-agent-view"
		/>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const LearnMoreLink = styled.a({
	display: 'block',
	marginBottom: `${token('space.300')} 0`,
	textAlign: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled
const Description = styled.div({
	marginTop: token('space.300'),
	marginRight: 0,
	marginBottom: token('space.300'),
	marginLeft: 0,
	textAlign: 'left',
	width: '495px',
});
