import React, { useMemo } from 'react';
import { differenceInDays } from 'date-fns';
import { graphql, useFragment } from 'react-relay';
import Heading from '@atlaskit/heading';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import ShipIcon from '@atlaskit/icon/core/migration/release--ship';
import Lozenge, { type LozengeProps } from '@atlaskit/lozenge';
import { Pressable, Box, Inline, Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
} from '@atlassian/jira-product-analytics-bridge';
import type {
	flyout_calendar_CrossProjectVersionFlyout$key,
	flyout_calendar_CrossProjectVersionFlyout$data,
} from '@atlassian/jira-relay/src/__generated__/flyout_calendar_CrossProjectVersionFlyout.graphql';
import { DEFAULT_FORMAT_DATE_OPTIONS } from '../../../../../common/constants.tsx';
import { ChangeIndicator } from '../../../../../common/ui/change-indicator/index.tsx';
import { toLocalDate } from '../../../../../common/utils/dates/index.tsx';
import { messages } from './messages.tsx';

type Projects = NonNullable<flyout_calendar_CrossProjectVersionFlyout$data['projects']>;
type Project = NonNullable<Projects[0]>;

const StatusLabel = {
	UNRELEASED: messages.unreleasedStatusLabel,
	RELEASED: messages.releasedStatusLabel,
	OVERDUE: messages.overdueStatusLabel,
};

const LozengeAppearance: { [key: string]: LozengeProps['appearance'] } = {
	UNRELEASED: 'inprogress',
	RELEASED: 'success',
	OVERDUE: 'removed',
};

type Props = {
	versionRef: flyout_calendar_CrossProjectVersionFlyout$key;
	onClose: () => void;
};
export const CrossProjectVersionFlyout = ({ versionRef, onClose }: Props) => {
	const data = useFragment(
		graphql`
			fragment flyout_calendar_CrossProjectVersionFlyout on JiraCrossProjectVersion {
				name
				startDate
				releaseDate
				status
				projects {
					id
					name
					avatar {
						xsmall
					}
					projectTypeName
				}
				crossProjectVersionScenarioValues {
					name
				}
			}
		`,
		versionRef,
	);

	const [isOverdue, numberDaysOverdue] = useMemo(() => {
		const overdueDays =
			data.status === 'UNRELEASED' && data.releaseDate != null
				? Math.max(differenceInDays(new Date(), toLocalDate(data.releaseDate)), 0)
				: 0;
		return [overdueDays > 0, overdueDays];
	}, [data]);

	const { formatMessage, formatDate } = useIntl();

	const analyticsAttributes = useMemo(
		() => ({
			isOverdue,
			isReleased: data.status === 'RELEASED',
		}),
		[data.status, isOverdue],
	);

	const hasChangedIndicator = data.crossProjectVersionScenarioValues !== null;
	const versionName = data.crossProjectVersionScenarioValues?.name ?? data.name;

	return (
		<ContextualAnalyticsData
			sourceType="InlineDialog"
			sourceName="releaseFlyout"
			attributes={analyticsAttributes}
		>
			<Stack
				xcss={containerStackStyles}
				space="space.200"
				testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout"
				role="group"
			>
				<Inline alignBlock="center" xcss={titleContainerInlineStyles}>
					<Inline alignBlock="center" space="space.050" xcss={titleInlineStyles}>
						<ShipIcon label="" LEGACY_size="small" />
						<Heading size="xxsmall" as="h6">
							{formatMessage(messages.titleLabel)}
						</Heading>
					</Inline>
					<Pressable
						xcss={closeButtonStyle}
						onClick={onClose}
						testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.close-button"
					>
						<CrossIcon label={formatMessage(messages.closeButtonLabel)} LEGACY_size="small" />
					</Pressable>
				</Inline>

				{(data.status === 'RELEASED' || data.status === 'UNRELEASED') && (
					<Inline>
						<Stack alignBlock="start" space="space.050" xcss={fieldValueInlineStyles}>
							<Box as="span">
								<Lozenge
									testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.status"
									appearance={LozengeAppearance[isOverdue ? 'OVERDUE' : data.status]}
								>
									{formatMessage(StatusLabel[isOverdue ? 'OVERDUE' : data.status])}
								</Lozenge>
							</Box>
						</Stack>
						{isOverdue && (
							<Stack alignInline="end" space="space.050" xcss={fieldValueInlineStyles}>
								<Box
									as="span"
									xcss={overdueDaysStyles}
									testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.overdue-warning"
								>
									{formatMessage(messages.overdueDaysLabel, {
										numberDaysOverdue,
									})}
								</Box>
							</Stack>
						)}
					</Inline>
				)}

				<Inline xcss={nameInlineStyles}>
					{versionName}
					{hasChangedIndicator && <ChangeIndicator appearance="triangle" />}
				</Inline>

				{(data.startDate || data.releaseDate) && (
					<Inline>
						{data.startDate && (
							<Stack
								testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.start-date"
								alignBlock="start"
								space="space.050"
								xcss={fieldValueInlineStyles}
							>
								<Box as="span" xcss={fieldLabelInlineStyles}>
									{formatMessage(messages.startDateLabel)}
								</Box>
								{formatDate(toLocalDate(data.startDate), DEFAULT_FORMAT_DATE_OPTIONS)}
							</Stack>
						)}
						{data.releaseDate && (
							<Stack
								testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.release-date"
								alignBlock="start"
								space="space.050"
								xcss={[fieldValueInlineStyles, isOverdue && overdueTextStyles]}
							>
								<Box as="span" xcss={fieldLabelInlineStyles}>
									{formatMessage(messages.releaseDateLabel)}
								</Box>
								{formatDate(toLocalDate(data.releaseDate), DEFAULT_FORMAT_DATE_OPTIONS)}
							</Stack>
						)}
					</Inline>
				)}

				{(data.projects ?? []).length > 0 && (
					<Stack
						testId="calendar.ui.calendar-renderer.event-renderer.cross-project-version-renderer.flyout.project"
						space="space.100"
					>
						<Inline xcss={fieldLabelInlineStyles}>{formatMessage(messages.projectLabel)}</Inline>
						{(data.projects ?? []).map((project: Project | null | undefined) =>
							project !== null && project !== undefined ? (
								<Inline space="space.100" alignBlock="center" key={project.id}>
									{project.avatar?.xsmall && (
										<Stack space="space.0" xcss={projectAvatarStyle}>
											<img alt={`${project.name} project avatar`} src={project.avatar?.xsmall} />
										</Stack>
									)}
									<Stack space="space.0">
										<Box as="span" xcss={projectNameStyles}>
											{project.name}
										</Box>
										{project.projectTypeName && (
											<Box as="span" xcss={projectMetadataStyles}>
												{project.projectTypeName}
											</Box>
										)}
									</Stack>
								</Inline>
							) : null,
						)}
					</Stack>
				)}
			</Stack>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

export default CrossProjectVersionFlyout;

const containerStackStyles = xcss({
	padding: 'space.200',
	width: '280px',
});

const titleContainerInlineStyles = xcss({
	height: token('space.300'),
});

const titleInlineStyles = xcss({
	flex: 1,
	color: 'color.text.subtlest',
});

const closeButtonStyle = xcss({
	border: 'none',
	background: 'none',
	cursor: 'pointer',
});

const nameInlineStyles = xcss({
	font: token('font.body.large'),
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	position: 'relative',
});

const fieldValueInlineStyles = xcss({
	flex: 1,
	font: token('font.body'),
	color: 'color.text',
});

const fieldLabelInlineStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
});

const projectNameStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
});

const projectMetadataStyles = xcss({
	color: 'color.text.subtlest',
});

const overdueTextStyles = xcss({
	color: 'color.text.accent.red',
});

const projectAvatarStyle = xcss({
	height: token('space.300'),
	width: token('space.300'),
	borderRadius: 'border.radius.100',
});

const overdueDaysStyles = xcss({
	color: 'color.text.accent.red',
});
