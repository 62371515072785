import React, { Fragment, useMemo, useCallback, useEffect, useRef } from 'react';
import urlParse from 'url-parse';
import { useConnectItemClickHistory } from '@atlassian/jira-connect-item-click-history-controller/src/index.tsx';
import { matchUrlExact } from '@atlassian/jira-navigation-apps-sidebar-common/src/utils/url-matchers/index.tsx';
import type { ConnectDataItem } from '@atlassian/jira-navigation-apps-sidebar-connect/src/common/types.tsx';
import { CUSTOM_ITEM } from '@atlassian/jira-navigation-apps-sidebar-nav4-analytics/src/common/constants/analytics/component-type.tsx';
import ExpandableMenuItemTrigger from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/expandable-menu-item-trigger-with-analytics/index.tsx';
import MenuLinkItem from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/common/ui/menu-link-item-with-analytics/index.tsx';
import { ForgeIcon } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-forge/src/common/ui/forge-item/forge-icon/index.tsx';
import {
	Divider,
	ExpandableMenuItem,
	ExpandableMenuItemContent,
} from '@atlassian/navigation-system';
import { useLocation } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-location/index.tsx';
import { useQuery } from '@atlassian/jira-navigation-apps-sidebar-nav4-sidebars-common-core/src/utils/use-query/index.tsx';

export type ConnectItemProps = {
	item: ConnectDataItem;
	analytics: {
		actionSubjectId: string;
		level: number;
		[attribute: string]: boolean | number | string | undefined;
	};
	showIcons?: boolean;
	// needed for recursion, to have nested items send level+1, level+2 etc values.
	level?: number;
	appId?: string;
};

export const ConnectItem = ({
	item,
	showIcons,
	analytics,
	level = analytics.level,
	appId = item.id,
}: ConnectItemProps) => {
	const { addNew } = useConnectItemClickHistory();

	const icon = useMemo(() => {
		if (showIcons !== true || item.section) return undefined;
		return <ForgeIcon url={item.iconUrl} />;
	}, [showIcons, item.iconUrl, item.section]);

	const location = useLocation();
	const query = useQuery();

	const getIsCurrentLocationMatchItemUrl = useCallback(
		(itemUrl?: string) => {
			if (!itemUrl) return false;

			// match pathname and query params
			// code adapted from src/packages/navigation-apps/sidebar/common/src/common/ui/menu-item/main.tsx
			const currentLocation = { ...location, query };
			const urlMatcher = matchUrlExact();
			const itemLocation = urlParse(itemUrl || '/', true);
			// @ts-expect-error - Property 'search' is missing in type 'URLParse' but required in type 'MatcherLocation'.
			return urlMatcher(currentLocation, itemLocation);
		},
		[location, query],
	);

	const isItemSelected = useMemo(
		() => getIsCurrentLocationMatchItemUrl(item.url),
		[item.url, getIsCurrentLocationMatchItemUrl],
	);

	const itemAnalytics = useMemo(() => {
		const initialLevel = analytics.level;
		const currentLevel = level;
		const isNestedItem = currentLevel - initialLevel > 0;

		return {
			...analytics,
			componentType: CUSTOM_ITEM,
			level,
			itemType: isNestedItem ? 'appSubpage' : 'app',
			itemId: item.id,
			parentItemType: isNestedItem ? 'app' : undefined,
			parentItemId: isNestedItem ? appId : undefined,
		};
	}, [analytics, appId, level, item.id]);

	const getIsChildSelected = useCallback(
		(parentItem: ConnectDataItem): boolean =>
			parentItem.items?.some(
				(child) => getIsCurrentLocationMatchItemUrl(child.url) || getIsChildSelected(child),
			) ?? false,
		[getIsCurrentLocationMatchItemUrl],
	);

	const isChildSelected = useMemo(() => getIsChildSelected(item), [item, getIsChildSelected]);

	const onItemClick = useCallback(() => {
		item.url != null && addNew(item.url);
	}, [addNew, item.url]);

	const anchorElement = useRef<HTMLAnchorElement>(null);
	useEffect(() => {
		// inject style class names used by Connect app js to attach event listener to display modal dialog
		if (item.styleClass) {
			const classes = item.styleClass
				.split(' ')
				.map((className) => className.trim())
				.filter(Boolean)
				.filter((className) => anchorElement.current?.classList?.contains(className) === false);
			anchorElement.current?.classList?.add(...classes);
		}
	}, [item.styleClass]);

	// nested menu items (for 1 App, or 1 menu item of an App)
	if (item.items && item.items.length > 0) {
		const childSections = item.items.filter((child) => child.section);
		const isAllSectionsUnnamed =
			childSections.length > 0 && childSections.every((section) => !section.name);

		const menuContent = item.items.map((child, index) => (
			<Fragment key={child.id}>
				{isAllSectionsUnnamed && child.section && index > 0 ? <Divider /> : null}
				<ConnectItem
					item={child}
					showIcons={showIcons}
					level={level ? level + 1 : undefined}
					appId={appId}
					analytics={analytics}
				/>
			</Fragment>
		));

		return item.name ? (
			<ExpandableMenuItem
				isDefaultExpanded={item.section || isChildSelected}
				isChildSelected={isChildSelected}
			>
				<ExpandableMenuItemTrigger elemBefore={icon} analytics={itemAnalytics}>
					{item.name}
				</ExpandableMenuItemTrigger>
				<ExpandableMenuItemContent>{menuContent}</ExpandableMenuItemContent>
			</ExpandableMenuItem>
		) : (
			<>{menuContent}</>
		);
	}

	// if no nested menu items
	return (
		<MenuLinkItem
			analytics={itemAnalytics}
			elemBefore={icon}
			href={item.url ?? ''}
			isSelected={isItemSelected}
			ref={anchorElement}
			onClick={onItemClick}
		>
			{item.name}
		</MenuLinkItem>
	);
};
