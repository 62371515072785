import React, { useCallback, useEffect, useState } from 'react';
import { styled } from '@compiled/react';
import debounce from 'lodash/debounce';
import SelectClearIcon from '@atlaskit/icon/core/migration/cross-circle--select-clear';
import EditorSearchIcon from '@atlaskit/icon/core/migration/search--editor-search';
import TextField from '@atlaskit/textfield';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { useIntl } from '@atlassian/jira-intl';
import { useAnalyticsEvents, fireTrackAnalytics } from '@atlassian/jira-product-analytics-bridge';
import type { QueueId } from '@atlassian/jira-servicedesk-queues-common/src/model/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import messages from './messages.tsx';

export type Props = {
	onChange: (text: string | null) => void;
	onChangedIsPendingSubmission: (loading: boolean) => void;
	onFocusChange: (isFocused: boolean) => void;
	queueId: QueueId;
	filterQuery?: string | null;
};

const TextFieldWidth = 240;

const createJQLClause = (text: string) => `summary ~ "${text}*" OR description ~ "${text}*"`;

const IssueSearchView = ({
	onChange,
	onChangedIsPendingSubmission,
	onFocusChange,
	queueId,
	filterQuery,
}: Props) => {
	const { formatMessage } = useIntl();
	const { placeholder, placeholderIssueTermRefresh } = messages;

	const [value, setValue] = useState<string>('');
	const [isIconHovered, setHovered] = useState<boolean>(false);
	const { createAnalyticsEvent } = useAnalyticsEvents();

	useEffect(() => {
		setValue('');
	}, [queueId]);

	useEffect(() => {
		if (filterQuery === null) {
			setValue('');
		}
	}, [filterQuery]);

	useEffect(
		() => () => {
			onFocusChange(false);
		},
		[onFocusChange],
	);

	// go/jfe-eslint
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const debouncedSearch = useCallback(
		debounce((query) => {
			const filterJQL = query === '' ? null : createJQLClause(query);
			onChange(filterJQL);
			onChangedIsPendingSubmission(true);
		}, 2000),
		[onChange],
	);

	// @ts-expect-error - TS7006 - Parameter 'event' implicitly has an 'any' type.
	const handleSearchChange = (event) => {
		setValue(event.target.value);
		debouncedSearch(event.target.value);
	};

	const onFocusHandle = () => {
		fireTrackAnalytics(createAnalyticsEvent({}), 'jsmQueueFiltering clicked');
		onFocusChange(true);
	};

	const onBlurHandle = () => onFocusChange(false);

	const onMouseEnterButton = useCallback(() => setHovered(true), []);
	const onMouseLeaveButton = useCallback(() => setHovered(false), []);

	const clearSearch = () => {
		setValue('');
		onChange(null);
		onChangedIsPendingSubmission(true);
	};

	return (
		<MinWidthWrapper>
			<TextField
				isCompact
				width={TextFieldWidth}
				placeholder={
					fg('itsm-issue-renaming')
						? formatMessage(placeholderIssueTermRefresh)
						: formatMessage(placeholder)
				}
				elemBeforeInput={<EditorSearchIcon spacing="spacious" label="" />}
				onChange={handleSearchChange}
				value={value}
				onFocus={onFocusHandle}
				onBlur={onBlurHandle}
				elemAfterInput={
					value && (
						<Tooltip content={formatMessage(messages.clearSearch)}>
							<ClearSearchWrapper
								onClick={clearSearch}
								onMouseEnter={onMouseEnterButton}
								onMouseLeave={onMouseLeaveButton}
								data-testid="servicedesk-queues-agent-view.layout.header.async-issue-search.issue-search.clear-search"
							>
								<SelectClearIcon
									color={isIconHovered ? token('color.icon.subtle') : token('color.icon.disabled')}
									label={formatMessage(messages.clearSearch)}
									spacing="none"
								/>
							</ClearSearchWrapper>
						</Tooltip>
					)
				}
			/>
		</MinWidthWrapper>
	);
};

export default IssueSearchView;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ClearSearchWrapper = styled.div({
	marginRight: token('space.100'),
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
});

/*
 * these styles are used for accomodating smaller screen sizes with the introduction of the split filters
 *
 * the min-width is needed to prevent the text area from shrinking to 0 size
 * the flex-shrink is used to give width priority to the active issue types / portal groups filters
 * the text-overflow is used when the text input is shrunken
 */
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const MinWidthWrapper = styled.div({
	marginTop: token('space.negative.100'),
	width: `${TextFieldWidth}px`,
	minWidth: '100px',
	flexShrink: 1000,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	input: {
		textOverflow: 'ellipsis',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const SkeletonWrapper = styled.div({
	marginTop: token('space.negative.100'),
});
