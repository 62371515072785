import React, { useRef, useMemo } from 'react';
import { styled } from '@compiled/react';
import type { PreloadedQuery } from 'react-relay';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import { token } from '@atlaskit/tokens';
import { JSErrorBoundary } from '@atlassian/jira-error-boundaries/src/ui/js-error-boundary/JSErrorBoundary.tsx';
import type { ui_jiraCalendarQuery } from '@atlassian/jira-relay/src/__generated__/ui_jiraCalendarQuery.graphql';
import { Breadcrumbs } from '@atlassian/jira-spa-apps-software-breadcrumbs/src/ui/index.tsx';
import UFOSegment from '@atlassian/jira-ufo-segment/src/index.tsx';
import { APP_ID } from './common/constants.tsx';
import { useCalendarCapabilities } from './common/controllers/capabilities-provider/index.tsx';
import { CalendarStoreContainer } from './controllers/calendar-store/index.tsx';
import type {
	CalendarIssueEventFields,
	CalendarScope,
	CalendarViewRange,
	CalendarWeekStart,
	CalendarDayStart,
	ConsumerCustomApi,
	CalendarColorBy,
} from './controllers/calendar-store/types.tsx';
import { ConnectionStoreContainer } from './controllers/connection-store/index.tsx';
import { useCalendarUFOExperienceContext } from './controllers/ufo-context-provider/index.tsx';
import { Skeleton } from './ui/calendar-skeleton/index.tsx';
import { CalendarContent } from './ui/index.tsx';

/**
 * Props for the CalendarPage component.
 */
export interface CalendarPageProps extends ConsumerCustomApi {
	/**
	 * Define calendar view data fetching scope.
	 */
	scope: CalendarScope;

	/**
	 * The initial date to show in the calendar.
	 */
	selectedDate?: Date;

	/**
	 * The view mode of the calendar. Default view mode is month
	 *
	 * Note: the calendar only support MONTH view. Other views need to be implemented
	 */
	viewRange?: CalendarViewRange;

	/**
	 * Whether to hide done items on the calendar. Default is false
	 */
	hideDoneItems?: boolean;

	/**
	 * Whether to show weekends on the calendar. Default is false
	 */
	showWeekends?: boolean;

	/**
	 * Whether to show versions on the calendar. Default is false
	 */
	showVersions?: boolean;

	/**
	 * Whether to show sprints on the calendar. Default is false
	 */
	showSprints?: boolean;

	/**
	 * Whether to show issues on the calendar. Default is true
	 */
	showIssues?: boolean;

	/**
	 * Whether to show issue key on issues on the calendar. Default is false
	 */
	showIssueKey?: boolean;
	/**
	 * Whether to use 24 hour clock in calendar weekly view. Default is false
	 */
	showIn24HourClock?: boolean;

	/**
	 * The day of the week on which the calendar starts. Default view mode is sunday
	 */
	weekStartsOn?: CalendarWeekStart;
	/**
	 * Color setting of item cards
	 */
	colorBy?: CalendarColorBy;

	/**
	 * The time of the day at which the calendar starts. Default view time is 09:00 AM
	 */
	dayStartsAt?: CalendarDayStart;

	/**
	 * The fields used to render/update issue events displayed on the calendar.
	 */
	issueEventFields?: CalendarIssueEventFields;

	/**
	 * The reference to the preloaded calendar query.
	 */
	calendarQueryRef?: PreloadedQuery<ui_jiraCalendarQuery>;

	refetchApiRef?: React.MutableRefObject<{
		onRefetch: () => void;
		refetchVersionsWithoutSuspense?: (projects?: string[]) => void;
	} | null>;
}

/**
 * This is a platform implementation of calendar view.
 *
 * This page is implemented using
 *
 * * JiraIssue GraphQL types
 * * AGG & Relay
 */
export function CalendarPage({
	scope,
	selectedDate,
	viewRange,
	hideDoneItems = false,
	showWeekends = false,
	showSprints = false,
	showIssues = true,
	showVersions = false,
	showIssueKey = true,
	colorBy,
	showIn24HourClock,
	weekStartsOn,
	dayStartsAt,
	issueEventFields,
	calendarQueryRef,
	refetchApiRef,
	onScheduled,
	onFiltered,
	onSettingsChanged,
}: CalendarPageProps) {
	const refetchApi = useRef({
		// eslint-disable-next-line @typescript-eslint/no-empty-function
		onRefetch: () => {},
	});

	const { hasHeader, hasBreadcrumbs } = useCalendarCapabilities();

	const currentDate = useMemo(() => selectedDate ?? new Date(), [selectedDate]);
	const { packageName, teamName } = useCalendarUFOExperienceContext();

	if (!calendarQueryRef) {
		return <Skeleton />;
	}

	const extraQuery = calendarQueryRef?.variables?.issuesSearchInput?.additionalFilterQuery ?? '';

	return (
		<UFOSegment name="jira-calendar-content">
			<JSErrorBoundary id={APP_ID} packageName={packageName} teamName={teamName} fallback="page">
				<Placeholder name="CalendarPage" fallback={<Skeleton />}>
					<CalendarStoreContainer
						calendarScope={scope}
						currentDate={currentDate}
						viewRange={viewRange}
						hideDoneItems={hideDoneItems}
						showWeekends={showWeekends}
						showSprints={showSprints}
						showVersions={showVersions}
						showIssues={showIssues}
						showIssueKey={showIssueKey}
						weekStartsOn={weekStartsOn}
						dayStartsAt={dayStartsAt}
						showIn24HourClock={showIn24HourClock}
						issueEventFields={issueEventFields}
						colorBy={colorBy}
						refetchApiRef={refetchApiRef || refetchApi}
						onScheduled={onScheduled}
						onFiltered={onFiltered}
						extraQuery={extraQuery}
						onSettingsChanged={onSettingsChanged}
					>
						<ConnectionStoreContainer>
							<CalendarPageContainer hasHeader={hasHeader}>
								{hasBreadcrumbs && <Breadcrumbs />}
								<CalendarContent calendarQueryRef={calendarQueryRef} />
							</CalendarPageContainer>
						</ConnectionStoreContainer>
					</CalendarStoreContainer>
				</Placeholder>
			</JSErrorBoundary>
		</UFOSegment>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CalendarPageContainer = styled.div<{ hasHeader: boolean }>({
	display: 'flex',
	// This is required because we need the container to have an intrinsic height.
	// It can be replaced with a resize observer over the top-level element.
	height: '100%',
	boxSizing: 'border-box',
	// Remove spacing when only the content is needed
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	padding: ({ hasHeader }) =>
		hasHeader ? `${token('space.0')} ${token('space.500')}` : `${token('space.0')}`,
});
