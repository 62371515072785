import React, { useState, useCallback, useMemo } from 'react';
import { SpotlightManager, SpotlightTarget, SpotlightTransition } from '@atlaskit/onboarding';
import { Box } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import CoordinationClient from '@atlassian/jira-engagement/src/ui/coordination-client/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import PopoutPanelNav4 from '@atlassian/jira-servicedesk-popout-panel-nav4/src/ui/index.tsx';
import {
	getCategoryStateKey,
	usePoppedOutState,
} from '@atlassian/jira-servicedesk-queues-nav4/src/common/controllers/queues-category-state/index.tsx';
import { PracticeQueues } from '@atlassian/jira-servicedesk-queues-nav4/src/common/ui/practice-queues/main.tsx';
import { toItsmPractice } from '@atlassian/jira-servicedesk-work-category/src/main.tsx';
import { JiraSpotlight } from '@atlassian/jira-spotlight/src/ui/jira-spotlight.tsx';
import { usePathParam } from '@atlassian/react-resource-router';
import messages from './messages.tsx';

const SPOTLIGHT_TARGET_ID = 'jsm-nav4-queues-popout';
const SPOTLIGHT_MESSAGE_ID = 'jsm-nav4-queues-popout-spotlight';

type QueuesPopoutSpotlightProps = {
	stop?: VoidFunction; // this is a property passed from CoordinationClient
	onDismiss?: VoidFunction;
};

const QueuesPopoutSpotlight = ({ stop, onDismiss }: QueuesPopoutSpotlightProps) => {
	const { formatMessage } = useIntl();
	const onClick = useCallback(() => {
		onDismiss?.();
		stop?.();
	}, [onDismiss, stop]);

	const actions = useMemo(
		() => [
			{
				onClick,
				text: formatMessage(messages.spotlightDismissButtonLabel),
			},
		],
		[onClick, formatMessage],
	);

	return (
		<SpotlightTransition>
			<JiraSpotlight
				actions={actions}
				dialogPlacement="right top"
				heading={formatMessage(messages.spotlightHeading)}
				key={SPOTLIGHT_TARGET_ID}
				target={SPOTLIGHT_TARGET_ID}
				targetRadius={3}
				targetBgColor={token('elevation.surface')}
				messageId={SPOTLIGHT_MESSAGE_ID}
				messageType="engagement"
			>
				{formatMessage(messages.spotlightMessage)}
			</JiraSpotlight>
		</SpotlightTransition>
	);
};

interface QueuesPopoutPanelNav4Props {
	children: React.ReactNode;
}

const QueuesPopoutPanelNav4 = ({ children }: QueuesPopoutPanelNav4Props) => {
	const [projectKeyFromPath] = usePathParam('projectKey');
	const [practiceFromPath] = usePathParam('practiceType');
	const categoryFromPath = toItsmPractice(practiceFromPath);
	const [isSpotlightDismissed, setIsSpotlightDismissed] = useState(false);

	const categoryStateKey = getCategoryStateKey(projectKeyFromPath || '', categoryFromPath);
	const [isPoppedOut] = usePoppedOutState(categoryStateKey);

	const onSpotlightDismiss = useCallback(() => {
		setIsSpotlightDismissed(true);
	}, [setIsSpotlightDismissed]);

	const practiceQueues = (
		<SpotlightManager>
			<SpotlightTarget name={SPOTLIGHT_TARGET_ID}>
				<Box>
					<PracticeQueues
						key={categoryStateKey}
						projectKey={projectKeyFromPath || ''}
						practice={categoryFromPath}
					/>
				</Box>
			</SpotlightTarget>
			{isPoppedOut && !isSpotlightDismissed && (
				<CoordinationClient messageId={SPOTLIGHT_MESSAGE_ID}>
					<QueuesPopoutSpotlight onDismiss={onSpotlightDismiss} />
				</CoordinationClient>
			)}
		</SpotlightManager>
	);

	return (
		<PopoutPanelNav4 isPoppedOut={isPoppedOut} popoutComponent={practiceQueues}>
			{children}
		</PopoutPanelNav4>
	);
};

export default QueuesPopoutPanelNav4;
