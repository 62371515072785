import { useCallback, useEffect, useState } from 'react';
import fireErrorAnalytics from '@atlassian/jira-errors-handling/src/utils/fire-error-analytics.tsx';
import { useService } from '@atlassian/jira-common-services/src/use-service/index.tsx';
import { getUserProperty, dismissSectionMessageRequest } from '../services/index.tsx';
import { PACKAGE_NAME, TEAM_NAME } from '../common/constants.tsx';

const handleErrorLogging = (
	operation: string,
	error: Error,
	attributes: Record<string, string | number>,
) => {
	fireErrorAnalytics({
		meta: {
			id: operation,
			packageName: PACKAGE_NAME,
			teamName: TEAM_NAME,
		},
		error,
		sendToPrivacyUnsafeSplunk: true,
		attributes,
	});
};

export const useSectionDismiss = (accountId: string | null, messageKey: string) => {
	const [showSectionMessage, setShowSectionMessage] = useState(false);
	const [isLoading, setIsLoading] = useState(!!accountId);

	const getSectionMessageVisibilityState = useCallback(async () => {
		try {
			const response = await getUserProperty(accountId, messageKey);
			setShowSectionMessage(response.value);
			// eslint-disable-next-line @typescript-eslint/no-explicit-any
		} catch (error: any) {
			setShowSectionMessage(error.statusCode === 404);
			error.statusCode !== 404 &&
				handleErrorLogging('getSectionMessageState', error, {
					messageKey,
				});
		} finally {
			setIsLoading(false);
		}
	}, [messageKey, accountId]);

	const { error: dismissSectionMessageError, fetch } = useService(
		dismissSectionMessageRequest(accountId, messageKey),
	);

	const dismissSectionMessage = useCallback(() => {
		setShowSectionMessage(false);
		accountId && fetch();
	}, [fetch, accountId]);

	useEffect(() => {
		if (accountId) {
			getSectionMessageVisibilityState();
		}
	}, [getSectionMessageVisibilityState, accountId]);

	useEffect(() => {
		if (dismissSectionMessageError) {
			handleErrorLogging('dismissSectionMessage', dismissSectionMessageError, {
				messageKey,
			});
		}
	}, [dismissSectionMessageError, messageKey]);

	return { isLoading, showSectionMessage, dismissSectionMessage };
};
