// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import { N700 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const eventWrapperPaddingTop = 2;
export const eventWrapperPaddingLeft = 4;
export const eventWrapperText = {
	fontSize: {
		default: 14,
		min: 11,
	},
	lineHeight: {
		default: 20,
		min: 1,
	},
};

// eslint-disable-next-line @atlaskit/design-system/ensure-design-token-usage/preview, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const ContentWrapper = styled.div<{
	shouldDisplayInline: boolean;
	shouldShrinkTitle: boolean;
	textColor?: string;
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
}>((props) => ({
	overflow: 'hidden',
	height: '100%',
	display: 'flex',
	alignItems: 'center',
	textAlign: 'left',
	paddingLeft: `${eventWrapperPaddingLeft}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	flexDirection: props.shouldDisplayInline ? 'row' : 'column',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	paddingTop: `${props.shouldShrinkTitle ? 0 : eventWrapperPaddingTop}px`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	fontSize: `${
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		props.shouldShrinkTitle ? eventWrapperText.fontSize.min : eventWrapperText.fontSize.default
	}px`,
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	lineHeight: `${
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		props.shouldShrinkTitle ? eventWrapperText.lineHeight.min : eventWrapperText.lineHeight.default
	}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	color: props.textColor || token('color.text', N700),
}));

ContentWrapper.displayName = 'ContentWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TitleWrapper = styled.span<{
	shouldDisplayInline: boolean;
	shouldWrapText: boolean;
	maxHeight: number;
	order: number;
	view: string;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values, @atlaskit/design-system/use-tokens-typography -- Ignored via go/DSP-18766
		fontWeight: props.view === 'list' ? 400 : 600,
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		order: props.order,
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => !props.shouldDisplayInline && 'overflow: hidden; width: 100%;',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => props.shouldWrapText && 'white-space: nowrap;',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => props.maxHeight && `max-height: ${props.maxHeight}px`,
);

TitleWrapper.displayName = 'TitleWrapper';

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const TimeWrapper = styled.span<{
	shouldDisplayInline: boolean;
	order: number;
}>(
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => ({
		fontWeight: token('font.weight.regular'),
		whiteSpace: 'nowrap',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		order: props.order,
	}),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => props.order === 1 && 'margin-right:4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	(props) => !props.shouldDisplayInline && 'width: 100%;',
);
TimeWrapper.displayName = 'TimeWrapper';
