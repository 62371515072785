import React, { Component, createRef } from 'react';
import { styled, keyframes } from '@compiled/react';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { type IntlShape, injectIntl } from '@atlassian/jira-intl/src/index.tsx';
import type { VirtualBoundaries } from '../../../../model/index.tsx';
import type {
	LoadingRowProps,
	LoadingRowUnmountCallback,
	LoadingRowRenderReasonType,
	OnFocusCallback,
} from '../../../../model/rows/index.tsx';
import type { CompiledTheme } from '../../../../model/themes/index.tsx';
import { RowHighlight } from '../../../../view/table/common/styled.tsx';
import { withTheme } from '../../../context/theme-context/index.tsx';
import messages from './messages.tsx';

const WIDE_SKELETON_WIDTH_PX = 616;
const SHORT_SKELETON_WIDTH_PX = 408;
const MIN_TABLE_WIDTH_FOR_FULL_SKELETONS = WIDE_SKELETON_WIDTH_PX + 200;
const SKELETON_HEIGHT = 20;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Wrapper = styled.div<{ rowHeight: number; noRightMargin: boolean }>({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ rowHeight }) => `${rowHeight}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	marginRight: ({ noRightMargin }) => {
		if (noRightMargin) {
			return token('space.0', '0');
		}
		return token('space.100');
	},
	position: 'relative',
	display: 'flex',
	alignItems: 'center',
	outline: 'none',
});

const shimmer = keyframes({
	'0%': {
		backgroundPosition: '-300px 0',
	},
	'100%': {
		backgroundPosition: '1000px 0',
	},
});

// Replace with once Loader component ready.
// https://hello.atlassian.net/wiki/spaces/ADG/pages/195122968/Skeleton+product+1.0+spec
// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const Skeleton = styled.div<{ height: string | number; width: string | number }>({
	animationDuration: '1.2s',
	animationFillMode: 'forwards',
	animationIterationCount: 'infinite',
	animationName: shimmer,
	animationTimingFunction: 'linear',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundColor: token('color.skeleton.subtle', colors.N30),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	backgroundImage: `linear-gradient(to right, ${token('color.skeleton.subtle', colors.N30)} 10%, ${token('color.skeleton', colors.N40)} 20%, ${token('color.skeleton.subtle', colors.N30)} 30%)`,
	backgroundRepeat: 'no-repeat',
	flex: '1 1 auto',
	borderRadius: '4px',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	height: ({ height }) => `${height}px`,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	maxWidth: ({ width }) => `${width}px`,
});

type Props = LoadingRowProps & {
	tableWidth: number;
	rowHeight: number;
	reasonType: LoadingRowRenderReasonType;
	tableHasRightShadow: boolean;
	virtualBoundaries: VirtualBoundaries;
	numberOfLoadingRows: number;
	onUnmount: LoadingRowUnmountCallback;
	onFocusCallback: OnFocusCallback;
	isActiveItem: boolean;
	isRowLeftEdgeVisible: boolean;
	isRowRightEdgeVisible: boolean;
	intl: IntlShape;
	theme: CompiledTheme;
};

// eslint-disable-next-line jira/react/no-class-components
class LoadingRow extends Component<Props> {
	componentDidMount() {
		const { id, rowHeight, reasonType, onRowContentUpdate } = this.props;
		onRowContentUpdate && onRowContentUpdate(id, rowHeight);
		this.timestampMounted = Date.now();
		this.initialReasonType = reasonType;
		if (this.props.isActiveItem && this.myRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.focus();
		}
		this.myRef.current &&
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.addEventListener('focusin', this.props.onFocusCallback);
	}

	componentDidUpdate(prevProps: Props) {
		if (!prevProps.isActiveItem && this.props.isActiveItem && this.myRef.current) {
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.focus();
		}
	}

	componentWillUnmount() {
		const {
			id,
			onUnmount,
			numberOfLoadingRows,
			virtualBoundaries: { displayStart },
		} = this.props;
		const timeVisibleFor = Date.now() - this.timestampMounted;
		onUnmount &&
			onUnmount(
				numberOfLoadingRows,
				displayStart,
				id,
				timeVisibleFor,
				// @ts-expect-error Argument of type 'string' is not assignable to parameter of type 'UIAnalyticsEvent'.
				this.initialReasonType,
			);
		this.myRef.current &&
			// @ts-expect-error - TS2571 - Object is of type 'unknown'.
			this.myRef.current.removeEventListener('focusin', this.props.onFocusCallback);
	}

	baseSkeletonWidth() {
		const { rowIndex } = this.props;
		return rowIndex % 2 ? SHORT_SKELETON_WIDTH_PX : WIDE_SKELETON_WIDTH_PX;
	}

	skeletonWidth() {
		const { tableWidth } = this.props;
		const baseWidth = this.baseSkeletonWidth();
		if (tableWidth < MIN_TABLE_WIDTH_FOR_FULL_SKELETONS) {
			const scalingFactor = tableWidth / MIN_TABLE_WIDTH_FOR_FULL_SKELETONS;
			return Math.ceil(baseWidth * scalingFactor);
		}
		return baseWidth;
	}

	myRef = createRef();

	// @ts-expect-error - TS2564 - Property 'timestampMounted' has no initializer and is not definitely assigned in the constructor.
	timestampMounted: number;

	// @ts-expect-error - TS2564 - Property 'initialReasonType' has no initializer and is not definitely assigned in the constructor.
	initialReasonType: LoadingRowRenderReasonType;

	render() {
		const {
			rowHeight,
			tableHasRightShadow,
			isActiveItem,
			isRowLeftEdgeVisible,
			isRowRightEdgeVisible,
			onClick,
			intl: { formatMessage },
			theme,
		} = this.props;
		return (
			<Wrapper
				// @ts-expect-error - TS2322 - The expected type comes from property onClick which is declared here on type IntrinsicAttributes & { rowHeight: number; noRightMargin: boolean; } & ClassAttributes<HTMLDivElement> & HTMLAttributes<HTMLDivElement> & StyledProps
				onClick={onClick}
				rowHeight={rowHeight}
				noRightMargin={tableHasRightShadow}
				isActiveItem={isActiveItem}
				// @ts-expect-error - TS2322 - Type unknown is not assignable to type HTMLDivElement
				ref={this.myRef}
				tabIndex={0}
				aria-label={formatMessage(messages.loadingRow)}
				role="button"
			>
				<Skeleton width={this.skeletonWidth()} height={SKELETON_HEIGHT} />
				{isActiveItem && (
					<RowHighlight
						theme={theme}
						isRowLeftEdgeVisible={isRowLeftEdgeVisible}
						isRowRightEdgeVisible={isRowRightEdgeVisible}
					/>
				)}
			</Wrapper>
		);
	}
}

export default injectIntl(withTheme(LoadingRow));
