import React, { useCallback } from 'react';

import type { DayHeaderContentArg, FormatDateOptions } from '@fullcalendar/core';

import Badge from '@atlaskit/badge';
import { fg } from '@atlaskit/platform-feature-flags';
import { N500, N800 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

import type { CalendarViewRange, TodayWrapperType } from '../../../common/types';
import { toMoment } from '../../../common/utils';

import { ConditionalTodayWrapper } from './conditional-today-wrapper';
import { DayContainer, DayNumber, DayText } from './styled';

export type DayCellContentProps = Pick<DayHeaderContentArg, 'date' | 'isPast' | 'isToday'>;

export const useDayCellContent = (
	viewRange: CalendarViewRange,
	timezone: string,
	formatDate: (date: Date, options: FormatDateOptions) => string,
	wrapper?: TodayWrapperType,
) =>
	useCallback(
		function DayHeader({ date, isPast, isToday }: DayCellContentProps) {
			if (viewRange !== 'month') {
				return <></>;
			}
			const dateMoment = toMoment(timezone, date);

			if (fg('platform-component-visual-refresh')) {
				return (
					<DayContainer>
						<ConditionalTodayWrapper isToday={isToday} Wrapper={wrapper}>
							<Badge
								appearance="primary"
								style={{
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									...(!isToday && {
										backgroundColor: 'transparent',
										color: token('color.text', N800),
									}),
									// eslint-disable-next-line @atlaskit/ui-styling-standard/enforce-style-prop
									...(isPast && { color: token('color.text.subtle', N500) }),
								}}
							>
								{dateMoment.date() === 1 && `${formatDate(date, { month: 'short' })} `}
								{formatDate(date, { day: 'numeric' })}
							</Badge>
						</ConditionalTodayWrapper>
					</DayContainer>
				);
			}

			return (
				<DayContainer>
					{dateMoment.date() === 1 && (
						<DayText isPast={isPast} isToday={isToday}>
							{formatDate(date, { month: 'short' })}
						</DayText>
					)}
					<ConditionalTodayWrapper isToday={isToday} Wrapper={wrapper}>
						<DayNumber isPast={isPast} isToday={isToday}>
							{formatDate(date, { day: 'numeric' })}
						</DayNumber>
					</ConditionalTodayWrapper>
				</DayContainer>
			);
		},
		[viewRange, timezone, formatDate, wrapper],
	);
