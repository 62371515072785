import { Observable } from 'rxjs/Observable';
import 'rxjs/add/observable/concat';
import 'rxjs/add/observable/of';

import logs from '@atlassian/jira-common-util-logging/src/log.tsx';

import { captureException } from '@atlassian/jira-capture-exception/src/index.tsx';
import { error as createErrorAction, type ErrorAction } from './actions.tsx';
import { UNKNOWN, type GenericErrorDescription } from './types.tsx';

const LOCATION = 'common.components.virtual-table.ops.common.errors';

export type GenericAction = {
	type: string;
	// eslint-disable-next-line @typescript-eslint/no-explicit-any
	payload?: any;
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type GenericActionCreator = (arg1?: any) => GenericAction;

const translateJsError = (jsError: Error): Observable<ErrorAction> =>
	Observable.of(
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
		createErrorAction({
			type: UNKNOWN,
			message: jsError.message,
			stack: jsError.stack,
		} as GenericErrorDescription),
	);

const generateErrorAction = (thrownError: Error): Observable<ErrorAction> =>
	translateJsError(thrownError);

export const errorHandlerFactory =
	(additionalFailAction?: GenericActionCreator) => (thrownError: Error) => {
		captureException(LOCATION, thrownError);
		logs.safeErrorWithoutCustomerData(
			LOCATION,
			thrownError.message || 'Operations error',
			thrownError,
		);

		const errorAction = generateErrorAction(thrownError);
		if (additionalFailAction) {
			return Observable.concat(errorAction, Observable.of(additionalFailAction()));
		}
		return errorAction;
	};
