import React, { useRef } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment } from 'react-relay';
import { Box, Inline, xcss } from '@atlaskit/primitives';
import Placeholder from '@atlaskit/react-ufo/placeholder';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';
import {
	ActionsWrapperStyled,
	LeftActionsWrapper,
	RightActionsWrapper,
} from '@atlassian/jira-business-app-wrapper/src/ui/actions-wrapper/index.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useViewMode } from '@atlassian/jira-issue-context-service/src/main.tsx';
import { getWillShowNav4 } from '@atlassian/jira-navigation-apps-sidebar-nav4-rollout-core/src/common/utils/get-will-show-nav4/index.tsx';
import type { calendarTopControlsBar_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarTopControlsBar_calendar.graphql';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { useQueryParam } from '@atlassian/react-resource-router';
import { SELECTED_ISSUE } from '../../common/constants.tsx';
import {
	useCalendarCapabilities,
	PLATFORM,
} from '../../common/controllers/capabilities-provider/index.tsx';
import { CalendarViewOnboarding } from '../../common/ui/onboarding/index.tsx';
import { useBreakpointObserver } from '../../common/ui/responsive/index.tsx';
import {
	useCalendarActions,
	useCalendarIsLoading,
	useIsUnscheduledPanelOpen,
	useIsViewSettingsOpen,
} from '../../controllers/calendar-store/index.tsx';
import { BusinessFiltersLeft, BusinessFiltersRight } from './business-filters/index.tsx';
import { CalendarUnscheduledPanelToggle } from './calendar-unscheduled-panel-toggle/index.tsx';
import { ConnectedDateControls } from './date-controls/index.tsx';
import { CalendarFilters } from './filters/index.tsx';
import { MoreMenu } from './more-menu/index.tsx';
import { CalendarShareButton } from './share-button/index.tsx';
import { ViewRangeControls } from './view-range-controls/index.tsx';
import { CalendarViewSettings } from './view-settings/index.tsx';

export interface CalendarTopControlsBarProps {
	queryRef: calendarTopControlsBar_calendar$key;
	jqlBuilderQuery?: string;
}

export function CalendarTopControlsBar({ queryRef, jqlBuilderQuery }: CalendarTopControlsBarProps) {
	const data = useFragment(
		graphql`
			fragment calendarTopControlsBar_calendar on Query
			@argumentDefinitions(
				cloudId: { type: "ID!" }
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
				unscheduledIssuesSearchInput: { type: "JiraCalendarIssuesInput" }
				skipPanelIssueSearch: { type: "Boolean!" }
				versionsSearchInput: { type: "JiraCalendarVersionsInput" }
				sprintsSearchInput: { type: "JiraCalendarSprintsInput" }
				skipSprintSearch: { type: "Boolean!" }
				issuesSearchInput: { type: "JiraCalendarIssuesInput!" }
				issuesPageSize: { type: "Int" }
				issuesPageCursor: { type: "String" }
				skipVersionsV1Search: { type: "Boolean!" }
				skipVersionsV2Search: { type: "Boolean!" }
			) {
				jira {
					...filters_calendar_CalendarFilters
						@arguments(
							cloudId: $cloudId
							scopeInput: $scopeInput
							configurationInput: $configurationInput
						)
					...moreMenu_calendar
						@arguments(scopeInput: $scopeInput, configurationInput: $configurationInput)
				}

				...viewSettings_calendar_CalendarViewSettings
					@arguments(
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						versionsSearchInput: $versionsSearchInput
						skipVersionsV1Search: $skipVersionsV1Search
						skipVersionsV2Search: $skipVersionsV2Search
						sprintsSearchInput: $sprintsSearchInput
						skipSprintSearch: $skipSprintSearch
						issuesSearchInput: $issuesSearchInput
						issuesPageSize: $issuesPageSize
						issuesPageCursor: $issuesPageCursor
					)

				...calendarUnscheduledPanelToggle_calendar
					@skip(if: $skipPanelIssueSearch)
					@arguments(
						scopeInput: $scopeInput
						configurationInput: $configurationInput
						unscheduledIssuesSearchInput: $unscheduledIssuesSearchInput
					)
			}
		`,
		queryRef,
	);

	const { toggleUnscheduledPanelVisibility, toggleViewSettingsPanelVisibility } =
		useCalendarActions();

	const {
		hasFilterBar,
		hasUnscheduledPanel,
		hasHeader,
		hasBusinessFilter,
		hasShareButton,
		issue: { modalType },
		weekViewEnabled,
	} = useCalendarCapabilities();
	const isLoading = useCalendarIsLoading();
	const containerRef = useRef<HTMLDivElement>(null);
	const breakpoint = useBreakpointObserver({ ref: containerRef });
	const isUnscheduledPanelOpen = useIsUnscheduledPanelOpen();
	const isViewSettingsOpen = useIsViewSettingsOpen();

	const [selectedIssueKey] = useQueryParam(SELECTED_ISSUE);
	const issueViewMode = useViewMode();

	const showIssueDetails = modalType === PLATFORM;
	const isSidebarOpen =
		isUnscheduledPanelOpen || (showIssueDetails && issueViewMode === 'SIDEBAR' && selectedIssueKey);

	return fg('jira-calendar-business-theme') ? (
		<Box ref={containerRef} xcss={hasHeader && toolbarWrapperStyles}>
			<ActionsWrapperStyled
				sidebarOpen={!!isSidebarOpen}
				isVisualRefreshEnabledFG={isVisualRefreshEnabled()}
				hasBottomSpacing={
					isVisualRefreshEnabled() && fg('visual-refresh_drop_3') ? hasBusinessFilter : undefined
				}
			>
				{hasFilterBar && (
					<LeftActionsWrapper>
						{hasBusinessFilter && <BusinessFiltersLeft />}
						{hasBusinessFilter && isVisualRefreshEnabled() && <BusinessFiltersRight />}

						{!hasBusinessFilter && (
							<CalendarFilters jiraRef={data.jira ?? null} jqlBuilderQuery={jqlBuilderQuery} />
						)}
					</LeftActionsWrapper>
				)}

				<RightActionsWrapper>
					{isLoading && <Spinner size="medium" />}
					<ConnectedDateControls />
					{weekViewEnabled && <ViewRangeControls />}
					{fg('software_calendar_quickstart_onboarding') && (
						<CalendarViewOnboarding
							onToggleUnscheduledPanelVisibility={toggleUnscheduledPanelVisibility}
							onToggleViewSettingsPanelVisibility={toggleViewSettingsPanelVisibility}
							isUnscheduledPanelOpen={isUnscheduledPanelOpen}
							isViewSettingsPanelOpen={isViewSettingsOpen}
						/>
					)}
					{!isVisualRefreshEnabled() && !getWillShowNav4() && hasShareButton && !hasHeader && (
						<CalendarShareButton />
					)}
					{hasUnscheduledPanel && <CalendarUnscheduledPanelToggle queryRef={data} />}
					{hasBusinessFilter && !isVisualRefreshEnabled() && <BusinessFiltersRight />}
					<CalendarViewSettings breakpoint={breakpoint} queryRef={data} />
					{!hasHeader && data.jira && <MoreMenu jiraRef={data.jira} />}
				</RightActionsWrapper>
			</ActionsWrapperStyled>
		</Box>
	) : (
		<Inline ref={containerRef}>
			{hasFilterBar && (
				<Inline grow="fill">
					<Placeholder fallback={null} name="JiraCalendarAppFilters">
						{hasBusinessFilter && <BusinessFiltersLeft />}
						{!hasBusinessFilter && (
							<CalendarFilters jiraRef={data.jira ?? null} jqlBuilderQuery={jqlBuilderQuery} />
						)}
					</Placeholder>
				</Inline>
			)}

			<RightHandContainer>
				{isLoading && <Spinner size="medium" />}
				<ConnectedDateControls />
				{weekViewEnabled && <ViewRangeControls />}
				{fg('software_calendar_quickstart_onboarding') && (
					<CalendarViewOnboarding
						onToggleUnscheduledPanelVisibility={toggleUnscheduledPanelVisibility}
						onToggleViewSettingsPanelVisibility={toggleViewSettingsPanelVisibility}
						isUnscheduledPanelOpen={isUnscheduledPanelOpen}
						isViewSettingsPanelOpen={isViewSettingsOpen}
					/>
				)}
				{!isVisualRefreshEnabled() && !getWillShowNav4() && hasShareButton && !hasHeader && (
					<CalendarShareButton />
				)}
				{hasUnscheduledPanel && <CalendarUnscheduledPanelToggle queryRef={data} />}
				{hasBusinessFilter && <BusinessFiltersRight />}
				<CalendarViewSettings breakpoint={breakpoint} queryRef={data} />
				{!hasHeader && data.jira && <MoreMenu jiraRef={data.jira} />}
			</RightHandContainer>
		</Inline>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const RightHandContainer = styled.div({
	flexGrow: 1,
	flexShrink: 0,
	flexBasis: 'auto',
	display: 'flex',
	justifyContent: 'end',
	alignItems: 'center',
	gap: token('space.100'),
});

const toolbarWrapperStyles = xcss({
	marginTop: 'space.negative.150',
});
