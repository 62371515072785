import type { ForgeState } from '@atlassian/jira-issue-view-common-types/src/forge-types.tsx';
import {
	type Action as ForgeAction,
	FETCH_FORGE_SUCCESS,
	FORGE_ISSUE_ACTION_CLOSE,
	FORGE_ISSUE_ACTION_EXEC,
	FORGE_ISSUE_ACTION_RENDERED,
	FORGE_ISSUE_PANEL_ADDED,
	FORGE_NEW_ISSUE_PANEL_UNTRACK,
	FORGE_ISSUE_PANEL_REMOVED,
	FORGE_ISSUE_PANEL_UPDATED,
} from '../../actions/forge-actions.tsx';
import {
	FETCH_ISSUE_REMOTE_DATA_SUCCESS,
	type FetchIssueRemoteDataSuccess,
} from '../../actions/issue-remote-data-actions.tsx';
import {
	FETCH_ISSUE_SUCCESS,
	REFRESH_ISSUE_SUCCESS,
	type FetchIssueSuccessAction,
	type RefreshIssueSuccessAction,
} from '../../common/actions/issue-fetch-actions.tsx';

type Action =
	| ForgeAction
	| FetchIssueSuccessAction
	| RefreshIssueSuccessAction
	| FetchIssueRemoteDataSuccess;

export const initialState: ForgeState = {
	actions: [],
	glances: [],
	issueContexts: [],
	panels: [],
	activities: [],
	customFields: [],
	customFieldTypes: [],
	backgroundScripts: [],
	activeAction: null,
	panelsKeys: {
		newPanels: [],
		collapsedPanels: [],
	},
	uiModifications: [],
};

// eslint-disable-next-line jira/import/no-anonymous-default-export
export default (state: ForgeState = initialState, action: Action): ForgeState => {
	switch (action.type) {
		case FETCH_FORGE_SUCCESS:
			return {
				...state,
				actions: action.payload.issueAction,
				glances: action.payload.issueGlance,
				issueContexts: action.payload.issueContext,
				panels: action.payload.issuePanel,
				activities: action.payload.issueActivity,
				customFields: action.payload.customField,
				customFieldTypes: action.payload.customFieldType,
				backgroundScripts: action.payload.issueViewBackgroundScript,
				panelsKeys: {
					...state.panelsKeys,
					newPanels: [],
				},
				uiModifications: action.payload.uiModifications,
			};
		case FORGE_ISSUE_ACTION_EXEC:
			return {
				...state,
				activeAction: {
					action: action.payload,
					isLoading: true,
				},
			};
		case FORGE_ISSUE_ACTION_RENDERED:
			return {
				...state,
				// @ts-expect-error - TS2322 - Type '{ isLoading: false; action?: Action | undefined; }' is not assignable to type 'ActiveAction'.
				activeAction: {
					...state.activeAction,
					isLoading: false,
				},
			};
		case FORGE_ISSUE_ACTION_CLOSE:
			return {
				...state,
				activeAction: null,
			};
		case FORGE_ISSUE_PANEL_ADDED: {
			return {
				...state,
				panelsKeys: {
					...state.panelsKeys,
					newPanels: [...state.panelsKeys.newPanels, action.payload],
				},
			};
		}
		case FORGE_NEW_ISSUE_PANEL_UNTRACK: {
			const removedPanelKey = action.payload;
			const { panelsKeys } = state;

			return {
				...state,
				panelsKeys: {
					...panelsKeys,
					newPanels: panelsKeys.newPanels.filter((panelKey) => panelKey !== removedPanelKey),
				},
			};
		}
		case FORGE_ISSUE_PANEL_REMOVED: {
			const removedPanelKey = action.payload;

			return {
				...state,
				panelsKeys: {
					newPanels: state.panelsKeys.newPanels.filter((panelKey) => panelKey !== removedPanelKey),
					collapsedPanels: state.panelsKeys.collapsedPanels.filter(
						(panelKey) => panelKey !== removedPanelKey,
					),
				},
			};
		}
		case FORGE_ISSUE_PANEL_UPDATED: {
			const { panelKey, collapsed } = action.payload;

			const updatedCollapsedPanels = collapsed
				? Array.from(new Set([...state.panelsKeys.collapsedPanels, panelKey]))
				: state.panelsKeys.collapsedPanels.filter((key) => key !== panelKey);

			return {
				...state,
				panelsKeys: {
					...state.panelsKeys,
					collapsedPanels: updatedCollapsedPanels,
				},
			};
		}
		case FETCH_ISSUE_SUCCESS:
		case REFRESH_ISSUE_SUCCESS:
		case FETCH_ISSUE_REMOTE_DATA_SUCCESS: {
			const collapsedPanels = action.payload.forgeIssueProperties?.collapsedPanels || [];

			return {
				...state,
				panelsKeys: {
					...state.panelsKeys,
					collapsedPanels: Array.from(
						new Set([...state.panelsKeys.collapsedPanels, ...collapsedPanels]),
					),
				},
			};
		}
		default:
			return state;
	}
};
