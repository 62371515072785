/** @jsx jsx */
import React, { type ReactNode } from 'react';
import { css, jsx } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';

const baseStyle = css({
	display: 'flex',
	justifyContent: 'center',
	alignItems: 'center',
	opacity: 0.8,

	backgroundColor: token('elevation.surface'),
	position: 'absolute',
	zIndex: 6,
});

export const Blanket = ({
	scrollOffset,
	width,
	children,
}: {
	scrollOffset: number;
	width: number;
	children?: ReactNode;
}) => (
	<div
		css={baseStyle}
		style={{
			top: `${scrollOffset}px`,
			bottom: `${-scrollOffset}px`,
			width: fg('css_support_for_virtual_table') ? `${width}px` : `${width}px !important`,
		}}
	>
		{children}
	</div>
);
