import React, { type ReactNode } from 'react';
import { styled } from '@compiled/react';
import Button from '@atlaskit/button';
import CrossIcon from '@atlaskit/icon/core/migration/close--cross';
import { Box, Inline } from '@atlaskit/primitives';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import type { JQLModel } from '../../../common/utils/jql-model/index.tsx';
import { messages } from './messages.tsx';

export interface SectionHeaderProps {
	/**
	 * JQL model used to check if the clear button should be rendered and render the count of
	 * selected values for this field
	 */
	jqlModel: JQLModel;
	/**
	 * The jqlTerm of the field that this section is for
	 */
	fieldName: string;
	/**
	 * Called when the cross icon / clear button is clicked.
	 */
	onClickClearFilter: (fieldName: string) => void;
	children: ReactNode;
}

/**
 * The heading for a filter popup section.
 */
export function SectionHeader({
	children,
	jqlModel,
	fieldName,
	onClickClearFilter,
}: SectionHeaderProps) {
	const count = jqlModel.whereClauses[fieldName]?.length ?? 0;
	const { formatMessage } = useIntl();

	const countContent = (
		<Box testId="software-filters-popup.ui.filter-section.section-header.count">({count})</Box>
	);

	const countEl = count ? countContent : null;

	const clearButton = count ? (
		<Button
			onClick={() => onClickClearFilter(fieldName)}
			testId="software-filters-popup.ui.filter-section.section-header.clear-button"
			appearance="subtle"
			spacing="none"
		>
			<Inline alignBlock="center" alignInline="center">
				<CrossIcon label={formatMessage(messages.clear, { fieldName })} LEGACY_size="small" />
			</Inline>
		</Button>
	) : null;

	return (
		<Inline alignBlock="center">
			<SectionHeaderHeading>
				<Inline space="space.050" alignBlock="center">
					<Box>{children}</Box>
					{countEl}
				</Inline>
			</SectionHeaderHeading>
			{clearButton}
		</Inline>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SectionHeaderHeading = styled.h3({
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: token('color.text.subtle', colors.N200),
	// eslint-disable-next-line @atlaskit/design-system/use-tokens-typography
	textTransform: 'uppercase',
	font: token('font.heading.xxsmall', 'normal 600 0.75rem/1rem sans-serif'),
});
