import { N40, N70, G400, B400, N0, N200 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

export const STATUS_THEME_DEFAULT = 'default';
export const STATUS_THEME_SUCCESS = 'success';
export const STATUS_THEME_IN_PROGRESS = 'inprogress';

export const statusCategoryToThemeMap: { [key: string]: string } = {
	'1': STATUS_THEME_DEFAULT,
	'2': STATUS_THEME_DEFAULT,
	'3': STATUS_THEME_SUCCESS,
	'4': STATUS_THEME_IN_PROGRESS,
};

export const bgColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.background.neutral', N40),
	[STATUS_THEME_SUCCESS]: token('color.background.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.background.information'),
};

export const textColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.text.subtle'),
	[STATUS_THEME_SUCCESS]: token('color.text.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.text.information'),
};

export const textColorOld: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.text'),
	[STATUS_THEME_SUCCESS]: token('color.text.success'),
	[STATUS_THEME_IN_PROGRESS]: token('color.text.information'),
};

export const bgHoverColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.background.neutral.hovered', N70),
	[STATUS_THEME_SUCCESS]: token('color.background.success.hovered', G400),
	[STATUS_THEME_IN_PROGRESS]: token('color.background.information.hovered', B400),
};

export const textHoverColor: { [key: string]: string } = {
	[STATUS_THEME_DEFAULT]: token('color.text'),
	[STATUS_THEME_SUCCESS]: token('color.text.success', N0),
	[STATUS_THEME_IN_PROGRESS]: token('color.text.information', N0),
};

export const disabledColor: { [key: string]: string } = {
	bg: token('color.background.neutral', N40),
	text: token('color.text.disabled', N200),
};
