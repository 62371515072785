import React from 'react';
import { styled } from '@compiled/react';
import Spinner from '@atlaskit/spinner';
import { token } from '@atlaskit/tokens';

export const MenuFallback = () => (
	<SpinnerWrapper>
		<Spinner size="medium" testId="business-filters.common.ui.menu-fallback.spinner" />
	</SpinnerWrapper>
);

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const SpinnerWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
	justifyContent: 'center',
	paddingTop: token('space.200'),
	paddingRight: token('space.200'),
	paddingBottom: token('space.200'),
	paddingLeft: token('space.200'),
});
