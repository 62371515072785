import React from 'react';
import { useFragment, graphql } from 'react-relay';
import { Inline, xcss } from '@atlaskit/primitives';
import type { calendarCardSummary_calendar$key } from '@atlassian/jira-relay/src/__generated__/calendarCardSummary_calendar.graphql';

interface CalendarCardSummaryProps {
	issueRef: calendarCardSummary_calendar$key;
}

export function CalendarCardSummary({ issueRef }: CalendarCardSummaryProps) {
	const data = useFragment(
		graphql`
			fragment calendarCardSummary_calendar on JiraIssue {
				summaryField {
					text
				}
			}
		`,
		issueRef,
	);

	const summary = data?.summaryField?.text;

	return summary && <Inline xcss={overflowStyles}>{summary}</Inline>;
}

const overflowStyles = xcss({
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	flex: 1,
});
