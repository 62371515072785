import { defineMessages } from '@atlassian/jira-intl';

export const messages = defineMessages({
	issueCountErrorMessageHeader: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.issue-count-error-message-header',
		defaultMessage: "We can't show the number of issues",
		description: 'Header message to alert user the issue count cannot be displayed',
	},
	issueCountErrorMessageAdmin: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.issue-count-error-message-admin',
		defaultMessage:
			"There seems to be a problem with the queue's filters. Edit the queue and try again.",
		description: 'Message to alert the admin that the issue count could not be displayed',
	},
	issueCountErrorMessageAgentStarred: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.issue-count-error-message-agent-starred',
		defaultMessage:
			"There seems to be a problem with the queue's filters. Contact your admins to edit this queue or remove it from Starred.",
		description: 'Message to alert the agent that the starred issue count could not be displayed',
	},
	issueCountErrorMessageAgentNotStarred: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.issue-count-error-message-agent-not-starred',
		defaultMessage:
			"There seems to be a problem with the queue's filters. Contact your admins to edit this queue.",
		description:
			'Message to alert the agent that the non starred issue count could not be displayed',
	},
	learnMoreButtonLabel: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.learn-more-button-label',
		defaultMessage: 'Learn more',
		description: 'Learn more button label',
	},
	reloadButtonLabel: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.reload-button-label',
		defaultMessage: 'Reload',
		description: 'Reload button label',
	},
	editQueueButtonLabel: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.edit-queue-button-label',
		defaultMessage: 'Edit queue',
		description: 'Edit queue button label',
	},
	issueCountErrorMessageHeaderIssueTermRefresh: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.issue-count-error-message.issue-count-error-message-header-issue-term-refresh',
		defaultMessage: "We can't show the number of work items",
		description: 'Header message to alert user the issue count cannot be displayed',
	},
});
