// eslint-disable-next-line @atlaskit/ui-styling-standard/use-compiled -- Ignored via go/DSP-18766
import styled from 'styled-components';

import { N0, N200, N500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';

const todayColor = token('color.text.subtle', N500);
const todayNumberColor = token('color.text.inverse', N0);
const pastTextColor = token('color.text.subtlest', N200);
const textColor = token('color.text.subtle', N200);
const DAY_NUMBER_SIZE = 22;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles, @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
export const DayContainer = styled.div({
	font: token('font.heading.xxsmall'),
	display: 'flex',
	marginTop: 0,
});
DayContainer.displayName = 'DayContainer';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DayText = styled.div<{
	isPast: boolean;
	isToday: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => {
		if (props.isToday) {
			return todayColor;
		}
		if (props.isPast) {
			return pastTextColor;
		}
		return textColor;
	}};
	height: ${DAY_NUMBER_SIZE}px;

	&::after {
		content: '\\00a0';
	}
`;
DayText.displayName = 'DayText';

// eslint-disable-next-line @atlaskit/design-system/no-styled-tagged-template-expression, @atlaskit/ui-styling-standard/no-styled, @atlaskit/ui-styling-standard/no-exported-styles -- Ignored via go/DSP-18766
export const DayNumber = styled.div<{
	isPast: boolean;
	isToday: boolean;
}>`
	display: flex;
	align-items: center;
	justify-content: center;
	color: ${(props) => {
		if (props.isToday) {
			return todayNumberColor;
		}
		if (props.isPast) {
			return pastTextColor;
		}
		return textColor;
	}};
	background-color: ${(props) => (props.isToday ? todayColor : 'transparent')};
	border-radius: ${(props) => (props.isToday ? `${DAY_NUMBER_SIZE / 2}px` : '0')};
	width: ${(props) => (props.isToday ? `${DAY_NUMBER_SIZE}px` : 'auto')};
	height: ${DAY_NUMBER_SIZE}px;
`;
DayNumber.displayName = 'DayNumber';
