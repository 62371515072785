import React, { useCallback, useMemo, type SyntheticEvent } from 'react';
import { styled } from '@compiled/react';
import { differenceInDays } from 'date-fns';
import { type EntryPointProps, graphql, useFragment, usePreloadedQuery } from 'react-relay';
import { Text, Inline, Box, Stack, xcss } from '@atlaskit/primitives';
import CloseIcon from '@atlaskit/icon/core/close';
import ReleaseIcon from '@atlaskit/icon/core/release';
import CrossIconOld from '@atlaskit/icon/core/migration/close--cross';
import ShipIconOld from '@atlaskit/icon/core/migration/release--ship';
import Lozenge, { type LozengeProps } from '@atlaskit/lozenge';

import { N400, N500, R500 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import Skeleton from '@atlaskit/skeleton';
import Link from '@atlassian/jira-common-analytics-v2-wrapped-components/src/link.tsx';
import JiraProgressBar from '@atlassian/jira-common-components-progress-bar/src/main.tsx';
import ProgressElement from '@atlassian/jira-common-components-progress-bar/src/progress-element/index.tsx';
import ErrorBoundary from '@atlassian/jira-error-boundary/src/ErrorBoundary.tsx';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { SCENARIO_TYPE } from '@atlassian/jira-portfolio-3-portfolio/src/common/view/constant.tsx';
import {
	ContextualAnalyticsData,
	FireScreenAnalytics,
	fireUIAnalytics,
	type AnalyticsEvent,
} from '@atlassian/jira-product-analytics-bridge';
import type { ui_ReleaseFlyout$key } from '@atlassian/jira-relay/src/__generated__/ui_ReleaseFlyout.graphql';
import { type UFOExperience, ExperienceSuccess, useUFOComponentExperience } from '@atlassian/ufo';
import type { ui_releaseFlyoutQuery } from '@atlassian/jira-relay/src/__generated__/ui_releaseFlyoutQuery.graphql';
import { DEFAULT_FORMAT_DATE_OPTIONS } from '../common/constants.tsx';
import { ChangeIndicator } from '../common/ui/change-indicator/index.tsx';
import { toLocalDate } from '../common/utils/date/index.tsx';
import { useReleaseFlyoutUFOExperienceContext } from '../controllers/ufo-context-provider/index.tsx';
import { ActionsMenu, type ActionsMenuProps } from './actions-menu/index.tsx';
import {
	DONE_COLOR,
	IN_PROGRESS_COLOR,
	PROGRESS_BAR_BORDER_RADIUS,
	PROGRESS_BAR_HEIGHT,
	TODO_COLOR,
} from './constants.tsx';
import { createViewReleaseFlyoutExperience } from './experience/index.tsx';
import { messages } from './messages.tsx';

const StatusLabel = {
	UNRELEASED: messages.unreleasedStatusLabel,
	RELEASED: messages.releasedStatusLabel,
	OVERDUE: messages.overdueStatusLabel,
};

const LozengeAppearance: { [key: string]: LozengeProps['appearance'] } = {
	UNRELEASED: 'inprogress',
	RELEASED: 'success',
	OVERDUE: 'removed',
};

export type ReleaseFlyoutProps = {
	versionRef: ui_ReleaseFlyout$key;
	onClose: () => void;
	actionMenuItems?: ActionsMenuProps['children'];
	opensReleaseInNewWindow?: boolean;
};

type ReleaseFlyoutPropsInternal = ReleaseFlyoutProps & {
	viewReleaseFlyoutExperience: UFOExperience;
};

const handleErrorBoundaryError = (viewReleaseFlyoutExperience: UFOExperience) => {
	viewReleaseFlyoutExperience.failure();
};

export const ReleaseFlyoutInternal = ({
	actionMenuItems,
	versionRef,
	viewReleaseFlyoutExperience,
	onClose,
	opensReleaseInNewWindow = false,
}: ReleaseFlyoutPropsInternal) => {
	useUFOComponentExperience(viewReleaseFlyoutExperience);

	/* eslint-disable @atlassian/relay/graphql-naming */
	const data = useFragment(
		graphql`
			fragment ui_ReleaseFlyout on JiraVersion
			@argumentDefinitions(
				viewId: { type: "ID", defaultValue: null }
				skipVersionsV1Search: { type: "Boolean", defaultValue: true }
				skipVersionsV2Search: { type: "Boolean", defaultValue: false }
			) {
				name
				description
				startDate
				releaseDate
				status
				versionId
				project {
					name
					key
					avatar {
						xsmall
						__typename
					}
					projectTypeName
				}
				driver {
					name
					picture
				}
				doneIssues: issues(filters: { statusCategories: DONE }) {
					totalCount
				}
				inprogressIssues: issues(filters: { statusCategories: IN_PROGRESS }) {
					totalCount
				}
				todoIssues: issues(filters: { statusCategories: TODO }) {
					totalCount
				}
				planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
					name
					scenarioType
					startDate @skip(if: $skipVersionsV1Search)
					releaseDate @skip(if: $skipVersionsV1Search)
					startDateValue @skip(if: $skipVersionsV2Search) {
						date
					}
					releaseDateValue @skip(if: $skipVersionsV2Search) {
						date
					}
					description
				}
			}
		`,
		versionRef,
	);

	const doneCount = data.doneIssues?.totalCount ?? 0;
	const inprogressCount = data.inprogressIssues?.totalCount ?? 0;
	const todoCount = data.todoIssues?.totalCount ?? 0;

	const totalCount = doneCount + inprogressCount + todoCount;

	const donePercentage = totalCount > 0 ? (doneCount / totalCount) * 100 : 0;

	let startDate = data.planScenarioValues?.startDate ?? data.startDate;
	let releaseDate = data.planScenarioValues?.releaseDate ?? data.releaseDate;
	if (fg('plan-calendar-versions-v2-adoption')) {
		// Use startDateValue/releseDateValue property if it exists even though the 'date' is null, it means the scenario value has been cleared
		startDate = data.planScenarioValues?.startDateValue
			? data.planScenarioValues?.startDateValue.date
			: data.startDate;
		releaseDate = data.planScenarioValues?.releaseDateValue
			? data.planScenarioValues?.releaseDateValue.date
			: data.releaseDate;
	}

	const [isOverdue, numberDaysOverdue] = useMemo(() => {
		const overdueDays =
			data.status !== 'RELEASED' && releaseDate != null
				? Math.max(differenceInDays(new Date(), toLocalDate(releaseDate)), 0)
				: 0;
		return [overdueDays > 0, overdueDays];
	}, [data, releaseDate]);

	const { formatMessage, formatDate } = useIntl();

	const onReleaseLinkClicked = useCallback(
		(event: SyntheticEvent, analyticsEvent: AnalyticsEvent) => {
			fireUIAnalytics(analyticsEvent, 'releaseFlyout');
		},
		[],
	);

	const analyticsAttributes = useMemo(
		() => ({
			isOverdue,
			isReleased: data.status === 'RELEASED',
		}),
		[data.status, isOverdue],
	);

	const isNewlyAddedScenarioType = data.planScenarioValues?.scenarioType === SCENARIO_TYPE.ADDED;
	const versionName = data.planScenarioValues?.name ?? data.name;

	// Explicitly check against null/undefined because planScenarioValues's description could be empty string
	const description =
		data.planScenarioValues?.description !== null &&
		data.planScenarioValues?.description !== undefined
			? data.planScenarioValues?.description
			: data.description;

	return (
		<ContextualAnalyticsData
			sourceType="InlineDialog"
			sourceName="releaseFlyout"
			attributes={analyticsAttributes}
		>
			<Stack
				xcss={containerStackStyles}
				space="space.200"
				testId="release-flyout.ui.stack"
				role="group"
			>
				<Inline alignBlock="center" xcss={titleContainerInlineStyles}>
					<Inline alignBlock="center" space="space.050" xcss={titleInlineStyles}>
						{fg('enghealth-12479-jsw-board-visual-refresh') ? (
							<ReleaseIcon
								spacing="none"
								label=""
								LEGACY_size="small"
								LEGACY_fallbackIcon={ShipIconOld}
								color={token('color.icon')}
							/>
						) : (
							<ShipIconOld label="" LEGACY_size="small" />
						)}
						<Title>{formatMessage(messages.releaseLabel)}</Title>
					</Inline>
					{actionMenuItems && <ActionsMenu>{actionMenuItems}</ActionsMenu>}
					<CloseButton onClick={onClose} data-testid="release-flyout.ui.close-button">
						{fg('enghealth-12479-jsw-board-visual-refresh') ? (
							<CloseIcon
								spacing="none"
								LEGACY_size="small"
								LEGACY_fallbackIcon={CrossIconOld}
								label={formatMessage(messages.closeButtonLabel)}
								color={token('color.icon')}
							/>
						) : (
							<CrossIconOld label={formatMessage(messages.closeButtonLabel)} LEGACY_size="small" />
						)}
					</CloseButton>
				</Inline>

				{(data.status === 'RELEASED' || data.status === 'UNRELEASED') && (
					<Inline>
						<Stack alignBlock="start" space="space.050" xcss={fieldValueInlineStyles}>
							<Inline>
								<Lozenge
									testId="release-flyout.ui.status"
									appearance={LozengeAppearance[isOverdue ? 'OVERDUE' : data.status]}
								>
									{formatMessage(StatusLabel[isOverdue ? 'OVERDUE' : data.status])}
								</Lozenge>
							</Inline>
						</Stack>
						{isOverdue && (
							<Stack alignInline="end" space="space.050" xcss={fieldValueInlineStyles}>
								<OverdueDays data-testid="release-flyout.ui.overdue-warning">
									{formatMessage(messages.overdueDaysLabel, {
										numberDaysOverdue,
									})}
								</OverdueDays>
							</Stack>
						)}
					</Inline>
				)}

				{versionName && (
					<Inline xcss={nameInlineStyles}>
						{!isNewlyAddedScenarioType ? (
							<Link
								data-testid="release-flyout.ui.link"
								to={`/projects/${data.project?.key}/versions/${data.versionId}`}
								// eslint-disable-next-line jira/react/no-style-attribute, @atlaskit/ui-styling-standard/enforce-style-prop -- Ignored via go/DSP-18766
								style={{ color: token('color.text', N500) }}
								onClick={onReleaseLinkClicked}
								{...(opensReleaseInNewWindow
									? {
											target: '_blank',
											rel: 'noreferrer',
										}
									: {})}
							>
								{versionName}
							</Link>
						) : (
							versionName
						)}
						{data.planScenarioValues?.name && <ChangeIndicator />}
					</Inline>
				)}

				{description && (
					<Inline xcss={descriptionInlineStyles}>
						{description}
						{data.planScenarioValues?.description && <ChangeIndicator />}
					</Inline>
				)}

				<Stack
					testId="release-flyout.ui.progress"
					space="space.050"
					xcss={progressContainerStackStyles}
				>
					<Inline spread="space-between" xcss={fieldLabelInlineStyles}>
						<Text as="span" weight="semibold" size="UNSAFE_small" color="color.text.subtle">
							{formatMessage(messages.progressLabel)}
						</Text>
						<ProgressPercentage>
							{`${formatMessage(messages.doneProgressLabel, { percent: Math.floor(donePercentage) })}`}
						</ProgressPercentage>
					</Inline>
					{doneCount || inprogressCount || todoCount ? (
						<JiraProgressBar
							sum={totalCount}
							height={PROGRESS_BAR_HEIGHT}
							borderRadius={PROGRESS_BAR_BORDER_RADIUS}
						>
							<ProgressElement
								value={doneCount}
								color={DONE_COLOR}
								tooltipContent={formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.doneToolTipsTextIssueTermRefresh
										: messages.doneToolTipsText,
									{
										count: doneCount,
										totalCount,
									},
								)}
							/>
							<ProgressElement
								value={inprogressCount}
								color={IN_PROGRESS_COLOR}
								tooltipContent={formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.inProgressToolTipsTextIssueTermRefresh
										: messages.inProgressToolTipsText,
									{
										count: inprogressCount,
										totalCount,
									},
								)}
							/>
							<ProgressElement
								value={todoCount}
								color={TODO_COLOR}
								tooltipContent={formatMessage(
									fg('jira-issue-terminology-refresh-m3')
										? messages.toDoToolTipsTextIssueTermRefresh
										: messages.toDoToolTipsText,
									{
										count: todoCount,
										totalCount,
									},
								)}
							/>
						</JiraProgressBar>
					) : (
						<EmptyProgressBar />
					)}
				</Stack>

				{(startDate || releaseDate) && (
					<Inline space="space.100">
						{startDate && (
							<Stack
								testId="release-flyout.ui.start-date"
								alignBlock="start"
								space="space.050"
								xcss={fieldValueInlineStyles}
							>
								<Inline xcss={fieldLabelInlineStyles}>
									{formatMessage(messages.startDateLabel)}
								</Inline>
								<Box as="span" xcss={wrapperStyles}>
									{formatDate(toLocalDate(startDate), DEFAULT_FORMAT_DATE_OPTIONS)}
									{((data.planScenarioValues?.startDateValue &&
										fg('plan-calendar-versions-v2-adoption')) ||
										data.planScenarioValues?.startDate) && <ChangeIndicator />}
								</Box>
							</Stack>
						)}
						{releaseDate && (
							<Stack
								testId="release-flyout.ui.release-date"
								alignBlock="start"
								space="space.050"
								xcss={[fieldValueInlineStyles, isOverdue && overdueTextStyles]}
							>
								<Inline xcss={fieldLabelInlineStyles}>
									{formatMessage(messages.releaseDateLabel)}
								</Inline>
								<Box as="span" xcss={wrapperStyles}>
									{formatDate(toLocalDate(releaseDate), DEFAULT_FORMAT_DATE_OPTIONS)}
									{((data.planScenarioValues?.releaseDateValue &&
										fg('plan-calendar-versions-v2-adoption')) ||
										data.planScenarioValues?.releaseDate) && <ChangeIndicator />}
								</Box>
							</Stack>
						)}
					</Inline>
				)}

				{data.project?.name && data.project?.avatar?.xsmall && data.project?.projectTypeName && (
					<Stack testId="release-flyout.ui.project" space="space.100">
						<Inline xcss={fieldLabelInlineStyles}>{formatMessage(messages.projectLabel)}</Inline>
						<Inline space="space.100" alignBlock="center">
							<ProjectAvatar
								alt={`${data.project.name} project avatar`}
								src={data.project.avatar.xsmall}
							/>
							<Stack space="space.0">
								<Inline space="space.050" alignBlock="center" xcss={projectNameStyles}>
									{data.project.name}
								</Inline>
								<Inline space="space.050" alignBlock="center" xcss={projectMetadataStyles}>
									{data.project.projectTypeName}
								</Inline>
							</Stack>
						</Inline>
					</Stack>
				)}

				{data.driver?.name && data.driver?.picture && (
					<Stack testId="release-flyout.ui.driver" space="space.100">
						<Inline xcss={fieldLabelInlineStyles}>{formatMessage(messages.driverLabel)}</Inline>
						<Inline space="space.050" alignBlock="center" xcss={fieldValueInlineStyles}>
							<DriverAvatar alt={`${data.driver.name} avatar`} src={data.driver.picture} />
							{data.driver.name}
						</Inline>
					</Stack>
				)}
				<ExperienceSuccess experience={viewReleaseFlyoutExperience} />
			</Stack>
			<FireScreenAnalytics />
		</ContextualAnalyticsData>
	);
};

type ReleaseFlyoutInternalEntryPointProps = Omit<ReleaseFlyoutPropsInternal, 'versionRef'> &
	ReleaseFlyoutEntryPointProps['queries'];

const ReleaseFlyoutInternalEntryPoint = ({
	releaseFlyout,
	...rest
}: ReleaseFlyoutInternalEntryPointProps) => {
	const data = usePreloadedQuery(
		graphql`
			query ui_releaseFlyoutQuery(
				$id: ID!
				# This is used to determine the date fields specific to view
				$viewId: ID
				# Determines if jiraCalendar.versions is used. This takes into account "skipVersionSearch" and fg('plan-calendar-versions-v2-adoption')
				# FG-cleanup: plan-calendar-versions-v2-adoption -- consolidate "skipVersionsV1Search" and "skipVersionsV2Search" back to "skipVersionSearch"
				$skipVersionsV1Search: Boolean = true
				# Determines if jiraCalendar.versionsV2 is used. This takes into account "skipVersionSearch" and fg('plan-calendar-versions-v2-adoption')
				$skipVersionsV2Search: Boolean = true
			) @preloadable {
				node(id: $id) @required(action: THROW) {
					... on JiraVersion {
						...ui_ReleaseFlyout
							@arguments(
								viewId: $viewId
								skipVersionsV1Search: $skipVersionsV1Search
								skipVersionsV2Search: $skipVersionsV2Search
							)
					}
				}
			}
		`,
		releaseFlyout,
	);

	return <ReleaseFlyoutInternal {...rest} versionRef={data.node} />;
};

export const ReleaseFlyout = (props: ReleaseFlyoutProps) => {
	const { location, suffixKey } = useReleaseFlyoutUFOExperienceContext();
	const viewReleaseFlyoutExperience = useMemo(
		() => createViewReleaseFlyoutExperience(location, suffixKey),
		[location, suffixKey],
	);
	return (
		<ErrorBoundary
			id="release-flyout"
			packageName="jiraReleaseFlyout"
			teamName="a4t-tanuki"
			onError={() => handleErrorBoundaryError(viewReleaseFlyoutExperience)}
		>
			<ReleaseFlyoutInternal viewReleaseFlyoutExperience={viewReleaseFlyoutExperience} {...props} />
		</ErrorBoundary>
	);
};

export const ReleaseFlyoutSkeleton = () => {
	const { formatMessage } = useIntl();

	return (
		<Stack
			xcss={containerStackStyles}
			space="space.200"
			testId="release-flyout.ui.stack"
			role="group"
		>
			<Inline alignBlock="center" xcss={titleContainerInlineStyles}>
				<Inline alignBlock="center" space="space.050" xcss={titleInlineStyles}>
					{fg('enghealth-12479-jsw-board-visual-refresh') ? (
						<ReleaseIcon
							spacing="none"
							label=""
							LEGACY_size="small"
							LEGACY_fallbackIcon={ShipIconOld}
							color={token('color.icon')}
						/>
					) : (
						<ShipIconOld label="" LEGACY_size="small" />
					)}
					<Title>{formatMessage(messages.releaseLabel)}</Title>
				</Inline>
			</Inline>
			<Skeleton height="16px" isShimmering width="50%" />
			<Skeleton height="24px" isShimmering width="80%" />
			<Skeleton height="24px" isShimmering width="100%" />
			<Skeleton height="16px" isShimmering width="50%" />
			<Skeleton height="8px" isShimmering width="100%" />
			<Skeleton height="8px" isShimmering width="100%" />
			<Skeleton height="8px" isShimmering width="70%" />
		</Stack>
	);
};

export type ReleaseFlyoutEntryPointQueries = { releaseFlyout: ui_releaseFlyoutQuery };
export type ReleaseFlyoutEntryPointRuntimeProps = Omit<ReleaseFlyoutProps, 'versionRef'>;

export type ReleaseFlyoutEntryPointProps = EntryPointProps<
	ReleaseFlyoutEntryPointQueries,
	{},
	ReleaseFlyoutEntryPointRuntimeProps,
	{}
>;

const ReleaseFlyoutEntryPoint = ({
	props,
	queries: { releaseFlyout },
}: ReleaseFlyoutEntryPointProps) => {
	const { location, suffixKey } = useReleaseFlyoutUFOExperienceContext();
	const viewReleaseFlyoutExperience = useMemo(
		() => createViewReleaseFlyoutExperience(location, suffixKey),
		[location, suffixKey],
	);

	return (
		<ReleaseFlyoutInternalEntryPoint
			viewReleaseFlyoutExperience={viewReleaseFlyoutExperience}
			{...props}
			releaseFlyout={releaseFlyout}
		/>
	);
};

export default ReleaseFlyoutEntryPoint;

const containerStackStyles = xcss({
	padding: 'space.200',
	width: '280px',
});

const titleContainerInlineStyles = xcss({
	height: token('space.300'),
});

const titleInlineStyles = xcss({
	flex: 1,
	color: 'color.text.subtlest',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Title = styled.h6({
	margin: 0,
	color: 'inherit',
	font: token('font.heading.xxsmall'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const CloseButton = styled.button({
	border: 'none',
	background: 'none',
	cursor: 'pointer',
});

const nameInlineStyles = xcss({
	font: 'font.body.large',
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	/* stylelint-disable value-no-vendor-prefix */
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	position: 'relative',
});

const descriptionInlineStyles = xcss({
	font: token('font.body'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	/* stylelint-disable value-no-vendor-prefix */
	display: '-webkit-box',
	WebkitLineClamp: '2',
	WebkitBoxOrient: 'vertical',
	position: 'relative',
});

const progressContainerStackStyles = xcss({
	marginTop: 'space.050',
	marginBottom: 'space.100',
});

const fieldValueInlineStyles = xcss({
	flex: 1,
	font: token('font.body'),
	color: 'color.text',
});

const fieldLabelInlineStyles = xcss({
	font: token('font.heading.xxsmall'),
	color: 'color.text.subtle',
});

const projectNameStyles = xcss({
	font: token('font.body'),
	color: 'color.text',
});

const projectMetadataStyles = xcss({
	font: token('font.body.small'),
	color: 'color.text.subtlest',
});

const overdueTextStyles = xcss({
	color: 'color.text.accent.red',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectAvatar = styled.img({
	height: token('space.300'),
	width: token('space.300'),
	borderRadius: token('border.radius', '3px'),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProgressPercentage = styled.span({
	color: token('color.text', N400),
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const EmptyProgressBar = styled.div({
	display: 'flex',
	width: '100%',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
	height: PROGRESS_BAR_HEIGHT,
	backgroundColor: token('color.background.neutral'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	borderRadius: token('border.radius', `${PROGRESS_BAR_BORDER_RADIUS}px`),
	overflow: 'hidden',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const DriverAvatar = styled.img({
	height: token('space.300'),
	width: token('space.300'),
	borderRadius: '50%',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const OverdueDays = styled.span({
	font: token('font.heading.xxsmall'),
	color: token('color.text.accent.red', R500),
});

const wrapperStyles = xcss({
	position: 'relative',
	display: 'block',
});
