import React from 'react';
import EmptyState from '@atlaskit/empty-state';
import { fg } from '@atlassian/jira-feature-gating';
import SearchNoResultsImage from '@atlassian/jira-illustrations/src/ui/adg4/brand/spots/empty/components/search-no-results-old/index.tsx';
import { getAkEmptyStateRenderImageFn } from '@atlassian/jira-illustrations/src/ui/helpers/ak-empty-state/index.tsx';
import { useIntl } from '@atlassian/jira-intl';
import noSearchResultsImg from '@atlassian/jira-servicedesk-common/src/utils/assets/no-search-results.svg';
import messages from './messages.tsx';

const EmptyView = () => {
	const { formatMessage } = useIntl();

	return (
		<EmptyState
			renderImage={
				fg('jsm_queues_consolidate_jfe_assets')
					? getAkEmptyStateRenderImageFn(SearchNoResultsImage)
					: undefined
			}
			imageUrl={fg('jsm_queues_consolidate_jfe_assets') ? undefined : noSearchResultsImg}
			header={formatMessage(messages.header)}
			description={formatMessage(messages.description)}
			headingLevel={2}
		/>
	);
};

export default EmptyView;
