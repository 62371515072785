import React, { useState } from 'react';
import { styled } from '@compiled/react';
import { token } from '@atlaskit/tokens';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import MeatballMenu from './meatball-menu/async.tsx';
import NotificationFlagGroup, { type Flags } from './notification-flag-group/index.tsx';

// TODO: Show meatball only onHover and onFocus
const IssueOperations = ({
	tableInjectedProps: { issueKey },
	dataSelectorProps: { issueId, useRefetch },
}: JsonComponentProps<'meatball'>) => {
	const { onRefetch } = useRefetch();
	const [flags, setFlags] = useState<Flags>([]);

	const onAddFlag = (styleClass: string, isSuccessful: boolean) =>
		setFlags([{ id: flags.length + 1, styleClass, isSuccessful }, ...flags]);

	const onDismissFlag = () => setFlags(flags.slice(1));

	const onIssueRefetch = () => onRefetch(issueKey);

	return (
		<>
			<ButtonWrapper>
				<MeatballMenu
					issueId={issueId}
					issueKey={issueKey}
					onAddFlag={onAddFlag}
					onIssueRefetch={onIssueRefetch}
				/>
			</ButtonWrapper>
			<NotificationFlagGroup flags={flags} onDismiss={onDismissFlag} issueKey={issueKey} />
		</>
	);
};

export default IssueOperations;

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ButtonWrapper = styled.div({
	display: 'flex',
	flexWrap: 'wrap',
	paddingTop: token('space.050'),
	paddingRight: token('space.050'),
	paddingBottom: token('space.050'),
	paddingLeft: token('space.050'),
});
