import React from 'react';
import { expVal } from '@atlassian/jira-feature-experiments';
import type { JsonComponentProps } from '../../../model/fields/types.tsx';
import Tags from '../common/tags/index.tsx';

type Props = JsonComponentProps<'labels'>;

const Labels = ({ tableInjectedProps: { isLastColumn }, dataSelectorProps: { value } }: Props) => (
	<Tags
		// @ts-expect-error - TS2322 - Type 'LabelsProps' is not assignable to type 'TagData[]'.
		tags={value}
		isLastColumn={isLastColumn}
		{...(expVal('adding-color-to-labels-field-in-jira-experiment', 'isColoredLabelsEnabled', false)
			? { isColored: true }
			: {})}
	/>
);

export default Labels;
