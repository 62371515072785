/**
 * Overflow menu for Calendar app.
 * Only appears when hasHeader is false, and contains Add People, Give feedback and any additional Menu items.
 */
import React, { useState } from 'react';
import { graphql, useFragment } from 'react-relay';
import DropdownMenu, { DropdownItem, type CustomTriggerProps } from '@atlaskit/dropdown-menu';
import PersonAdd from '@atlaskit/icon/core/person-add';
import ShowMoreIcon from '@atlaskit/icon/core/show-more-horizontal';
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import { ResponsiveThemedButton } from '@atlassian/jira-project-theme-components/src/ui/responsive-themed-button/ResponsiveThemedButton.tsx';
import { ThemedButton } from '@atlassian/jira-project-theme-components/src/ui/themed-button/ThemedButton.tsx';
import type { moreMenu_calendar$key } from '@atlassian/jira-relay/src/__generated__/moreMenu_calendar.graphql';
import { ResponsiveButton } from '@atlassian/jira-responsive-button/src/ui/index.tsx';
import { isVisualRefreshEnabled } from '@atlassian/jira-visual-refresh-rollout/src/feature-switch/index.tsx';
import { AddPeopleModal } from '../../../common/add-people-button/index.tsx';
import { useCalendarCapabilities } from '../../../common/controllers/capabilities-provider/index.tsx';
import { FeedbackButton } from '../../../common/feedback-collector-button/index.tsx';
import { useCalendarComponents } from '../../../controllers/calendar-components-provider/index.tsx';
import { useCalendarScope } from '../../../controllers/calendar-store/index.tsx';
import { messages } from './messages.tsx';

type MoreMenuProps = {
	jiraRef: moreMenu_calendar$key;
};

export const MoreMenu = ({ jiraRef }: MoreMenuProps) => {
	const { additionalMenuItems, renderFeedbackButton } = useCalendarComponents();
	const { hasAddPeopleButton } = useCalendarCapabilities();
	const [isAddPeopleOpen, setIsAddPeopleOpen] = useState(false);
	const scope = useCalendarScope();
	const { formatMessage } = useIntl();
	const data = useFragment<moreMenu_calendar$key>(
		graphql`
			fragment moreMenu_calendar on JiraQuery
			@argumentDefinitions(
				scopeInput: { type: "JiraViewScopeInput!" }
				configurationInput: { type: "JiraCalendarViewConfigurationInput!" }
			) {
				jiraCalendar(scope: $scopeInput, configuration: $configurationInput)
					@optIn(to: "JiraCalendar") {
					projects(first: 30) {
						edges {
							node {
								key
								projectId
								name
							}
						}
					}
				}
			}
		`,
		jiraRef,
	);
	const { projectId, key, name } = data.jiraCalendar?.projects?.edges?.[0]?.node ?? {};
	const showAddPeopleButton = hasAddPeopleButton && projectId && key && name;

	const trigger = ({ triggerRef, isSelected, ...props }: CustomTriggerProps) => {
		const iconColor = isSelected
			? token('color.icon.selected', colors.B400)
			: token('color.icon', colors.N300);

		if (isVisualRefreshEnabled()) {
			return (
				<ThemedButton
					{...props}
					aria-label={formatMessage(messages.label)}
					label={formatMessage(messages.label)}
					iconBefore={<ShowMoreIcon label="" color="currentColor" spacing="spacious" />}
					ref={triggerRef}
					isSelected={isSelected}
					title={formatMessage(messages.label)}
				/>
			);
		}

		return fg('jira-calendar-business-theme') ? (
			<ResponsiveThemedButton
				{...props}
				aria-label={formatMessage(messages.btnLabel)}
				label={formatMessage(messages.btnLabel)}
				icon={<ShowMoreIcon label="" color={iconColor} spacing="spacious" />}
				ref={triggerRef}
				isSelected={isSelected}
			/>
		) : (
			<ResponsiveButton
				{...props}
				label={formatMessage(messages.btnLabel)}
				icon={ShowMoreIcon}
				ref={triggerRef}
				isSelected={isSelected}
			/>
		);
	};

	const openPeopleModal = () => {
		setIsAddPeopleOpen(true);
	};

	const closePeopleModal = () => {
		setIsAddPeopleOpen(false);
	};

	if (
		!showAddPeopleButton &&
		(!additionalMenuItems || additionalMenuItems.length === 0) &&
		!renderFeedbackButton
	) {
		// Do not render the dropdown if there are no items to show
		return null;
	}

	return (
		<>
			<DropdownMenu placement="bottom-end" trigger={trigger}>
				{showAddPeopleButton && (
					<DropdownItem
						onClick={openPeopleModal}
						elemBefore={<PersonAdd label="" color={token('color.icon')} />}
					>
						{formatMessage(messages.addPeople)}
					</DropdownItem>
				)}
				<FeedbackButton renderFeedbackButton={renderFeedbackButton} scope={scope} />
				{additionalMenuItems &&
					additionalMenuItems.length > 0 &&
					additionalMenuItems.map((item) => (
						<DropdownItem href={item.href} key={item.title} UNSAFE_shouldDisableRouterLink>
							{item.title}
						</DropdownItem>
					))}
			</DropdownMenu>
			{showAddPeopleButton && (
				<AddPeopleModal
					isOpen={isAddPeopleOpen}
					onClose={closePeopleModal}
					projectId={projectId}
					jiraProjectKey={key}
					name={name}
				/>
			)}
		</>
	);
};
