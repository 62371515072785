import { defineMessages } from '@atlassian/jira-intl';

export const tooltipMessages = defineMessages({
	loadingIssueCountTooltip: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.loading-issue-count-tooltip',
		defaultMessage: 'Loading the number of issues',
		description: 'Tooltip message to inform user the issue count is being fetched',
	},
	showLastRefreshedTimeTooltip: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.show-last-refreshed-time-tooltip',
		defaultMessage: 'Last updated {lastUpdate}',
		description: 'Tooltip Message to inform user the last issue count refreshed time',
	},
	loadingIssueCountTooltipIssueTermRefresh: {
		id: 'servicedesk-queues-nav4.common.practice-queues.queue-list.queue-item.loading-issue-count-tooltip-issue-term-refresh',
		defaultMessage: 'Loading the number of work items',
		description: 'Tooltip message to inform user the issue count is being fetched',
	},
});
