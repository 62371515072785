import React, { useCallback } from 'react';

import type { DayHeaderContentArg, FormatDateOptions } from '@fullcalendar/core';
import format from 'date-fns/format';

import { cssMap, cx } from '@atlaskit/css';
import Heading from '@atlaskit/heading';
import { fg } from '@atlaskit/platform-feature-flags';
import { Box, Flex, Text } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';

import type { CalendarView, CalendarViewRange } from '../../../common/types';

import { DayText, GridDayContainer, GridDayNumber } from './styled';

const dayNumberStyles = cssMap({
	root: {
		height: '40px',
		width: '40px',
		borderRadius: token('border.radius.circle'),
		display: 'flex',
		alignItems: 'center',
		justifyContent: 'center',
	},
});

export type DayHeaderContentProps = Pick<
	DayHeaderContentArg,
	'text' | 'date' | 'isPast' | 'isToday'
>;

export const useDayHeaderContent = (
	view: CalendarView,
	viewRange: CalendarViewRange,
	formatDate: (date: Date, options: FormatDateOptions) => string,
) =>
	useCallback(
		function DayHeader({ text, date, isPast, isToday }: DayHeaderContentProps) {
			if (view === 'list') {
				return <>{format(date, 'EEEE, do MMMM yyyy')}</>;
			}
			if (viewRange === 'month') {
				return fg('use-primitives-in-jsm-calendar-for-a11y') ? (
					<Text>{text}</Text>
				) : (
					<DayText>{text}</DayText>
				);
			}

			return fg('use-primitives-in-jsm-calendar-for-a11y') ? (
				<Flex direction="column" alignItems="center">
					<Text {...(isPast && { color: 'color.text.subtle' })}>
						{formatDate(date, { weekday: 'short' })}
					</Text>
					<Box
						xcss={cx(dayNumberStyles.root)}
						{...(isToday && { backgroundColor: 'color.background.neutral.bold' })}
					>
						<Heading size="small">{formatDate(date, { day: 'numeric' })}</Heading>
					</Box>
				</Flex>
			) : (
				<GridDayContainer isPast={isPast}>
					<DayText>{formatDate(date, { weekday: 'short' })}</DayText>
					<GridDayNumber isToday={isToday}>{formatDate(date, { day: 'numeric' })}</GridDayNumber>
				</GridDayContainer>
			);
		},
		[view, viewRange, formatDate],
	);
