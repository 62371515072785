import React, { useRef, useEffect, useState, useCallback, type MouseEvent, useMemo } from 'react';
import { styled } from '@compiled/react';
import { graphql, useFragment, useQueryLoader } from 'react-relay';
import ShipIcon from '@atlaskit/icon/core/migration/release--ship';
import { draggable } from '@atlaskit/pragmatic-drag-and-drop/element/adapter';
import { Pressable, Box, Inline, xcss, type BorderRadius } from '@atlaskit/primitives';
import { fontFallback } from '@atlaskit/theme/typography';
import { token } from '@atlaskit/tokens';
import Tooltip from '@atlaskit/tooltip';
import { JiraVersionAri } from '@atlassian/ari/jira/version';
import { fg } from '@atlassian/jira-feature-gating';
import { mergeRefs } from '@atlassian/jira-merge-refs/src/index.tsx';
import { JiraPopup } from '@atlassian/jira-popup/src/ui/jira-popup.tsx';
import { useAnalyticsEvents, fireUIAnalytics } from '@atlassian/jira-product-analytics-bridge';
import editReleaseModalQuery, {
	type editReleaseModalQuery as editReleaseModalQueryType,
} from '@atlassian/jira-relay/src/__generated__/editReleaseModalQuery.graphql';
import type { versionRenderer_calendar_VersionEventRenderer$key } from '@atlassian/jira-relay/src/__generated__/versionRenderer_calendar_VersionEventRenderer.graphql';
import {
	ReleaseFlyout,
	ReleaseFlyoutSkeleton,
} from '@atlassian/jira-release-flyout/src/ui/index.tsx';
import { EditReleaseModal } from '@atlassian/jira-software-releases-release-modals-relay/src/ui/edit-release-modal/async.tsx';
import { JiraEntryPointContainer } from '@atlassian/jira-entry-point-container/src/index.tsx';
import {
	useEntryPoint,
	type UseEntryPoint,
} from '@atlassian/jira-entry-point/src/controllers/use-entry-point/index.tsx';
import { useEntryPointButtonTrigger } from '@atlassian/jira-entry-point-button-trigger/src/index.tsx';
import releaseFlyoutEntryPoint from '@atlassian/jira-release-flyout/src/entrypoint.tsx';
import { expVal } from '@atlassian/jira-feature-experiments';
import {
	useCalendarCapabilities,
	NONE,
} from '../../../../common/controllers/capabilities-provider/index.tsx';
import { ChangeIndicator } from '../../../../common/ui/change-indicator/index.tsx';
import { COMPLETE, OFF_TRACK, ON_TRACK } from '../../../../common/ui/release-icon/constants.tsx';
import { ReleaseIcon } from '../../../../common/ui/release-icon/index.tsx';
import { getReleaseStatus } from '../../../../common/ui/release-icon/utils.tsx';
import { removeTimeFromDateString } from '../../../../common/utils/dates/index.tsx';
import { findDraggedDate } from '../../../../common/utils/drag-and-drop/index.tsx';
import {
	useCalendarActions,
	useColorBy,
	useDraggingEvent,
} from '../../../../controllers/calendar-store/index.tsx';
import { moreLinkPopoverBinder } from '../../../../controllers/more-link-popover-binder/index.tsx';
import { useIsVisibleInMoreLinkPopover } from '../../../../controllers/use-is-visible-in-more-link-popover/index.tsx';
import {
	isDroppableCalendarDayCell,
	makeDraggableCalendarVersion,
} from '../../../../services/draggable-card-type/index.tsx';
import { TEAM_NAME } from '../../../../common/constants.tsx';
import { useCalendarViewId } from '../../../../controllers/use-calendar-view-id/index.tsx';
import { EditReleaseItem } from './edit-release-item/index.tsx';
import { ViewReleaseItem } from './view-release-item/index.tsx';

export const ENTRYPOINT_ID = 'jira.calendar.version-event.release-flyout-entrypoint';

interface VersionEventRendererProps {
	versionRef: versionRenderer_calendar_VersionEventRenderer$key;
}

export function VersionEventRenderer({ versionRef }: VersionEventRendererProps) {
	const version = useFragment<versionRenderer_calendar_VersionEventRenderer$key>(
		graphql`
			fragment versionRenderer_calendar_VersionEventRenderer on JiraScenarioVersionLike
			@argumentDefinitions(
				isJiraCalendarReleaseFlyoutLazyLoadingEnabled: {
					type: "Boolean!"
					provider: "@atlassian/jira-relay-provider/src/is-jira-calendar-release-flyout-lazy-loading-enabled.relayprovider"
				}
				viewId: { type: "ID" }
				skipVersionsV1Search: { type: "Boolean!" }
				skipVersionsV2Search: { type: "Boolean!" }
			) {
				id
				planScenarioValues(viewId: $viewId) @optIn(to: "JiraPlansSupport") {
					name
					startDate @skip(if: $skipVersionsV1Search)
					releaseDate @skip(if: $skipVersionsV1Search)
					startDateValue @skip(if: $skipVersionsV2Search) {
						date
					}
					releaseDateValue @skip(if: $skipVersionsV2Search) {
						date
					}
					scenarioType
				}

				... on JiraVersion {
					versionId
					name
					startDate
					releaseDate
					status
					project {
						key
						name
						avatar {
							xsmall
						}
					}
					...ui_ReleaseFlyout
						@arguments(
							viewId: $viewId
							skipVersionsV1Search: $skipVersionsV1Search
							skipVersionsV2Search: $skipVersionsV2Search
						)
						@skip(if: $isJiraCalendarReleaseFlyoutLazyLoadingEnabled)
					...editReleaseItem_calendar
				}
			}
		`,
		versionRef,
	);

	const versionEventRef = useRef<HTMLDivElement>(null);

	const { setDraggingEvent } = useCalendarActions();
	const { viewId } = useCalendarViewId();
	const draggingEvent = useDraggingEvent();
	const {
		version: { modalType, canSchedule, visible: isVersionVisible },
	} = useCalendarCapabilities();
	const colorBy = useColorBy();

	const { isVisible, onDropEvent } = useIsVisibleInMoreLinkPopover(versionEventRef);
	const isDraggable = canSchedule;

	let entryPointResult: UseEntryPoint<typeof releaseFlyoutEntryPoint> | null = null;

	if (expVal('jira_calendar_release_flyout_lazy_loading', 'isEnabled', false)) {
		// eslint-disable-next-line react-hooks/rules-of-hooks
		const releaseFlyoutEntryPointParams = useMemo(
			() => ({
				id: version.id,
				viewId,
				skipVersionsV1Search: !isVersionVisible || fg('plan-calendar-versions-v2-adoption'),
				skipVersionsV2Search: !isVersionVisible || !fg('plan-calendar-versions-v2-adoption'),
			}),
			[isVersionVisible, version.id, viewId],
		);

		// eslint-disable-next-line react-hooks/rules-of-hooks
		entryPointResult = useEntryPoint(releaseFlyoutEntryPoint, releaseFlyoutEntryPointParams);
	}

	const versionName = version.planScenarioValues?.name ?? version.name;

	let startDate = version.planScenarioValues?.startDate ?? version.startDate;
	let releaseDate = version.planScenarioValues?.releaseDate ?? version.releaseDate;
	if (fg('plan-calendar-versions-v2-adoption')) {
		// Use startDateValue/releseDateValue property if it exists even though the 'date' is null, it means the scenario value has been cleared
		startDate = version.planScenarioValues?.startDateValue
			? version.planScenarioValues?.startDateValue.date
			: version.startDate;
		releaseDate = version.planScenarioValues?.releaseDateValue
			? version.planScenarioValues?.releaseDateValue.date
			: version.releaseDate;
	}

	const status =
		version.status && releaseDate ? getReleaseStatus(version.status, releaseDate) : null;

	/**
	 * The only way to identify new scenario version is through its ARI != JiraVersionAri.
	 * It will change once we refactored JiraVersion and split it into two types.
	 *
	 * https://hello.jira.atlassian.cloud/browse/PLVR-300
	 */
	const isJiraVersion = JiraVersionAri.check(version.id);
	const hasChangedIndicator =
		(version.planScenarioValues?.scenarioType !== null &&
			version.planScenarioValues?.scenarioType !== undefined) ||
		!isJiraVersion;

	useEffect(() => {
		const el = versionEventRef.current;
		if (!el || !isDraggable) {
			return;
		}

		return draggable({
			element: el,
			getInitialData: (args) => {
				const { input } = args;
				const draggedDate =
					findDraggedDate(el, input) || moreLinkPopoverBinder.getCurrentDayCellDate();

				// eslint-disable-next-line @typescript-eslint/consistent-type-assertions
				return makeDraggableCalendarVersion({
					versionId: version.id,
					versionName: versionName ?? '',
					startDate: removeTimeFromDateString(startDate),
					releaseDate: removeTimeFromDateString(releaseDate),
					draggedDate,
				}) as unknown as Record<string, unknown>;
			},
			onDragStart(_args) {
				setDraggingEvent({ type: 'version', id: version.id });
			},
			// Replace with lodash/noop
			// eslint-disable-next-line @typescript-eslint/no-empty-function
			onDrag(_args) {},
			onDrop(args) {
				setDraggingEvent(null);
				const dropTargetData = args.location.current.dropTargets[0]?.data;
				if (isDroppableCalendarDayCell(dropTargetData)) {
					onDropEvent(dropTargetData.isEventDraggedAway);
				}
			},
		});
	}, [isDraggable, version.id, versionName, startDate, releaseDate, setDraggingEvent, onDropEvent]);

	const [isOpen, setIsOpen] = useState<boolean>(false);

	const { createAnalyticsEvent } = useAnalyticsEvents();

	const onEventCardClick = useCallback(() => {
		if (modalType === NONE) {
			const { versionId, project } = version;
			if (project?.key) {
				// eslint-disable-next-line jira/jira-ssr/no-unchecked-globals-usage
				window.open(
					`/projects/${project.key}/versions/${versionId}/tab/release-report-all-issues`,
					'_blank',
				);
			}
			return;
		}

		setIsOpen(!isOpen);

		fireUIAnalytics(
			createAnalyticsEvent({
				action: 'clicked',
				actionSubject: 'versionEventCard',
			}),
			'calendar',
		);
	}, [createAnalyticsEvent, isOpen, version, modalType]);

	const [isEditReleaseModalOpen, setIsEditReleaseModalOpen] = useState<boolean>(false);
	const [editReleaseModalQueryRef, loadEditReleaseModalQuery] =
		useQueryLoader<editReleaseModalQueryType>(editReleaseModalQuery);

	const onEdit = useCallback(() => {
		if (!editReleaseModalQueryRef) {
			loadEditReleaseModalQuery({
				versionAri: version.id,
			});
		}

		setIsEditReleaseModalOpen(true);
	}, [editReleaseModalQueryRef, loadEditReleaseModalQuery, version.id]);

	const onClose = useCallback(() => setIsOpen(false), [setIsOpen]);

	const ActionMenuItems = useMemo(() => {
		const itemNodes = [];
		if (isJiraVersion) {
			itemNodes.push(
				<ViewReleaseItem
					key={version.id}
					versionAri={version.id}
					projectKey={version.project?.key ?? ''}
					onClosePopup={onClose}
				/>,
			);
		}
		if (canSchedule) {
			itemNodes.push(
				<EditReleaseItem
					{...(fg('concurrent-rendering-fix-unique-list-key')
						? { key: `edit-release-${version.id}` }
						: {})}
					versionRef={version}
					onClosePopup={onClose}
					onEditRelease={onEdit}
				/>,
			);
		}
		if (itemNodes.length > 0) {
			return <>{itemNodes}</>;
		}
		return undefined;
	}, [onClose, onEdit, version, canSchedule, isJiraVersion]);

	const runtimeProps = useMemo(
		() => ({
			actionMenuItems: ActionMenuItems,
			onClose,
		}),
		[ActionMenuItems, onClose],
	);

	const triggerRef = entryPointResult
		? // eslint-disable-next-line react-hooks/rules-of-hooks
			useEntryPointButtonTrigger(entryPointResult.entryPointActions, !isOpen)
		: null;

	if (!isVisible) return null;

	const isDragging = draggingEvent?.type === 'version' && draggingEvent?.id === version.id;

	return (
		<>
			<JiraPopup
				isOpen={isOpen}
				onClose={onClose}
				placement="bottom-start"
				content={() =>
					entryPointResult ? (
						<JiraEntryPointContainer
							entryPointReferenceSubject={entryPointResult.entryPointReferenceSubject}
							errorFallback="flag"
							fallback={<ReleaseFlyoutSkeleton />}
							id={ENTRYPOINT_ID}
							runtimeProps={runtimeProps}
							teamName={TEAM_NAME}
						/>
					) : (
						<ReleaseFlyout
							versionRef={version}
							onClose={onClose}
							actionMenuItems={ActionMenuItems}
						/>
					)
				}
				messageId="calendar.ui.calendar-renderer.event-renderer.version-renderer.jira-popup"
				messageType="transactional"
				trigger={({ ref: popupRef, ...triggerProps }) => (
					<Tooltip position="bottom" content={version.name}>
						{({ ref: tooltipRef, ...tooltipProps }) => (
							<Pressable
								{...triggerProps}
								{...tooltipProps}
								testId="calendar.ui.calendar-renderer.event-renderer.version-renderer.version-event-card"
								ref={mergeRefs(popupRef, tooltipRef, versionEventRef, triggerRef)}
								xcss={[
									eventCardInlineStyles,
									hasChangedIndicator && extraLeftPaddingStyles,
									isDragging && eventCardInlineDraggingStyles,
									...(colorBy === 'status'
										? [
												status === OFF_TRACK && overdueStyles,
												status === COMPLETE && releasedStyles,
												status === ON_TRACK && unreleasedStyles,
											]
										: [defaultStyles]),
								]}
								onMouseDown={(e: MouseEvent<HTMLButtonElement>) => {
									// Prevents fullcalendar from handling mouse events and interfering with pragmatic dnd
									e.stopPropagation();
								}}
								onClick={onEventCardClick}
							>
								<ShipIcon label="" LEGACY_size="small" color={token('color.icon')} />
								<Inline
									space="space.050"
									xcss={eventNameInlineStyles}
									alignBlock="center"
									as="span"
								>
									{versionName}
								</Inline>
								{version.status && version.releaseDate && (
									<Box as="span" xcss={releaseIconWrapperStyles}>
										<ReleaseIcon status={version.status} releaseDate={version.releaseDate} />
									</Box>
								)}
								{version.project?.avatar?.xsmall && (
									<ProjectAvatar
										alt={`${version.project.name} project avatar`}
										src={version.project.avatar.xsmall}
									/>
								)}
								{hasChangedIndicator && <ChangeIndicator />}
							</Pressable>
						)}
					</Tooltip>
				)}
			/>
			{editReleaseModalQueryRef && (
				<EditReleaseModal
					versionId={version.id}
					isOpen={isEditReleaseModalOpen}
					onClose={() => setIsEditReleaseModalOpen(false)}
					queryReference={editReleaseModalQueryRef}
				/>
			)}
		</>
	);
}

const eventCardInlineStyles = xcss({
	position: 'relative',
	display: 'flex',
	gap: 'space.050',
	padding: 'space.050',
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	font: token('font.body', fontFallback.body.medium),
	alignItems: 'center',
	boxSizing: 'border-box',
	width: '100%',
	height: '24px',
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	backgroundColor: 'color.background.accent.gray.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.gray.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.gray.subtlest.pressed',
	},
	color: 'color.text',
	borderRadius:
		// eslint-disable-next-line @typescript-eslint/consistent-type-assertions, @atlaskit/ui-styling-standard/no-unsafe-values -- Ignored via go/DSP-18766
		`var(--fc-event-border-left-radius, 0)
        var(--fc-event-border-right-radius, 0)
        var(--fc-event-border-right-radius, 0)
${/* eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766 */ ''}
        var(--fc-event-border-left-radius, 0)` as BorderRadius,
});

const unreleasedStyles = xcss({
	backgroundColor: 'color.background.accent.blue.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.blue.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.blue.subtlest.pressed',
	},
});

const releasedStyles = xcss({
	backgroundColor: 'color.background.accent.green.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.green.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.green.subtlest.pressed',
	},
});

const overdueStyles = xcss({
	backgroundColor: 'color.background.accent.red.subtlest',
	':hover': {
		backgroundColor: 'color.background.accent.red.subtlest.hovered',
	},
	':active': {
		backgroundColor: 'color.background.accent.red.subtlest.pressed',
	},
});

const defaultStyles = xcss({
	backgroundColor: 'elevation.surface.raised',
	':hover': {
		backgroundColor: 'elevation.surface.raised.hovered',
	},
	':active': {
		backgroundColor: 'elevation.surface.raised.pressed',
	},
	border: '1px solid',
	borderColor: 'color.border',
});

const extraLeftPaddingStyles = xcss({
	paddingLeft: 'space.100',
	overflow: 'hidden',
});

const eventCardInlineDraggingStyles = xcss({
	opacity: 0.5,
});

const eventNameInlineStyles = xcss({
	display: 'inline',
	fontWeight: token('font.weight.medium'),
	overflow: 'hidden',
	textOverflow: 'ellipsis',
	textAlign: 'left',
});

const releaseIconWrapperStyles = xcss({
	flex: 1,
	flexShrink: 0,
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ProjectAvatar = styled.img({
	height: token('space.200'),
	width: token('space.200'),
	borderRadius: token('border.radius'),
});
