/*!
 * More options dropdown, copied over from JWM codebase.
 */
import React, { type ReactNode, useState, useCallback, memo, forwardRef } from 'react';
import { styled } from '@compiled/react';
import MoreIcon from '@atlaskit/icon/core/migration/show-more-horizontal--more';
import Popup, { type PopupComponentProps } from '@atlaskit/popup'; // ignore-for-ENGHEALTH-17759
import { colors } from '@atlaskit/theme';
import { token } from '@atlaskit/tokens';
import { useIntl } from '@atlassian/jira-intl';
import { messages } from './messages.tsx';

const CustomPopupContainer = forwardRef<HTMLDivElement, PopupComponentProps>(
	({ children, ...props }, ref) => (
		<StyledPopup {...props} ref={ref}>
			{children}
		</StyledPopup>
	),
);

/* We need a custom popup container to be able to handle the scroll
of the content and fetch more options once reaches the end */
const PopupContainer = memo(CustomPopupContainer);

export function MoreOptionsDropdown({ content }: { content: () => ReactNode }) {
	const [isOpen, setIsOpen] = useState(false);
	const close = useCallback(() => {
		setIsOpen(false);
	}, []);

	const toggle = useCallback(() => {
		const nextIsOpen = !isOpen;
		setIsOpen(nextIsOpen);
	}, [isOpen]);
	const { formatMessage } = useIntl();

	return (
		<Popup
			isOpen={isOpen}
			onClose={close}
			placement="bottom-end"
			popupComponent={PopupContainer}
			content={content}
			shouldRenderToParent
			trigger={({ ref, 'aria-expanded': ariaExpanded, ...triggerProps }) => (
				<ShowMoreButtonWrapper ref={ref} {...triggerProps}>
					<ShowMoreButton
						data-testid="software-filters-popup.common.ui.more-options-dropdown.button-wrapper"
						isOpen={isOpen}
						onClick={toggle}
						aria-expanded={ariaExpanded}
					>
						<MoreIcon label={formatMessage(messages.moreOptions)} LEGACY_size="small" />
					</ShowMoreButton>
				</ShowMoreButtonWrapper>
			)}
		/>
	);
}

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShowMoreButton = styled.button<{
	isOpen: boolean;
}>({
	position: 'relative',
	cursor: 'pointer',
	display: 'flex',
	alignItems: 'center',
	width: token('space.400'),
	height: token('space.400'),
	borderRadius: '50%',
	aspectRatio: '1',
	padding: 0,
	justifyContent: 'center',
	gap: token('space.050'),
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
	color: `${token('color.text.accent.gray', colors.N700)}`,
	border: 0,
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
	backgroundColor: ({ isOpen }) => {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		if (isOpen) return token('color.background.neutral.pressed', colors.N40);
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		return token('color.background.neutral', colors.N20);
	},
	'&:hover': {
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-dynamic-styles -- Ignored via go/DSP-18766
		backgroundColor: ({ isOpen }) => {
			// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
			if (isOpen) return token('color.background.neutral.pressed', colors.N40);

			return token('color.background.neutral.hovered');
		},
	},
	// eslint-disable-next-line @atlaskit/ui-styling-standard/no-nested-selectors -- Ignored via go/DSP-18766
	'> div': {
		bottom: '0px',
	},
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const ShowMoreButtonWrapper = styled.div({
	display: 'flex',
	alignItems: 'center',
});

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const StyledPopup = styled.div({
	// Puts the element above the parent dropdown, which is at z-index: 400
	zIndex: 401,

	backgroundColor: token('elevation.surface.overlay'),
	borderRadius: token('border.radius'),
	boxShadow: token(
		'elevation.shadow.overlay',
		// eslint-disable-next-line @atlaskit/ui-styling-standard/no-imported-style-values -- Ignored via go/DSP-18766
		`0 4px 8px -2px ${colors.N50A}, 0 0 1px ${colors.N60A}`,
	),
	boxSizing: 'border-box',
	display: 'block',
	flex: '1 1 auto',
	'&:focus': {
		outline: 'none',
	},
});
