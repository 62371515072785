/*!
 * This is copied from JWM calendar.
 */
import React from 'react';
import { styled } from '@compiled/react';
import Heading from '@atlaskit/heading';
import { Stack, xcss } from '@atlaskit/primitives';
import { token } from '@atlaskit/tokens';
import { fg } from '@atlassian/jira-feature-gating';
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';

type Props = {
	error?: Error;
};

export const CalendarUnscheduledPanelEmptyState = ({ error }: Props) => {
	const { formatMessage } = useIntl();

	const values = error
		? {
				title: messages.errorTitle,
				description: messages.errorDescription,
			}
		: {
				title: messages.title,
				description: fg('jira-issue-terminology-refresh-m3')
					? messages.dropzoneDescriptionIssueTermRefresh
					: messages.dropzoneDescription,
			};

	return (
		<Stack grow="fill" alignBlock="center" alignInline="center" xcss={stackStyles}>
			<Heading as="h3" size="small">
				{formatMessage(values.title)}
			</Heading>
			<Description>{formatMessage(values.description)}</Description>
		</Stack>
	);
};

// eslint-disable-next-line @atlaskit/ui-styling-standard/no-styled -- To migrate as part of go/ui-styling-standard
const Description = styled.p({
	font: token('font.body'),

	color: token('color.text'),
	marginTop: token('space.200'),
	marginRight: 0,
	marginBottom: token('space.200'),
	marginLeft: 0,
	textAlign: 'center',
});

const stackStyles = xcss({
	padding: 'space.300',
});
