import React, { forwardRef, useState } from 'react';
import Button, {
	IconButton,
	LinkButton,
	LinkIconButton,
	type IconButtonProps,
	type ButtonProps,
	type LinkButtonProps,
} from '@atlaskit/button/new';

import type { IconProps } from '@atlaskit/icon/types';
import { Inline } from '@atlaskit/primitives';
import {
	Show,
	type Breakpoint,
	UNSAFE_useMediaQuery as useMediaQuery,
} from '@atlaskit/primitives/responsive';
import Tooltip from '@atlaskit/tooltip';
import {
	ThemedLinkButton,
	ThemedLinkIconButton,
} from '@atlassian/jira-project-theme-components/src/ui/themed-button-new/ThemedButtonNew.tsx';
import { RESPONSIVE_BUTTON_DEFAULT_TEST_ID } from '../common/constants.tsx';

export type ResponsiveBaseButtonProps = {
	// Atlaskit Icon to be displayed on the button. Format is icon={YourIconName}
	icon: React.ComponentType<IconProps>;
	// Label text the expanded button and tooltip on the collapsed (icon-only) button
	label: string;
	// Media query above which the button will be shown in expanded form. Default is lg.
	breakpoint?: Exclude<Breakpoint, 'xxs'>;
	// Button appearance; supports the values valid for both the 'full' and 'icon' variations
	appearance?: React.ComponentProps<typeof IconButton>['appearance'];
	// Controls the amount of padding in the icon button. Can be 'compact' | 'default'
	iconButtonSpacing?: IconButtonProps['spacing'];
	// Controls the amount of padding in the button. Can be 'compact' | 'default' | 'none'
	buttonSpacing?: ButtonProps['spacing'];

	isThemed?: boolean;
};

export type ResponsiveLinkButtonProps =
	// Inherits Atlaskit link button props except some exceptions where we are handling something explicitly or not supporting it
	Omit<LinkButtonProps, 'iconBefore' | 'children' | 'appearance' | 'spacing'> &
		ResponsiveBaseButtonProps;

export type ResponsiveButtonProps =
	// Inherits Atlaskit button props except some exceptions where we are handling something explicitly or not supporting it
	Omit<ButtonProps, 'iconBefore' | 'children' | 'appearance' | 'spacing'> &
		ResponsiveBaseButtonProps;

/**
 * Displays an IconButton+tooltip or a Button with iconBefore, depending on viewport size.
 */
export const ResponsiveButton = forwardRef<HTMLButtonElement, ResponsiveButtonProps>(
	(props, ref) => {
		const {
			icon,
			label,
			iconAfter,
			breakpoint = 'lg',
			appearance,
			testId,
			'aria-label': ariaLabel,
			iconButtonSpacing,
			buttonSpacing,
			isThemed,
			...restProps
		} = props;

		const mediaQueryBreakpoint = useMediaQuery(`above.${breakpoint}`, (event) =>
			setIsAboveBreakpoint(event.matches),
		);
		const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(mediaQueryBreakpoint?.matches);

		return (
			<Inline>
				<Show below={breakpoint}>
					<Tooltip content={label}>
						<IconButton
							{...restProps}
							icon={icon}
							appearance={appearance ?? 'default'}
							label={label}
							testId={`${testId ?? RESPONSIVE_BUTTON_DEFAULT_TEST_ID}.collapsed`}
							ref={!isAboveBreakpoint ? ref : undefined}
							spacing={iconButtonSpacing}
						/>
					</Tooltip>
				</Show>
				<Show above={breakpoint}>
					<Button
						{...restProps}
						iconBefore={icon}
						iconAfter={iconAfter}
						appearance={appearance ?? 'default'}
						testId={`${testId ?? RESPONSIVE_BUTTON_DEFAULT_TEST_ID}.expanded`}
						ref={isAboveBreakpoint ? ref : undefined}
						aria-label={ariaLabel}
						spacing={buttonSpacing}
					>
						{label}
					</Button>
				</Show>
			</Inline>
		);
	},
);

/**
 * Displays a LinkIconButton+tooltip or a LinkButton with iconBefore, depending on viewport size.
 */
export const ResponsiveLinkButton = forwardRef<HTMLAnchorElement, ResponsiveLinkButtonProps>(
	(props: ResponsiveLinkButtonProps, ref) => {
		const {
			icon,
			label,
			iconAfter,
			breakpoint = 'lg',
			appearance,
			testId,
			'aria-label': ariaLabel,
			iconButtonSpacing,
			buttonSpacing,
			isThemed,
			...restProps
		} = props;

		const mediaQueryBreakpoint = useMediaQuery(`above.${breakpoint}`, (event) =>
			setIsAboveBreakpoint(event.matches),
		);
		const [isAboveBreakpoint, setIsAboveBreakpoint] = useState(mediaQueryBreakpoint?.matches);

		const IconButtonComponent = isThemed ? ThemedLinkIconButton : LinkIconButton;
		const ButtonComponent = isThemed ? ThemedLinkButton : LinkButton;

		return (
			<Inline>
				<Show below={breakpoint}>
					<Tooltip content={label}>
						<IconButtonComponent
							{...restProps}
							icon={icon}
							appearance={appearance ?? 'default'}
							label={label}
							testId={`${testId ?? RESPONSIVE_BUTTON_DEFAULT_TEST_ID}.collapsed`}
							href={restProps.href}
							ref={!isAboveBreakpoint ? ref : undefined}
							spacing={iconButtonSpacing}
						/>
					</Tooltip>
				</Show>
				<Show above={breakpoint}>
					<ButtonComponent
						{...restProps}
						iconBefore={icon}
						iconAfter={iconAfter}
						appearance={appearance ?? 'default'}
						testId={`${testId ?? RESPONSIVE_BUTTON_DEFAULT_TEST_ID}.expanded`}
						href={restProps.href}
						ref={isAboveBreakpoint ? ref : undefined}
						aria-label={ariaLabel}
						spacing={buttonSpacing}
					>
						{label}
					</ButtonComponent>
				</Show>
			</Inline>
		);
	},
);
