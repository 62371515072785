import React from 'react';
import SuccessIcon from '@atlaskit/icon/core/migration/success--check-circle';
import ErrorIcon from '@atlaskit/icon/core/migration/error';
import { G300, R300 } from '@atlaskit/theme/colors';
import { token } from '@atlaskit/tokens';
import AutoDismissFlag from '@atlassian/jira-flags/src/common/ui/components/ak-auto-dismiss-flag'; // ignore-for-ENGHEALTH-17759
import FlagGroup from '@atlassian/jira-flags/src/common/ui/components/group-flag'; // ignore-for-ENGHEALTH-17759
import { useIntl } from '@atlassian/jira-intl';
import messages from './messages.tsx';
import { getMessageId } from './utils.tsx';

export type Flags = {
	styleClass: string;
	id: number;
	isSuccessful: boolean;
}[];

type Props = {
	issueKey: string;
	flags: Flags;
	onDismiss: () => void;
};

const NotificationFlagGroup = ({ flags, onDismiss, issueKey }: Props) => {
	const { formatMessage } = useIntl();
	return (
		<FlagGroup onDismissed={onDismiss}>
			{flags.map(({ id, styleClass, isSuccessful }) => (
				<AutoDismissFlag
					id={id}
					icon={
						isSuccessful ? (
							<SuccessIcon
								label="Success"
								LEGACY_size="medium"
								spacing="spacious"
								color={token('color.icon.success', G300)}
							/>
						) : (
							<ErrorIcon
								label="Error"
								LEGACY_size="medium"
								spacing="spacious"
								color={token('color.icon.danger', R300)}
							/>
						)
					}
					key={id}
					title={
						isSuccessful ? formatMessage(messages.successTitle) : formatMessage(messages.errorTitle)
					}
					description={
						isSuccessful
							? formatMessage(messages[getMessageId(styleClass)], {
									issueKey,
								})
							: formatMessage(messages.errorDesc, {
									issueKey,
								})
					}
				/>
			))}
		</FlagGroup>
	);
};

export default NotificationFlagGroup;
